import { ApolloError } from '@apollo/client'

import { countries, documentTypes, regions } from '@klarpay/enums'
import { Box } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import getSymbolFromCurrency from 'currency-symbol-map'
import { isValid } from 'iban'
import {
  countBy,
  find,
  forEach,
  includes,
  isArray,
  isEmpty,
  isEqual,
  maxBy,
  pickBy,
  sortBy,
} from 'lodash'
import React from 'react'
import { toast } from 'react-toastify'
import { ExtendedAccessContractsTypesList } from '../constants'
import {
  Account,
  AccountStatus,
  Action,
  ActionSignature,
  ActionSignatureStatus,
  ActionStatus,
  Address,
  BankableEntityContractType,
  BatchPaymentRecord,
  CardLimitInputType,
  CardLimitWithUsage,
  CardSpendingLimitTypes,
  ContractAuthorizedPersonType,
  ContractStatusType,
  ContractType,
  Currency,
  DefaultCardAllowedLimitsType,
  FundsType,
  Individual,
  LegalEntity,
  LimitObjectType,
  MaxCardAllowedLimitsType,
  Maybe,
  ProfileChangeAnswer,
  UboLegalEntityType,
  UboOwner,
  UboType,
  Wallet,
  WalletStatus,
} from '../graphql'
import i18n from '../i18n'
import { currencies, newSelectRoleInvite, paidList, rates } from '../stubs'

import {
  ActionButtonType,
  AddressTypeOptions,
  BeneficialCellType,
  ChangesType,
  ContractChanges,
  CPAlerts,
  CurrencyType,
  DetailsContractsByIndividualType,
  LegalList,
  LimitsChanged,
  LimitsType,
  LimNameType,
  ShowAlertType,
  SingleOrPlural,
  WebsiteField,
} from '../types'
import { countriesIbanList, dashboardClosedStatuses, dashboardPassStatuses } from './Data'
import { getEnumLabel } from './HelpFunctions/functions'

export * from './HelpFunctions/functions'
export * from './currencyLabels'

export function joinPaths(...paths: string[]): string {
  return paths.join('/')
}

export function getCookie(name: string): string | null {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
  )
  return matches ? decodeURIComponent(matches[1]) : null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sizeInKB = (value: number) =>
  Number((value < 1024 ? 1024 : value) / 1024).toLocaleString(navigator.language || 'en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  })

const sortCurrencies = (item: { key: string } | { currency: string }): number => {
  const sortBy = 'key' in item ? item.key : item.currency
  switch (sortBy) {
    case Currency.Chf:
      return 0
    case Currency.Eur:
      return 1
    case Currency.Usd:
      return 2
    case Currency.Gbp:
      return 3
    default:
      return 4
  }
}

export const sortAccountStatuses = ({ status }: Account): number => {
  switch (status) {
    case AccountStatus.Active:
      return 0
    case AccountStatus.PendingOpeningSignatures:
      return 1
    case AccountStatus.PendingApproval:
      return 2
    case AccountStatus.PendingIncomingPayment:
      return 3
    case AccountStatus.Suspended:
      return 4
    case AccountStatus.Closed:
      return 5
    default:
      return 6
  }
}

export const currenciesLabelsArray = Object.values(sortBy(currencies, sortCurrencies))

export const newCurrenciesLabelsArray = (fundsType?: FundsType) =>
  Object.values(
    sortBy(
      fundsType ? pickBy(currencies, (c) => c.allowedNewAccounts.includes(fundsType)) : currencies,
      (i) => sortCurrencies({ currency: i.key }),
    ),
  )

export const selectCurrenciesLabelsArray = Object.values(currencies)

export const selectRoleInviteLabelsArray = Object.values(newSelectRoleInvite)

export const currencyFormat = (
  value = 0,
  currency?: Maybe<string>,
  options?: Intl.NumberFormatOptions,
  isCryptoCurrency?: boolean,
): string =>
  currency
    ? Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: currency,
        useGrouping: true,
        ...options,
      })
    : Number(value).toLocaleString('en-US', {
        minimumFractionDigits: isCryptoCurrency ? 5 : 2,
        maximumFractionDigits: isCryptoCurrency ? 5 : 2,
        useGrouping: true,
        ...options,
      })

export const filterOptionsStart = createFilterOptions({
  matchFrom: 'start',
})

export const filterOptionsAny = createFilterOptions({
  matchFrom: 'any',
})

export function getShareSizeSummary(getUboOwners: UboOwner[], uboId: string): number {
  return (getUboOwners?.reduce(
    (acc: number, val: UboOwner) => acc + (val?.ownedUboId === uboId ? val?.shareSize ?? 0 : 0),
    0,
  ) || 0) as number
}

export function calcTotalShareAndIsDirectorAvail(
  uboId: string,
  getUboOwners: UboOwner[],
  shareSizeArr: number[] | undefined,
): { shareSizeSummary: number; isDirectorAvailable: boolean } {
  if (uboId !== '0' && uboId !== '') {
    if (!isEmpty(getUboOwners)) {
      return {
        shareSizeSummary: getShareSizeSummary(getUboOwners, uboId),
        isDirectorAvailable: false,
      }
    } else {
      return { shareSizeSummary: 0, isDirectorAvailable: true }
    }
  } else {
    const shareSizeSum = shareSizeArr?.reduce((acc, val) => acc + val, 0) || 0
    return {
      shareSizeSummary: shareSizeSum,
      isDirectorAvailable: shareSizeSum === 0,
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const scrollToInputElement = (
  elements: Record<string, unknown>,
  offer?: number | undefined,
) => {
  const err = Object.keys(elements)
  const inputs = document.querySelectorAll('input')
  const texts = document.querySelectorAll('textarea')
  const buttons = document.querySelectorAll('button')
  const fields = [...inputs, ...texts, ...buttons]
  const sort: string[] = []

  if (!isEmpty(err)) {
    forEach(fields, function (i) {
      err.forEach((error) => {
        const name: string | null = i.getAttribute('name')
        if (name === error) {
          sort.push(name)
        }
        if (name?.includes('businessRegions') && error === 'businessRegions') {
          sort.push('businessRegions[0].businessCategoryRegion')
        } else if (name?.includes('expectedVolumes') && error === 'expectedVolumes') {
          sort.push('expectedVolumes[0].currency')
        } else if (name?.includes('urls') && error === 'urls') {
          sort.push('urls[0].value')
        } else if (name?.includes('Visadomestic') && error === 'totalPredictedMonthlyVolumes') {
          sort.push('Visadomestic')
        } else if (name?.includes('affiliatesList') && error === 'affiliatesList') {
          sort.push('affiliatesList')
        }
      })
    })

    if (!isEmpty(sort)) {
      const errorElements = sort
        .map((name) => {
          const escapedName = name.replace(/[\[\]]/g, '\\$&')
          return (
            document.querySelector(`input[name="${escapedName}"]`) ||
            document.querySelector(`textarea[name="${escapedName}"]`) ||
            document.querySelector(`button[name="${escapedName}"]`)
          )
        })
        .filter(Boolean) as HTMLElement[]

      const topElement = errorElements.reduce((prev, curr) =>
        prev.getBoundingClientRect().top < curr.getBoundingClientRect().top ? prev : curr,
      )

      if (topElement) {
        const yOffset = offer ? -offer : -24
        const yPosition = topElement.getBoundingClientRect().top + window.pageYOffset + yOffset
        topElement.focus()

        window.scrollTo({
          top: yPosition,
          behavior: 'smooth',
        })
      }
    }
  }
}

export function isEnoughSignatories(
  authorizedPersons: ContractAuthorizedPersonType[],
): [boolean, ShowAlertType[]] {
  const alerts: ShowAlertType[] = []
  const notEmptySignatories = find(authorizedPersons, (o) => !isEmpty(o.signatoryRight))
  const groupsCountBy = countBy(authorizedPersons, function (o) {
    if (o.groupSize === 2) {
      return 'dual'
    } else if ((o.groupSize ?? 0) >= 3) {
      return 'group'
    } else {
      return 'others'
    }
  })

  const maxGroupSize = maxBy(authorizedPersons, 'groupSize')?.groupSize ?? 0
  const groupSize = maxGroupSize <= 2 ? groupsCountBy['dual'] : groupsCountBy['group']
  if (isEmpty(notEmptySignatories)) {
    alerts.push({
      key: 'noOneSignatory',
      value: 'You need at least one Authorised person with Signatory rights to proceed.',
    })
    return [false, alerts]
  } else if (groupsCountBy['dual'] === 1 || groupSize < maxGroupSize) {
    if (groupSize < maxGroupSize && groupsCountBy['group']) {
      alerts.push({
        key: 'notEnoughSignatoriesGroup',
        value:
          '{{val1}} person{{val2}} Group ({{val3}}) Signatory rights meaning that their signature will have power only if {{val3}} Signatories with Group rights sign. There is not enough Signatories in the group. Consider changing the rights or add more Signatories.',
        messageParam1: '' + groupSize,
        messageParam2: groupSize > 1 ? SingleOrPlural.Plural : SingleOrPlural.Single,
        messageParam3: '' + maxGroupSize,
      })
    }
    if (groupsCountBy['dual'] === 1) {
      const person = find(authorizedPersons, (o) => o.groupSize === 2)?.person
      alerts.push({
        key: 'signatoryWithDualRights',
        value:
          '{{val1}} has Dual Signatory rights meaning that their signature will have power only if one more Signatory with Dual rights signs. Consider changing the rights or add more Signatories.',
        messageParam1: (person?.firstName as string) + ' ' + (person?.lastName as string),
      })
    }
    return [false, alerts]
  } else {
    return [true, alerts]
  }
}

export function isSignatoryInContract(
  authorizedPersons: ContractAuthorizedPersonType[],
  individualId: number,
): boolean {
  let isFound = false
  if (authorizedPersons) {
    forEach(authorizedPersons, (item) => {
      if (!isEmpty(item?.signatoryRight) && item?.person?.id === individualId) {
        isFound = true
      }
    })
  }
  return isFound
}

export function isSignatoryInContracts(individual: Individual): boolean {
  let isFound = false
  forEach(individual.contracts, function (o) {
    if (o?.authorizedPersons) {
      forEach(o?.authorizedPersons, (item) => {
        if (!isEmpty(item?.signatoryRight) && item?.person?.id === individual.id) {
          isFound = true
        }
      })
    }
  })
  return isFound
}

export function isIndividualSignedAccount(individualId: number, account: Account): boolean {
  let isFound = false
  const actionSignatures = account.executionAction?.signatures
  forEach(actionSignatures, function (signature) {
    if (signature?.status === ActionSignatureStatus.Accept) {
      if (signature?.signatory?.person?.id === individualId) {
        isFound = true
      }
    }
  })
  return isFound
}

export function formatIban(iban: string): string {
  if (!iban) return ''
  return `${iban.slice(0, 2)} ${iban.slice(2, 4)} ${iban.slice(4, 10)} ${iban.slice(10, 22)}`
}

export function formatAccountIban(iban: string): string {
  if (!iban) return ''
  return `${iban?.slice(0, 2)} ${iban?.slice(2, 22)}`
}

// get contract AP, contracts Applicant and check isAP accent
export function getDetailsContractsByIndividual(
  contracts: BankableEntityContractType[] | undefined,
  individual: Individual,
): DetailsContractsByIndividualType {
  const result: DetailsContractsByIndividualType = {
    individual: {
      id: individual?.id,
    },
    isAuthorizedPersons: false,
    contractsAp: [],
    contractsApplicant: [],
    individualContract: null,
  }

  if (!isEmpty(contracts)) {
    forEach(contracts, function (c: BankableEntityContractType) {
      const isFoundAPContracts = c.authorizedPersons?.find(
        (item) => item?.person?.id === individual?.id,
      )
      if (c.ownerType === UboType.NaturalPerson && individual?.id === (c.owner as Individual).id) {
        if (!dashboardClosedStatuses.includes(c.status as ContractStatusType)) {
          result.individualContract = c
        }
      } else if (
        c?.createdBy?.id === individual?.id &&
        (!dashboardPassStatuses.includes(c.status as ContractStatusType) ||
          (dashboardPassStatuses.includes(c.status as ContractStatusType) &&
            !isFoundAPContracts &&
            c.type !== ContractType.IntroducerReference))
      ) {
        result.contractsApplicant.push(c)
      } else if (
        (isFoundAPContracts && dashboardPassStatuses.includes(c.status as ContractStatusType)) ||
        (dashboardPassStatuses.includes(c.status as ContractStatusType) &&
          !isFoundAPContracts &&
          c.type === ContractType.IntroducerReference)
      ) {
        result.isAuthorizedPersons = true
        result.contractsAp.push(c)
      } else if (
        isFoundAPContracts &&
        (c?.status === ContractStatusType.Suspended ||
          c?.status === ContractStatusType.TerminationPending)
      ) {
        result.contractsApplicant.push(c)
      }
    })
  }
  return result
}

export function validateCP(
  legalsList: LegalList[],
  actionButtonType: ActionButtonType,
  selectedUboId?: string,
): [alerts: ShowAlertType[], canProcess: boolean, justWarning?: boolean] {
  const alerts: ShowAlertType[] = []

  switch (actionButtonType) {
    case ActionButtonType.onProceed:
      const companyWithout25Percents = find(
        legalsList,
        (o) =>
          o.legalEntityType !== UboLegalEntityType.DomiciliaryHolding &&
          (o?.ownersTotalShareSize ?? 0) < 25,
      )

      if ((legalsList[0] as LegalList)?.ownersTotalShareSize === 0) {
        alerts.push({
          key: CPAlerts.NoOneCP,
          value:
            'You have to add at least one Controlling person of {{val1}} before completing application.',
          messageParam1: (legalsList[0] as LegalList).label as string,
        })
        return [alerts, false, false]
      } else if (!isEmpty(companyWithout25Percents)) {
        alerts.push({
          key: CPAlerts.NoOneCPOfEntity,
          value:
            'You have to add at least one Controlling person of {{val1}} before completing application.',
          messageParam1: companyWithout25Percents?.label as string,
        })
        return [alerts, false]
      } else {
        return [alerts, true]
      }
    case ActionButtonType.onAdd:
      const domiciliaryAlreadyAdded100 = find(
        legalsList,
        (o) =>
          (!isEmpty(selectedUboId) ? selectedUboId : '0') === o.key &&
          o.legalEntityType === UboLegalEntityType.DomiciliaryHolding &&
          (o?.ownersTotalShareSize ?? 0) >= 100,
      )
      const alreadyAdded75 = find(
        legalsList,
        (o) =>
          (!isEmpty(selectedUboId) ? selectedUboId : '0') === o.key &&
          o.legalEntityType !== UboLegalEntityType.DomiciliaryHolding &&
          (o?.ownersTotalShareSize ?? 0) > 75,
      )
      if (!isEmpty(domiciliaryAlreadyAdded100)) {
        alerts.push({
          key: CPAlerts.Already100,
          value:
            'You have added Controlling persons of 100% of the shares of {{val1}}. Remove or edit existing persons to add new.',
          messageParam1: domiciliaryAlreadyAdded100?.label as string,
        })
        return [alerts, false]
      } else if (!isEmpty(alreadyAdded75)) {
        alerts.push({
          key: CPAlerts.Already75,
          value:
            "You have added Controlling persons of more than 75% of the shares of {{val1}}. You don't need to add more owners. Click on 'Add Controlling person' button again to ignore this and proceed to adding.",
          messageParam1: alreadyAdded75?.label as string,
        })
        return [alerts, false, true]
      } else {
        return [alerts, true]
      }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line
export function getProperty(obj: Record<string, any>, key: string) {
  return obj[key] || ''
}

export const setConversionCurrency = (
  value: number,
  currencyFrom: Currency | undefined | null,
  currencyTo: Currency | undefined | null,
): number => {
  if (value && currencyFrom && currencyTo) {
    let sum = 0
    if (currencyFrom !== Currency.Chf) {
      sum = +value * +getProperty(rates, currencyFrom as keyof unknown) // get sum on CFH
      return Math.ceil((sum / +getProperty(rates, currencyTo as keyof unknown)) * 100) / 100 // Conversion
    } else {
      return Math.ceil(value * +getProperty(rates, currencyTo as keyof unknown) * 100) / 100 // Conversion
    }
  }
  return 0
}

export const transformActionStatus = (action: Action): ActionSignatureStatus | ActionStatus => {
  if (action.status === ActionStatus.Rejected) {
    return ActionSignatureStatus.Reject
  } else if (
    action.status === ActionStatus.SignaturesQuorumReached ||
    action.status === ActionStatus.Executed
  ) {
    return ActionSignatureStatus.Accept
  } else {
    const signatures = action.signatures as ActionSignature[]
    if (isEmpty(signatures)) {
      return action.status as ActionStatus
    } else {
      if (signatures.some((s) => s.status === ActionSignatureStatus.Reject)) {
        return ActionSignatureStatus.Reject
      } else {
        const isMine = signatures.find((s) => s.isMine === true) as ActionSignature
        if (isEmpty(isMine)) {
          return action.status as ActionStatus
        } else {
          return isMine.status as ActionSignatureStatus
        }
      }
    }
  }
}

export const isValidIban = (iban: string, countryCode: string): boolean => {
  return (
    (iban ?? '').toUpperCase().startsWith((countryCode ?? '').toUpperCase()) &&
    isValid((iban ?? '').toUpperCase())
  )
}

export const isCountrySupportsIban = (countryCode: string): boolean => {
  return includes(countriesIbanList, (countryCode ?? '').toUpperCase())
}

export const getCurrencySign = (currency: string | undefined | null) => {
  if (!currency) {
    return
  }
  // CHF is currently not supported by the 'currency-symbol-map' for April 2023, but it may be added in the future
  if (currency === Currency.Chf) {
    return '₣'
  }
  return getSymbolFromCurrency(currency)
}

export function searchDifference(
  formData: Record<string, unknown>,
  newFormData: Record<string, unknown>,
): ChangesType[] {
  const unitedKeys = Object.keys({ ...formData, ...newFormData })

  return unitedKeys.reduce((acc, item) => {
    if (!isEqual(newFormData[item], formData[item])) {
      const changes: ChangesType = {
        fieldName: item,
        oldValue: formData[item],
        newValue: newFormData[item],
      }

      return [...acc, changes]
    } else {
      return acc
    }
  }, [] as ChangesType[])
}

export function parseLimitsArr(limits: LimitsType, limitName: LimNameType): CardLimitInputType[] {
  const { daily, monthly } = limits[limitName]
  //const { transaction, daily, weekly, monthly } = limits[limitName]

  switch (limitName) {
    case 'Internet':
      return [
        { limitType: CardSpendingLimitTypes.DailyInternetPurchase, amount: daily },
        // { limitType: CardSpendingLimitTypes.WeeklyInternetPurchase, amount: weekly },
        { limitType: CardSpendingLimitTypes.MonthlyInternetPurchase, amount: monthly },
        //{ limitType: CardSpendingLimitTypes.TransactionInternetPurchase, amount: transaction },
      ]
    case 'Contactless':
      return [
        { limitType: CardSpendingLimitTypes.DailyContactlessPurchase, amount: daily },
        // { limitType: CardSpendingLimitTypes.WeeklyContactlessPurchase, amount: weekly },
        { limitType: CardSpendingLimitTypes.MonthlyContactlessPurchase, amount: monthly },
        //{ limitType: CardSpendingLimitTypes.TransactionContactlessPurchase, amount: transaction },
      ]
    case 'Withdrawals':
      return [
        { limitType: CardSpendingLimitTypes.DailyWithdrawal, amount: daily },
        // { limitType: CardSpendingLimitTypes.WeeklyWithdrawal, amount: weekly },
        { limitType: CardSpendingLimitTypes.MonthlyWithdrawal, amount: monthly },
        // { limitType: CardSpendingLimitTypes.TransactionWithdrawal, amount: transaction },
      ]
  }
}

export function parseDefaultLimitsArr(
  defLimitsData: DefaultCardAllowedLimitsType,
): CardLimitInputType[] {
  const {
    defaultCardDailyInternetPurchaseLimit,
    defaultCardMonthlyInternetPurchaseLimit,
    defaultCardDailyContactlessPurchaseLimit,
    defaultCardMonthlyContactlessPurchaseLimit,
    defaultCardDailyWithdrawalLimit,
    defaultCardMonthlyWithdrawalLimit,
    defaultCardDailyOverallPurchaseLimit,
    defaultCardMonthlyOverallPurchaseLimit,
  } = defLimitsData
  return [
    {
      limitType: CardSpendingLimitTypes.DailyInternetPurchase,
      amount: defaultCardDailyInternetPurchaseLimit || 10000,
    },
    {
      limitType: CardSpendingLimitTypes.MonthlyInternetPurchase,
      amount: defaultCardMonthlyInternetPurchaseLimit || 25000,
    },
    {
      limitType: CardSpendingLimitTypes.DailyContactlessPurchase,
      amount: defaultCardDailyContactlessPurchaseLimit || 5000,
    },
    {
      limitType: CardSpendingLimitTypes.MonthlyContactlessPurchase,
      amount: defaultCardMonthlyContactlessPurchaseLimit || 10000,
    },
    {
      limitType: CardSpendingLimitTypes.DailyWithdrawal,
      amount: defaultCardDailyWithdrawalLimit || 10000,
    },
    {
      limitType: CardSpendingLimitTypes.MonthlyWithdrawal,
      amount: defaultCardMonthlyWithdrawalLimit || 10000,
    },
    {
      limitType: CardSpendingLimitTypes.DailyPurchase,
      amount: defaultCardDailyOverallPurchaseLimit || 10000,
    },
    {
      limitType: CardSpendingLimitTypes.MonthlyPurchase,
      amount: defaultCardMonthlyOverallPurchaseLimit || 25000,
    },
  ]
}

export function parseDefaultLimits(defLimitsData: DefaultCardAllowedLimitsType): LimitsType {
  const {
    defaultCardDailyInternetPurchaseLimit,
    defaultCardMonthlyInternetPurchaseLimit,
    defaultCardDailyContactlessPurchaseLimit,
    defaultCardMonthlyContactlessPurchaseLimit,
    defaultCardDailyWithdrawalLimit,
    defaultCardMonthlyWithdrawalLimit,
  } = defLimitsData
  return {
    Internet: {
      daily: defaultCardDailyInternetPurchaseLimit || 10000,
      monthly: defaultCardMonthlyInternetPurchaseLimit || 25000,
    },
    Contactless: {
      daily: defaultCardDailyContactlessPurchaseLimit || 5000,
      monthly: defaultCardMonthlyContactlessPurchaseLimit || 10000,
    },
    Withdrawals: {
      daily: defaultCardDailyWithdrawalLimit || 10000,
      monthly: defaultCardMonthlyWithdrawalLimit || 10000,
    },
  }
}

export function parseMaxLimits(maxLimitsData: MaxCardAllowedLimitsType): LimitsType {
  const {
    maxCardDailyInternetPurchaseLimit,
    maxCardMonthlyInternetPurchaseLimit,
    maxCardDailyContactlessPurchaseLimit,
    maxCardMonthlyContactlessPurchaseLimit,
    maxCardDailyWithdrawalLimit,
    maxCardMonthlyWithdrawalLimit,
  } = maxLimitsData
  return {
    Internet: {
      daily: maxCardDailyInternetPurchaseLimit || 10000,
      monthly: maxCardMonthlyInternetPurchaseLimit || 25000,
    },
    Contactless: {
      daily: maxCardDailyContactlessPurchaseLimit || 5000,
      monthly: maxCardMonthlyContactlessPurchaseLimit || 10000,
    },
    Withdrawals: {
      daily: maxCardDailyWithdrawalLimit || 10000,
      monthly: maxCardMonthlyWithdrawalLimit || 10000,
    },
  }
}

export function unParseLimitsArr(limits: CardLimitInputType[]): LimitsType {
  const limitsObj: LimitsType = {
    Internet: {
      daily: 0,
      monthly: 0,
    },
    Contactless: {
      daily: 0,
      monthly: 0,
    },
    Withdrawals: {
      daily: 0,
      monthly: 0,
    },
  }

  limits?.forEach((lim) => {
    switch (lim.limitType) {
      case CardSpendingLimitTypes.DailyInternetPurchase:
        limitsObj.Internet.daily = lim.amount
        break
      // case CardSpendingLimitTypes.WeeklyInternetPurchase:
      //   limitsObj.Internet.weekly = lim.amount
      //   break
      case CardSpendingLimitTypes.MonthlyInternetPurchase:
        limitsObj.Internet.monthly = lim.amount
        break
      // case CardSpendingLimitTypes.TransactionInternetPurchase:
      //   limitsObj.Internet.transaction = lim.amount
      //   break
      case CardSpendingLimitTypes.DailyContactlessPurchase:
        limitsObj.Contactless.daily = lim.amount
        break
      // case CardSpendingLimitTypes.WeeklyContactlessPurchase:
      //   limitsObj.Contactless.weekly = lim.amount
      //   break
      case CardSpendingLimitTypes.MonthlyContactlessPurchase:
        limitsObj.Contactless.monthly = lim.amount
        break
      // case CardSpendingLimitTypes.TransactionContactlessPurchase:
      //   limitsObj.Contactless.transaction = lim.amount
      //   break
      case CardSpendingLimitTypes.DailyWithdrawal:
        limitsObj.Withdrawals.daily = lim.amount
        break
      // case CardSpendingLimitTypes.WeeklyWithdrawal:
      //   limitsObj.Withdrawals.weekly = lim.amount
      //   break
      case CardSpendingLimitTypes.MonthlyWithdrawal:
        limitsObj.Withdrawals.monthly = lim.amount
        break
      // case CardSpendingLimitTypes.TransactionWithdrawal:
      //   limitsObj.Withdrawals.transaction = lim.amount
      //   break
      // case CardSpendingLimitTypes.WeeklyPurchase:
      //   limitsObj.Overall.weekly = lim.amount
      //   break
      // case CardSpendingLimitTypes.TransactionPurchase:
      //   limitsObj.Overall.transaction = lim.amount
      //   break
      default:
        break
    }
  })
  return limitsObj
}

export const documentTypesArray = sortBy(
  Object.values(documentTypes).filter((f) => !f.deprecationReason),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({ category }) => category,
)

export function limitsValueFormat(
  currency: string | undefined,
  limitValue: number,
  options: Intl.NumberFormatOptions,
): string {
  return !!currency
    ? `${getCurrencySign(currency) + ' ' + currencyFormat(limitValue, '', options)}`
    : '-'
}

// export function navLinkAssistant(user: Individual | null, toLink: string): string {
//   if (!user?.address?.line1) {
//     return APP_PATHS.onboarding.personalDetails
//   }
//
//   if (!user?.isPhoneVerified) return APP_PATHS.onboarding.phoneVerification
//
//   if (!user?.videoVerified) return APP_PATHS.onboarding.videoVerification
//
//   return toLink
// }

export function limitsChanged(
  limitsWithUsage: Maybe<Maybe<CardLimitWithUsage | LimitObjectType>[]> | undefined,
  limits: Maybe<Maybe<LimitObjectType>[]> | undefined,
): LimitsChanged[] {
  const foundDiff: LimitsChanged[] = []
  forEach(limits, (item) => {
    const diffElem = find(
      limitsWithUsage,
      (o) => o?.limitType === item?.limitType && o?.amount !== item?.amount,
    )
    if (diffElem) {
      foundDiff.push({
        cardSpendingLimitTypes: item?.limitType,
        limitUsageAmount: diffElem?.amount ?? 0,
        limitAmount: item?.amount ?? 0,
      })
    }
  })
  return foundDiff
}

export function contractChanges(oldData: LegalEntity, newData: LegalEntity): ContractChanges[] {
  const foundDiff: ContractChanges[] = []

  function addressDiffs(
    oldDataAddress: Maybe<Address> | undefined,
    newDataAddress: Maybe<Address> | undefined,
    addressType: AddressTypeOptions,
  ) {
    const checkedAddressObject = (oldDataAddress ? oldDataAddress : newDataAddress) || {}
    Object.keys(checkedAddressObject as Address).forEach(function (key) {
      const checkedAddressFields = [
        'additionalDetails',
        'city',
        'line1',
        'zip',
        'countryDescription',
      ]
      if (
        checkedAddressFields.includes(key) &&
        (!isEmpty(getProperty(oldDataAddress ?? {}, key as keyof Maybe<Address>))
          ? getProperty(oldDataAddress ?? {}, key as keyof Maybe<Address>)
          : '') !==
          (!isEmpty(getProperty(newDataAddress ?? {}, key as keyof Maybe<Address>))
            ? getProperty(newDataAddress ?? {}, key as keyof Maybe<Address>)
            : '')
      ) {
        foundDiff.push({
          addressType: addressType,
          changedProperty: key,
          oldValue: getProperty(oldDataAddress ?? {}, key as keyof Maybe<Address>),
          newValue: getProperty(newDataAddress ?? {}, key as keyof Maybe<Address>),
        })
      }
    })
  }

  if (newData?.address) {
    addressDiffs(oldData?.address, newData?.address, AddressTypeOptions.address)
  }
  if (newData?.correspondenceAddress) {
    addressDiffs(
      oldData?.correspondenceAddress,
      newData?.correspondenceAddress,
      AddressTypeOptions.correspondenceAddress,
    )
  }

  Object.keys(oldData as LegalEntity).forEach(function (key) {
    const checkedFields = ['companyName', 'tradingName', 'phone', 'website', 'taxId']
    if (
      checkedFields.includes(key) &&
      !!getProperty(newData, key as keyof Maybe<LegalEntity>) &&
      getProperty(oldData, key as keyof Maybe<LegalEntity>) !==
        getProperty(newData, key as keyof Maybe<LegalEntity>)
    ) {
      foundDiff.push({
        addressType: undefined,
        changedProperty: key,
        oldValue: getProperty(oldData, key as keyof Maybe<LegalEntity>),
        newValue: getProperty(newData, key as keyof Maybe<LegalEntity>),
      })
    }
  })

  return foundDiff
}

export function showError(error: ApolloError): void {
  toast.error(i18n.t('errorFetchingData', 'Error fetching data'))
  toast.error(error.message)
}

export function isActiveContract(
  contract: BankableEntityContractType,
  user: Individual,
): boolean | undefined {
  return (
    dashboardPassStatuses.includes(contract?.status as ContractStatusType) &&
    (contract.authorizedPersons?.map((personInfo) => personInfo?.person?.id).includes(user?.id) ||
      (contract.type === ContractType.IntroducerReference && contract.createdBy?.id === user.id))
  )
}

export function isLimitedActiveContract(
  contract: BankableEntityContractType,
  user: Individual,
): boolean | undefined {
  return (
    ExtendedAccessContractsTypesList.includes(contract?.status as ContractStatusType) &&
    (contract.authorizedPersons?.map((personInfo) => personInfo?.person?.id).includes(user?.id) ||
      (contract.type === ContractType.IntroducerReference && contract.createdBy?.id === user.id))
  )
}

export function isUserIdOnContractsTeam(
  userId: number,
  authorizedPersons: Maybe<ContractAuthorizedPersonType>[] = [],
): boolean {
  return authorizedPersons?.some((authorizedPerson) => authorizedPerson?.person?.id === userId)
}

export function isNotActiveContract(
  contract: BankableEntityContractType,
  user: Individual,
): boolean {
  const isUserOnTeamAndHasRequiredStatus: boolean =
    !!contract?.status &&
    [ContractStatusType.Suspended, ContractStatusType.TerminationPending].includes(
      contract?.status,
    ) &&
    isUserIdOnContractsTeam(user.id, contract?.authorizedPersons ?? [])

  return (
    (contract?.createdBy?.id === user?.id || isUserOnTeamAndHasRequiredStatus) &&
    (!dashboardPassStatuses.includes(contract?.status as ContractStatusType) ||
      (dashboardPassStatuses.includes(contract?.status as ContractStatusType) &&
        !contract.authorizedPersons
          ?.map((personInfo) => personInfo?.person?.id)
          .includes(user?.id) &&
        contract.type !== ContractType.IntroducerReference))
  )
}

export function checkCurrencyInAlias(accountAlias = '', accountCurrency: string): string {
  const accountAliasToArray = accountAlias.split(' ')[0].toLocaleUpperCase()
  const valuesCurrency = Object.values(Currency)
  let checkCurrency = ''
  if (!valuesCurrency.includes(accountAliasToArray as Currency)) {
    checkCurrency = accountCurrency
  }
  return checkCurrency
}

export function batchPaymentCellValues(
  cellType: BeneficialCellType,
  data: Maybe<BatchPaymentRecord>,
): { valueError: string; valueCell: string | null | undefined } {
  const errors: string[] = data?.errors ? Object.keys(data?.errors) : []
  switch (cellType) {
    case BeneficialCellType.amount:
      const errorsAmount = errors.find((item) => ['amount', 'currency'].includes(item))
      return {
        valueError: errorsAmount || '',
        valueCell: `${currencyFormat(data?.amount as number)} ${data?.currency}`,
      }
    case BeneficialCellType.name:
      const errorsName = errors.find((item) =>
        ['beneficiaryType', 'beneficiaryName', 'street1', 'city', 'country'].includes(item),
      )
      return {
        valueError: errorsName || '',
        valueCell: data?.beneficiaryName,
      }
    case BeneficialCellType.ibanAccountNumber:
      const errorsAccount = errors.find((item) =>
        ['beneficiaryAccountIdType', 'beneficiaryAccountId'].includes(item),
      )
      return {
        valueError: errorsAccount || '',
        valueCell: data?.beneficiaryAccountId,
      }
    case BeneficialCellType.swiftBic:
      const errorsBank = errors.find((item) => item === 'bankBic')
      return {
        valueError: errorsBank || '',
        valueCell: data?.bankBic,
      }
    case BeneficialCellType.processing:
      const errorsProcess = errors.find((item) => item === 'priority')
      return {
        valueError: errorsProcess || '',
        valueCell: data?.priority,
      }
    case BeneficialCellType.reference:
      const errorsReference = errors.find((item) => item === 'reference')
      return {
        valueError: errorsReference || '',
        valueCell: data?.reference,
      }
    case BeneficialCellType.chargeBearer:
      const errorsCharges = errors.find((item) => item === 'chargeBearer')
      return {
        valueError: errorsCharges || '',
        valueCell: paidList[data?.chargeBearer as keyof typeof paidList]?.label,
      }
    case BeneficialCellType.payoutAmount: {
      const errorsPayoutAmount = errors.find((item) =>
        ['payoutAmount', 'payoutCurrency'].includes(item),
      )
      return {
        valueError: errorsPayoutAmount || '',
        valueCell: `${currencyFormat(data?.payoutAmount as number)} ${data?.payoutCurrency}`,
      }
    }
    case BeneficialCellType.usingLocalAch:
      const errorsUseLocalPay = errors.find((item) => item === 'useLocalAchPayout')
      return {
        valueError: errorsUseLocalPay || '',
        valueCell: data?.useLocalAchPayout ? i18n.t('yes', 'Yes') : i18n.t('no', 'No'),
      }
    case BeneficialCellType.fixedPayoutAmount:
      const errorsFixedPayoutAmount = errors.find((item) => item === 'fixedPayoutAmount')
      return {
        valueError: errorsFixedPayoutAmount || '',
        valueCell: data?.fixedPayoutAmount ? i18n.t('yes', 'Yes') : i18n.t('no', 'No'),
      }
    case BeneficialCellType.bankClearingMemberId:
      const errorsBankClearingMemberId = errors.find((item) => item === 'bankClearingMemberId')
      return {
        valueError: errorsBankClearingMemberId || '',
        valueCell: data?.bankClearingMemberId,
      }
    case BeneficialCellType.bankBranchId:
      const errorsBankBranchId = errors.find((item) => item === 'bankBranchId')
      return {
        valueError: errorsBankBranchId || '',
        valueCell: data?.bankBranchId,
      }
    case BeneficialCellType.bankBranchName:
      const errorsBankBranchNbankBranchName = errors.find((item) => item === 'bankBranchName')
      return {
        valueError: errorsBankBranchNbankBranchName || '',
        valueCell: data?.bankBranchName,
      }
    case BeneficialCellType.beneficiaryPhone:
      const errorsBeneficiaryPhone = errors.find((item) => item === 'beneficiaryPhone')
      return {
        valueError: errorsBeneficiaryPhone || '',
        valueCell: data?.beneficiaryPhone,
      }
    case BeneficialCellType.beneficiaryEmail:
      const errorsBeneficiaryEmail = errors.find((item) => item === 'beneficiaryEmail')
      return {
        valueError: errorsBeneficiaryEmail || '',
        valueCell: data?.beneficiaryEmail,
      }
    case BeneficialCellType.purposeCode:
      const errorsPurposeCode = errors.find((item) => item === 'purposeCode')
      return {
        valueError: errorsPurposeCode || '',
        valueCell: data?.purposeCode,
      }
    default:
      return {
        valueError: '',
        valueCell: '',
      }
  }
}

export function formatTwoFactorSetUpCode(code: string): string {
  return code.match(/.{1,3}/g)?.reduce((acc: string, val: string) => acc + ' ' + val) ?? ''
}

export function currenciesForSelectFunc(
  currencyVolumes: Record<string, string>[],
  thisSelector: number,
): (CurrencyType & { key: string })[] {
  const selectedCurrencies = [] as { key: number; value: string }[]
  currencyVolumes?.forEach((field, index) => {
    !!field.currency && selectedCurrencies.push({ key: index, value: field.currency })
  })
  const newSelectCurrencies: (CurrencyType & { key: string })[] = []
  for (const item of Object.values(currencies)) {
    !selectedCurrencies.find(
      (itemSel) => itemSel.key !== thisSelector && itemSel.value === item.key,
    ) && newSelectCurrencies.push(item)
  }

  return newSelectCurrencies
}

export function regionsForSelect(
  regionsData: { key: string; label: string }[],
  regions: Record<string, string>[],
  thisSelector: number,
) {
  if (!regionsData || !regions) return []

  const selectedRegions: { key: number; value: string }[] = []
  regions?.forEach((field, index) => {
    !!field.businessCategoryRegion &&
      selectedRegions.push({ key: index, value: field.businessCategoryRegion })
  })

  const newSelectRegions: { key: string; label: string }[] = []
  for (const item of Object.values(regionsData)) {
    !selectedRegions.find((region) => region.key !== thisSelector && region.value === item.key) &&
      newSelectRegions.push(item)
  }
  return newSelectRegions
}

export function getStatusRoundPill(data: Account): JSX.Element | undefined {
  switch (data.status) {
    case AccountStatus.Active:
      return (
        <Box data-test="accStatus" className={`roundedPill ${data.status}`}>
          {i18n.t('active', 'Active')}
        </Box>
      )
    case AccountStatus.PendingApproval:
      return (
        <Box data-test="accStatus" className={`roundedPill ${data.status}`}>
          {i18n.t('pendingApprovalStatus', 'Pending approval')}
        </Box>
      )
    case AccountStatus.PendingOpeningSignatures:
    case AccountStatus.PendingClosureSignatures:
      return (
        <Box data-test="accStatus" className={`roundedPill ${data.status}`}>
          {i18n.t('pendingActions', 'Pending actions')}
        </Box>
      )
    case AccountStatus.PendingIncomingPayment:
      return (
        <Box data-test="accStatus" className={`roundedPill ${data.status}`}>
          {i18n.t('pendingIncomingPayment', 'Pending incoming payment')}
        </Box>
      )
    case AccountStatus.Suspended:
      return (
        <Box data-test="accStatus" className={`roundedPill ${data.status}`}>
          {i18n.t('suspended', 'Suspended')}
        </Box>
      )
    case AccountStatus.Closed:
      return (
        <Box data-test="accStatus" className={`roundedPill ${data.status}`}>
          {i18n.t('closed', 'Closed')}
        </Box>
      )
    case AccountStatus.Rejected:
      return (
        <Box data-test="accStatus" className={`roundedPill ${data.status}`}>
          {i18n.t('rejected', 'Rejected')}
        </Box>
      )
  }
}

export function getWalletStatusRoundPill(
  data: Wallet,
  isLimitedAccess: boolean,
  signatureAccepted: boolean,
): JSX.Element | undefined {
  switch (data.status) {
    case WalletStatus.Active:
      return <Box className={`roundedPill ${data.status}`}>{i18n.t('active', 'Active')}</Box>
    case WalletStatus.PendingApproval:
      return (
        <Box className={`roundedPill ${data.status}`}>
          {i18n.t('pendingApprovalStatus', 'Pending approval')}
        </Box>
      )
    case WalletStatus.PendingConfirmation:
      return (
        <Box className="roundedPill pending">
          {i18n.t('clickToVerifyWallet', 'Click to verify wallet')}
        </Box>
      )
    case WalletStatus.Verified:
      return (
        <Box className="roundedPill pending">
          {i18n.t('clickToSetupAccount', 'Click to Setup Account')}
        </Box>
      )
    case WalletStatus.PendingSignatures:
      return (
        <Box className="roundedPill pending">
          {isLimitedAccess
            ? i18n.t('pendingActions', 'Pending actions')
            : signatureAccepted
            ? i18n.t('addlSignaturesRequired', 'Addtl. Signatures Required')
            : i18n.t('clickToSign', 'Click to sign')}
        </Box>
      )
    case WalletStatus.Disconnected:
      return (
        <Box className={`roundedPill ${data.status}`}>{i18n.t('disconnected', 'Disconnected')}</Box>
      )
    case WalletStatus.Rejected:
      return (
        <Box className={`roundedPill ${data.status}`}>
          {i18n.t('notPermitted', 'Not permitted')}
        </Box>
      )
    case WalletStatus.Suspended:
      return (
        <Box className="roundedPill pending">
          {i18n.t('pendingClarifications', 'Pending Clarifications')}
        </Box>
      )
  }
}

const getCountriesFullName = (countriesArray: string[]): (string | undefined)[] => {
  return countriesArray.map(
    (country) =>
      [...Object.values(regions), ...Object.values(countries)].find((c) => c.key === country)
        ?.label,
  )
}

export function contractChangesNew(
  oldData: ProfileChangeAnswer,
  newData: ProfileChangeAnswer,
): ContractChanges[] {
  const foundDiff: ContractChanges[] = []

  // function addressDiffs(
  //   oldDataAddress: Maybe<Address> | undefined,
  //   newDataAddress: Maybe<Address> | undefined,
  //   addressType: AddressTypeOptions,
  // ) {
  //   const checkedAddressObject = (oldDataAddress ? oldDataAddress : newDataAddress) || {}
  //   Object.keys(checkedAddressObject as Address).forEach(function (key) {
  //     const checkedAddressFields = [
  //       'additionalDetails',
  //       'city',
  //       'line1',
  //       'zip',
  //       'countryDescription',
  //     ]
  //     if (
  //       checkedAddressFields.includes(key) &&
  //       (!isEmpty(getProperty(oldDataAddress ?? {}, key as keyof Maybe<Address>))
  //         ? getProperty(oldDataAddress ?? {}, key as keyof Maybe<Address>)
  //         : '') !==
  //         (!isEmpty(getProperty(newDataAddress ?? {}, key as keyof Maybe<Address>))
  //           ? getProperty(newDataAddress ?? {}, key as keyof Maybe<Address>)
  //           : '')
  //     ) {
  //       foundDiff.push({
  //         addressType: addressType,
  //         changedProperty: key,
  //         oldValue: getProperty(oldDataAddress ?? {}, key as keyof Maybe<Address>),
  //         newValue: getProperty(newDataAddress ?? {}, key as keyof Maybe<Address>),
  //       })
  //     }
  //   })
  // }

  // if (newData?.address) {
  //   addressDiffs(oldData?.address, newData?.address, AddressTypeOptions.address)
  // }
  // if (
  //   newData.__typename === 'ContractProfileChangeAnswers' &&
  //   newData?.correspondenceAddress &&
  //   oldData.__typename === 'ContractProfileChangeAnswers' &&
  //   oldData?.correspondenceAddress
  // ) {
  //   addressDiffs(
  //     oldData?.correspondenceAddress,
  //     newData?.correspondenceAddress,
  //     AddressTypeOptions.correspondenceAddress,
  //   )
  // }

  function reqursionParse(
    valData: Record<string, unknown> | string | number | boolean | [],
    key: string,
    oldVal: Record<string, unknown> | string | number | boolean | [],
    correspondence?: AddressTypeOptions,
  ) {
    if (
      (!valData && !oldVal) ||
      (isEmpty(valData) &&
        typeof valData !== 'boolean' &&
        typeof valData !== 'string' &&
        typeof valData !== 'number') ||
      key === '__typename' ||
      key === 'processingHistoryStatement'
    ) {
      return
    }

    const option =
      key === 'correspondenceAddress' ? AddressTypeOptions.correspondenceAddress : undefined

    if (typeof valData === 'boolean') {
      foundDiff.push({
        addressType: correspondence,
        changedProperty: key,
        oldValue: oldVal,
        newValue: valData,
      })
      return
    }

    if (typeof valData === 'number') {
      foundDiff.push({
        addressType: correspondence,
        changedProperty: key,
        oldValue: oldVal,
        newValue: valData,
      })
      return
    }

    if (typeof valData === 'string') {
      const oldValue = oldVal ? getEnumLabel(oldVal as string, key) : '-'
      const newValue = getEnumLabel(valData, key)
      if (key !== 'country') {
        foundDiff.push({
          addressType: correspondence,
          changedProperty: key,
          oldValue: oldValue,
          newValue: newValue,
        })
      }
      return
    }
    if (isArray(valData)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (valData.length > 0 && typeof valData[0] === 'object') {
        const newArr: string[] = []
        const oldArr: string[] = []
        valData?.forEach((obj) => {
          newArr.push(obj)
        })

        isArray(oldVal) &&
          oldVal?.forEach((obj: never) => {
            !isEmpty(obj) && oldArr.push(obj)
          })

        foundDiff.push({
          addressType: correspondence,
          changedProperty: key,
          oldValue: !isEmpty(oldArr) ? (oldArr as []) : '-',
          newValue: newArr as [],
        })
      } else {
        if (key === 'countriesOfOperatingAndConductingBusiness' && !isEmpty(valData)) {
          const oldValueCountries = isArray(oldVal) && getCountriesFullName(oldVal)
          const newValueCountries = isArray(valData) && getCountriesFullName(valData)

          foundDiff.push({
            addressType: correspondence,
            changedProperty: key,
            oldValue: oldValueCountries ?? '-',
            newValue: newValueCountries,
          })
        } else {
          foundDiff.push({
            addressType: correspondence,
            changedProperty: key,
            oldValue: oldVal ?? '-',
            newValue: valData,
          })
        }
      }
      return
    }

    const passObjectsTypes = ['TotalPredictedMonthlyVolumes']
    if (typeof valData === 'object' && passObjectsTypes.includes(valData.__typename as string)) {
      foundDiff.push({
        addressType: correspondence,
        changedProperty: key,
        oldValue: oldVal ?? '-',
        newValue: valData,
      })
    }

    Object.keys(valData).forEach((ke) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reqursionParse(valData[ke] as Record<string, unknown>, ke, oldVal?.[ke], option)
    })
  }

  Object.keys(newData).forEach(function (key) {
    if (
      !!newData[key as keyof ProfileChangeAnswer] &&
      typeof newData[key as keyof ProfileChangeAnswer] === 'object' &&
      key !== 'processingHistoryStatement'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reqursionParse(newData[key], key, oldData[key])
    } else if (!!newData[key as keyof ProfileChangeAnswer] && key !== '__typename') {
      const oldVal = oldData ? getProperty(oldData, key as keyof ProfileChangeAnswer) : '-'
      const newVal = getProperty(newData, key as keyof ProfileChangeAnswer)
      foundDiff.push({
        addressType: undefined,
        changedProperty: key,
        oldValue: isArray(oldVal) ? oldVal : getEnumLabel(oldVal as string, key),
        newValue: isArray(newVal) ? newVal : getEnumLabel(newVal, key),
      })
    }
  })

  return foundDiff
}

export const preventEnterNonNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const isDigit = /[0-9.]/.test(e.key)
  if (!isDigit) {
    e.preventDefault()
  }
}

export const parseWebsitesFormData = (websites?: WebsiteField[] | null): string[] => {
  if (!websites) return []
  return websites.filter((site) => !isEmpty(site.value)).map((site) => site.value)
}

export function getTrimmedWalletAddress(address?: string | null) {
  if (!address) return ''
  return address
    ? `${address.substring(0, 8)}...${address.substring(address.length - 8, address.length)}`
    : ''
}

export const currencyFormatWithSign = (
  value = 0,
  signOrText: 'sign' | 'leadingText' | 'trailingText' | 'format' | undefined,
  currency: string,
  options?: Intl.NumberFormatOptions,
): string => {
  const isCryptoCurrency = !Object.values(Currency).includes(currency as Currency)
  if (signOrText === 'trailingText' || isCryptoCurrency) {
    return `${currencyFormat(value, undefined, options, isCryptoCurrency)} ${currency}`
  }

  if (signOrText === 'leadingText') {
    return `${currency} ${currencyFormat(value, undefined, options)}`
  }

  if (signOrText === 'format') {
    return currencyFormat(value, currency, options)
  }

  if (signOrText === 'sign') {
    return `${getCurrencySign(currency as string)} ${currencyFormat(value, undefined, options)}`
  }

  return currencyFormat(value, currency, options)
}
