import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Grid'
import { Check } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { debounce, filter, find, isEmpty, isEqual, orderBy, sortBy, toNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import DownIcon from '../../../assets/images/icons/download.svg?react'
import {
  displayAccountStatusKeys,
  initialTransactionPageState,
  SELECTED_ACCOUNT_ID,
} from '../../../constants'
import {
  Account,
  AccountType,
  Currency,
  FundsType,
  Maybe,
  TransactionOrderBy,
  useGetContractAccountsQuery,
  useGetContractTransactionsCountQuery,
} from '../../../graphql'
import { trxPageStateVar } from '../../../graphql/local'
import { usePageFiltersSorting } from '../../../hooks'
import { useManageGuaranteedTabsUrlParams } from '../../../pages/TransactionsPage/hooks/useManageGuaranteedTabsUrlParams'
import { PATH_PARAMS } from '../../../routes/paths'
import { currencyFormat, getCurrencySign, negativeCheck, sortAccountStatuses } from '../../../utils'
import { DownloadStatementModal } from '../../../pages/TransactionsPage/DownloadStatementModal'
import { accountStatusesForTransactionsShown } from '../../../utils/Data'
import { ButtonWithTooltip } from '../Buttons'

const useStyles = (isViban: boolean, isLa: boolean, withoutFrame: boolean | undefined) =>
  makeStyles((theme) => ({
    popper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      padding: 0,
      position: 'absolute',
      width: 258,
      left: 0,
      top: '160px',
      zIndex: 300,
      height: 300,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    paper: {
      marginRight: 0,
      maxWidth: '100%',
      background: '#FFFFFF',
      borderRadius: 0,
      overflowX: 'hidden',
      boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        outline: '1px solid #efefef',
        borderRadius: '0.05em',
      },
    },
    accSelector: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      padding: '4px 0px 4px 0',
      position: 'relative',
      background: '#FFFFFF',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: 0,
        justifyContent: isViban ? 'end' : 'space-between',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: isViban ? 'end' : 'space-between',
        flexDirection: 'unset',
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    cardLabel: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 26px 16px 8px',
      width: '100%',
    },
    alias: {
      padding: '0 5px 0 0',
    },
    balance: {
      display: 'flex',
      '&>div': {
        fontWeight: '700',
        '&:first-child': {
          padding: '0 5px 0 0',
        },
      },
    },
    accButton: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      margin: '5px 0',
      minWidth: '150px',
      width: 258,
      height: 48,
      padding: 0,
      minHeight: 32,
      background: 'none',
      ...(withoutFrame ? { borderBottom: '1px solid #c4c4c4' } : { border: '1px solid #c4c4c4' }),
      boxSizing: 'border-box',
      position: 'relative',
      fontSize: '16px',
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: 5,
      },
      '&.open': {
        ...(withoutFrame ? { borderBottom: '1px solid #000000' } : { border: '1px solid #000000' }),
        backgroundColor: 'transparent',
      },
      '&.MuiButton-root.Mui-disabled': {
        color: '#000000',
      },
      [theme.breakpoints.down('md')]: {
        width: withoutFrame ? '100%' : '45%',
        margin: '5px 0 5px 0',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '5px 0 5px 0',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '10px 0 10px 0',
      },
    },
    item: {
      '& .MuiTypography-displayBlock': {
        marginBottom: '0px',
        paddingLeft: theme.spacing(1),
      },
      '& .MuiListItemIcon-root': {
        minWidth: '0.5em',
        marginRight: theme.spacing(2),
      },
      '& .flag-icon-lg': {
        fontSize: '0.8em',
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'start',
      padding: '0px 12px 0px 12px',
      position: 'static',
      width: '100%',
      height: 'auto',
      minHeight: '58px',
      left: '0px',
      top: '48px',
      background: '#FFFFFF',
      justifyContent: 'space-between',
    },
    statusRow: {
      width: '30%',
      flex: '0 0 auto',
    },
    checkRow: {
      flex: '0 0 auto',
      width: '10%',
      paddingLeft: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
    },
    listItemCheck: {
      color: '#000000',
    },
    list: {
      boxShadow: '5px 5px 4px 0px rgba(34, 60, 80, 0.2)',
      backgroundColor: 'white',
    },
    arrowColor: {
      color: '#999999',
    },
    arrowDropDown: {
      rotate: '180deg',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    btn: {
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        width: isLa ? '100%' : 'auto',
        marginTop: 0,
      },
      '& .MuiButton-root': {
        padding: 8,
        caretColor: 'transparent',
      },
      '& button': {
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    selectAccount: {
      margin: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    buttonGroup: {
      marginLeft: theme.spacing(2),
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1.5),
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        gap: 'unset',
        justifyContent: 'space-between',
        margin: 0,
      },
    },
    selected: {
      position: 'relative',
      width: '100%',
      height: 48,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    label: {
      position: 'absolute',
      top: -4,
      left: theme.spacing(2),
      color: '#999999',
    },
    accAliasWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 6,
    },
  }))()

const TransactionsAccountSelectComponent: FC<{
  setAccountId: (id: string | number | undefined) => void
  accountId: string | number | undefined
  currencies?: Currency[]
  fromCardId?: string | undefined
  vbanId?: string | undefined
  isLimitedAcces?: boolean
  showDownloadButton?: boolean
  children?: React.ReactNode
  accountTypes?: AccountType[]
  withoutFrame?: boolean
}> = ({
  setAccountId,
  accountId,
  fromCardId,
  vbanId,
  isLimitedAcces = true,
  showDownloadButton = true,
  children,
  currencies,
  accountTypes,
  withoutFrame,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([])
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [acc, setAcc] = useState<Account>()
  const [accAll, setAccAll] = useState<string>()
  const history = useHistory()
  const { t } = useTranslation()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const classes = useStyles(!!vbanId, isLimitedAcces, withoutFrame)

  const { setTabValue } = useManageGuaranteedTabsUrlParams()

  const { data: accountsData, loading } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: accountStatusesForTransactionsShown,
      isVirtual: !!vbanId,
      currencies,
      types: accountTypes,
    },
  })

  const { setPageFilters } = usePageFiltersSorting(trxPageStateVar)

  const { data: allUserTransactionsCount } = useGetContractTransactionsCountQuery({
    variables: {
      contractId: applicationId,
    },
  })

  const sortByAccounts = useMemo(() => {
    const routeCheck =
      history.location.pathname.includes('standing-orders') ||
      history.location.pathname.includes('direct-debits')
    const filteredAccs = routeCheck
      ? filter(
          accountsData?.accounts,
          (acc) => acc?.fundsType !== FundsType.DepositGuaranteed && !acc?.qrrSupported,
        )
      : accountsData?.accounts

    const orderByAccounts = orderBy(filteredAccs, ['balance.available'], ['desc']) as Account[]
    return sortBy(orderByAccounts, sortAccountStatuses) as Account[]
  }, [accountsData, history.location.pathname])

  const disableStatementDtn =
    !allUserTransactionsCount?.contractTransactionsCount || isEmpty(accountsData?.accounts)

  const onDownloadStatement = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleChange = useCallback(
    (id) => {
      setAccountId(id)
      !!id && localStorage.setItem(SELECTED_ACCOUNT_ID, String(id))
      setOpen(false)
      const account = accountsData?.accounts?.find((account) => account?.id === id)
      setPageFilters({
        ...initialTransactionPageState,
        ...(account?.fundsType === FundsType.DepositGuaranteed
          ? { sortBy: TransactionOrderBy.CreatedAt, notStatus: 'pending' }
          : {}),
      })
      setTabValue()
      // for resetting page pagination to 1 when an account changed
      history.location.state = undefined
    },
    [setAccountId, accountsData, setTabValue],
  )

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleCloseList = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
    setTimeout(() => sortByAccounts && setFilteredAccounts(sortByAccounts), 600)
  }

  const handleAccountSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const insertedValue = e.target.value.trim().toLowerCase()

    const filteredAccounts = sortByAccounts.filter(({ alias }) =>
      alias?.toLowerCase()?.includes(insertedValue),
    )

    if (filteredAccounts.length === 1) {
      return handleChange(filteredAccounts[0].id)
    }

    setFilteredAccounts(filteredAccounts)
  }, 100)

  useEffect(() => {
    if (!!accountId && !open) {
      const foundAcc = find(accountsData?.accounts, (accItem) => accItem?.id === accountId)
      const maxBalance = accountsData?.accounts?.reduce(
        (acc: Maybe<Account>, curr) =>
          toNumber(acc?.balance?.available) > toNumber(curr?.balance?.available) ? acc : curr,
        null,
      )
      if (!isEmpty(accountsData?.accounts) && (!!foundAcc || !!maxBalance)) {
        const selectedAccount = !!foundAcc ? foundAcc : (maxBalance as Account)
        setAcc(selectedAccount)
        setAccAll(accountId === '#allAccounts' ? 'allAccounts' : '')
        const accountIdForSet = String(
          accountId === '#allAccounts' ? '#allAccounts' : selectedAccount?.id,
        )
        localStorage.setItem(SELECTED_ACCOUNT_ID, accountIdForSet)
        setAccountId(accountIdForSet)
      }
    }
  }, [accountsData, accountId, open])

  useEffect(() => {
    const localAccount = localStorage.getItem(SELECTED_ACCOUNT_ID)
    !fromCardId &&
      !vbanId &&
      localAccount &&
      setAccountId(localAccount === 'undefined' ? '#allAccounts' : localAccount)
  }, [fromCardId, vbanId, setAccountId])

  useEffect(() => {
    if (sortByAccounts) {
      setFilteredAccounts(sortByAccounts)
    }
  }, [sortByAccounts])

  return (
    <>
      <Box item className={classes.container}>
        <Grid item className={classes.selectAccount}>
          <Box className={classes.accSelector}>
            {!vbanId && (
              <Box className={classes.accButton}>
                <Button
                  className={clsx(classes.selected, open && 'open')}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  disableRipple
                  disabled={accountsData?.accounts?.length === 1}
                  ref={anchorRef}
                >
                  {withoutFrame && (
                    <Typography variant="subtitle2" className={classes.label}>
                      {t('account', 'Account')}
                    </Typography>
                  )}
                  <Typography component="div" className={classes.cardLabel}>
                    {accAll === 'allAccounts' ? (
                      <Typography
                        component="div"
                        className={classes.alias}
                        data-test="accNameInFilter"
                        noWrap
                      >
                        {t(`${accAll}`)}
                      </Typography>
                    ) : acc ? (
                      <>
                        <Typography
                          component="div"
                          className={classes.alias}
                          data-test="accNameInFilter"
                          noWrap
                        >
                          {acc?.alias}
                        </Typography>
                        <Typography
                          component="div"
                          className={`${classes.balance} ${negativeCheck(acc?.balance?.available)}`}
                        >
                          <Typography component="div">
                            {getCurrencySign(acc?.currency as string)}
                          </Typography>
                          <Typography component="div">
                            {currencyFormat(acc?.balance?.available as number)}
                          </Typography>
                        </Typography>
                      </>
                    ) : (
                      <Typography component="div">{t('allAccounts', 'All accounts')}</Typography>
                    )}
                  </Typography>
                  {!filteredAccounts.length ? null : open ? (
                    <ExpandMoreIcon className={classes.arrowDropDown} />
                  ) : (
                    <ExpandMoreIcon className={classes.arrowColor} />
                  )}
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  transition
                  disablePortal
                  className={classes.popper}
                  placement="bottom-start"
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top',
                      }}
                    >
                      <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={handleCloseList}>
                          <List id="menu-list-grow" className={classes.list}>
                            <ListItem>
                              <TextField
                                onChange={handleAccountSearch}
                                label={t('selectAccount', 'Select Account')}
                                variant={'standard'}
                                fullWidth={true}
                              />
                            </ListItem>
                            <ListItem
                              key={'#all'}
                              button
                              className={classes.item}
                              onClick={() => handleChange('#allAccounts')}
                            >
                              <ListItemText>{t('allAccounts', 'All accounts')}</ListItemText>
                              <Box className={classes.checkRow}>
                                {accountId === '#allAccounts' && (
                                  <Check className={classes.listItemCheck} fontSize={'small'} />
                                )}
                              </Box>
                            </ListItem>
                            {!loading &&
                              filteredAccounts.map((account) => {
                                const { id, alias, currency, balance, status } = account as Account
                                const normalizedBalance = `${getCurrencySign(
                                  currency as string,
                                )} ${currencyFormat(balance?.available as number)}`

                                return (
                                  <ListItem key={id} button onClick={() => handleChange(id)}>
                                    <ListItemText>
                                      <Box className={classes.accAliasWrap}>
                                        <Typography noWrap>{alias}</Typography>
                                        <Box
                                          component="span"
                                          className={clsx(`roundedPill ${status as string}`)}
                                        >
                                          {t(displayAccountStatusKeys[status as string])}
                                        </Box>
                                      </Box>
                                      <Box>
                                        <b className={negativeCheck(balance?.available)}>
                                          {normalizedBalance}
                                        </b>
                                      </Box>
                                    </ListItemText>
                                    <Box>{accountId === id && <Check fontSize={'small'} />}</Box>
                                  </ListItem>
                                )
                              })}
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            )}
            <Box className={classes.buttonGroup}>
              {children}
              {showDownloadButton && (
                <ButtonWithTooltip
                  className={classes.btn}
                  title={`${t(
                    'statementIsNotAvailable',
                    `Statements are not available because you don't have any transactions`,
                  )}`}
                  disabled={disableStatementDtn}
                  placement="top-end"
                  variant="contained"
                  onClick={onDownloadStatement}
                  disableElevation
                  disableRipple
                  color="default"
                >
                  <DownIcon />
                </ButtonWithTooltip>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>

      {isOpen && <DownloadStatementModal handleClose={handleClose} accountId={accountId} />}
    </>
  )
}

export const TransactionsAccountSelect = React.memo(TransactionsAccountSelectComponent, isEqual)
