import React, { FC, useMemo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { contractsTeamPageStateVar, SearchField } from '../../../components'
import { FilterSelector } from '../../../components/Common/Selectors/FilterSelector'
import { authorizedPersonStatus } from '../../../constants'
import { AuthorizedPersonRights } from '../../../graphql'
import { useDetermineUserRights, usePageFiltersSorting } from '../../../hooks'
import { PATH_PARAMS } from '../../../routes/paths'
import { SearchName, StatusSelectorType } from '../../../types'
import { rightsTeamRole } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  filterBox: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1.5),
    },
  },
  searchField: {
    flex: 1,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  filterButton: {
    flex: 1,
    height: '48px',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      paddingRight: 0,
      '&.selector': {
        margin: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&.selector': {
        width: '100%',
        paddingRight: 0,
        margin: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      flex: 'unset',
      width: '100%',
    },
  },
}))

const TeamFiltersComponent: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { pageFilters, setPageFilters } = usePageFiltersSorting(contractsTeamPageStateVar)
  const { userRights } = useDetermineUserRights(applicationId)

  const statusOptions: { key: keyof typeof authorizedPersonStatus; label: string }[] = [
    { key: StatusSelectorType.AllStatuses, label: t('allStatuses', 'All statuses') },
  ]
  for (const item in authorizedPersonStatus) {
    statusOptions.push({ key: item, label: t(authorizedPersonStatus[item]) })
  }

  const rolesOptions = useMemo(() => {
    const rolesList = Object.keys(AuthorizedPersonRights).reduce(
      (
        acc: { key: AuthorizedPersonRights | StatusSelectorType; label: string }[],
        role: string,
      ) => {
        if (!userRights?.hasCardPayment && role === 'PaymentFacilitationView') {
          return acc
        }
        const key = AuthorizedPersonRights[role as keyof typeof AuthorizedPersonRights]
        acc.push({
          key,
          label: rightsTeamRole(key),
        })
        return acc
      },
      [{ key: StatusSelectorType.AllRoles, label: t('allRoles', 'All roles') }],
    )

    const facilitatorViewOption = find(
      rolesList,
      (item) => item.key === AuthorizedPersonRights.PaymentFacilitationView,
    )

    return facilitatorViewOption
      ? sortBy(rolesList, (item) => item.key === AuthorizedPersonRights.PaymentFacilitationView)
      : rolesList
  }, [userRights])

  return (
    <Box className={classes.filterBox}>
      <Box className={classes.searchField}>
        <SearchField
          name={SearchName.SearchTeam}
          setValue={(value) => setPageFilters({ searchValue: value, page: 1 })}
          currentValue={pageFilters.searchValue}
          placeHolder={t('searchTeamMemberByName', 'Search team member by name')}
        />
      </Box>
      <Box className={clsx(classes.filterButton, `selector`)}>
        <FilterSelector
          optionsList={rolesOptions}
          setValue={(value) => setPageFilters({ role: value as AuthorizedPersonRights, page: 1 })}
          currentValue={pageFilters.role}
        />
      </Box>
      <Box className={clsx(classes.filterButton, `selector`)}>
        <FilterSelector
          optionsList={statusOptions}
          setValue={(value) => setPageFilters({ status: value, page: 1 })}
          currentValue={pageFilters.status}
        />
      </Box>
    </Box>
  )
}

export const TeamFilters = React.memo(TeamFiltersComponent, isEqual)
