import React, { FC, useContext, useEffect } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { GoBackButton, PaymentContext } from '../../components'
import { useCustomHistory } from '../../hooks'
import { DetailsFooter } from './DetailsFooter'
import { BatchPaymentProps, getSignatures } from './contract'
import { Account, Action, ActionType, BatchPaymentActionType } from '../../graphql'
import { BatchTab } from './Tabs/BatchTab'
import { formatIban } from '../../utils'

const useStyles = makeStyles((theme) => ({
  wrap: {
    overflow: 'hidden',
    padding: theme.spacing(10, 1, 1, 1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(0),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  },
}))

export const BatchDetailPage: FC<{
  title: string
  actionDetails: Action
  signedCount: number
}> = ({ title, signedCount, actionDetails }) => {
  const classes = useStyles()

  const infoBatchPayment = (actionDetails as BatchPaymentActionType)?.batchPayment
  const { setTableStatus, setBatchListPage } = useContext(PaymentContext)
  const { handleGoBack } = useCustomHistory()

  const ApProps: BatchPaymentProps = {
    status: infoBatchPayment?.status,
    account: (infoBatchPayment?.account as Account)?.alias,
    iban: formatIban((infoBatchPayment?.account as Account)?.iban as string),
    rawData: actionDetails,
    accountNumber: (infoBatchPayment?.account as Account)?.accountNumber,
    accId: (infoBatchPayment?.account as Account)?.id as string,
    successfulPayments: (infoBatchPayment?.account as Account)?.paymentRecipients?.length as number,
    signatures: getSignatures(actionDetails?.signatures),
    creator: actionDetails?.initiatorName,
  }

  useEffect(() => {
    setBatchListPage(1)

    return () => {
      setTableStatus(false)
      setBatchListPage(1)
    }
  }, [setTableStatus, setBatchListPage])

  return (
    <Grid container className={classes.wrap}>
      <Grid item xs={12} md={10}>
        <Grid item container className={classes.head}>
          <GoBackButton />
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <BatchTab data={ApProps} signedCount={signedCount} />
        <DetailsFooter
          actionSignatureProps={ApProps}
          handleBack={handleGoBack}
          type={ActionType.BatchPayment}
        />
      </Grid>
    </Grid>
  )
}

export default BatchDetailPage
