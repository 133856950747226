import { TextFieldProps } from '@material-ui/core/TextField'
import { ControllerProps } from 'react-hook-form'
import {
  Account,
  AccountStatus,
  ActionsOrderBy,
  ActionStatus,
  AuthorizedPersonRights,
  AuthorizedPersonStatuses,
  BankableEntityContractType,
  CardsOrderBy,
  CardSpendingLimitTypes,
  ContractAuthorizedPersonType,
  ContractAuthPersonOrderBy,
  ContractChangeAction,
  ContractContact,
  ContractPaymentRecipient,
  ContractPaymentRecipientOrderByType,
  ContractUbo,
  Country,
  DebitTransaction,
  DirectDebitExecutionStatus,
  DirectDebitMandateStatus,
  DocumentOrderBy,
  FundsType,
  GetAccountQuery,
  GetContractDetailsQuery,
  GetTransactionsQuery,
  Individual,
  Maybe,
  NewAccountCurrency,
  OrderBy,
  OrderDirection,
  PaymentMethod,
  PaymentsOrderBy,
  PaymentStatus,
  ReservedFxRateDetails,
  SpendingLimitRequestAction,
  StandingOrderPeriodicity,
  SuccessFeeOrderBy,
  SwiftTransferChargeBearer,
  SwiftTransferRecipientType,
  TransactionExecutionType,
  TransactionOrderBy,
  TransactionPriorityType,
  TransactionRequestStatus,
  TwoFaMethod,
  UboLegalEntityType,
  UboOwner,
  UboStakeType,
} from '../graphql'
import React, { ReactNode } from 'react'
import { RegisterOptions } from 'react-hook-form/dist/types'
import { ActionSignatureProps } from '../pages/AplicationActions/contract'
import { IFileWithMeta, IMeta } from 'react-dropzone-uploader'
import { StandingOrdersOrderBy } from '../pages/StandingOrdersPage'

export type MaybePromise<T> = T | Promise<T>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ControlledTextFieldProps = TextFieldProps & ControllerProps<any>

export type CalendlyCollectionObjectType = {
  created_at: string
  end_time: string
  /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  event_guests: any[]
  event_memberships: Array<{ user: string }>
  event_type: string
  invitees_counter: {
    active: number
    limit: number
    total: number
  }
  location: {
    /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    location: any
    type: string
  }
  name: string
  start_time: string
  status: string
  updated_at: string
  uri: string
}

// export type CalendlyType = {
//   collection: CalendlyCollectionObjectType[]
//   pagination: {
//     count: number | null
//     next_page: number | null
//   }
// }

declare global {
  interface Window {
    /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    Calendly: any
  }
}

export interface IAppRouteSetting {
  path?: string
  guard?: React.FunctionComponent
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layout?: React.FunctionComponent<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.FunctionComponent<any> | React.LazyExoticComponent<React.FunctionComponent<any>>
  routes?: Array<IAppRouteSetting>
  exact?: boolean
}

export interface AuthPersonsSelectType {
  key: string | number
  label: string
  isAuthorizedSignatory: string
}

export type PreparedContracUbo = ContractUbo & { ownersArr: UboOwner[] }

export type DocIdsType = {
  name: string
  size: number
  docType: string
  relatesTo: string
  datePicker: Date
  docTypeError: boolean
  relatesToError: boolean
  datePickerError: boolean
  descriptionError: boolean
  errorUpload: boolean
  fileDescription: string
  fileDescriptionRequired: boolean
  fileUrl: string
}

export type DocumentUploadProps = {
  setOpen: (value: boolean) => void
  pushToInit: File[]
  setPushToInit: (value: File[]) => void
  docIds: DocIdsType[]
  setDocIds: React.Dispatch<React.SetStateAction<DocIdsType[]>>
  relatesToData:
    | { key: string; label: Maybe<string> | undefined }[]
    | { value: number; label: string }[]
  docType?: string | null
}

export interface DocumentUploadPropsForContract extends Omit<DocumentUploadProps, 'setDocIds'> {
  setDocIds: (idValuesArr: DocIdsType[]) => void
}

export enum CongratulationsScreenType {
  pendingApproval = 'pendingApproval',
  successWithActionButton = 'successWithActionButton',
}

export enum CreateOwnDocMutationType {
  updateContractUbo = 'updateContractUbo',
  addContractUbo = 'addContractUbo',
  updateUboOwner = 'updateUboOwner',
  addUboOwner = 'addUboOwner',
}

export type FilesForSavingAndShowType = {
  id: number
  fileName: string
  size: number
  mimeType?: string
  docType: string
  plainUrl?: string
  expiration?: Date | undefined | null
  metaType?: string
  isAlreadyUploaded?: boolean
  isRemoved?: boolean
  isFirstSide?: boolean
  documentName?: string
}

export type TotalBalanceAsset = {
  amount: number
}

export type CurrencyType = {
  key: string
  label: string
  iconImage: string
  allowedNewAccounts: FundsType[]
}
export type CurrencyAsset = {
  key: string
  label: string
  countryKey: string
  // precision: number
}

export type RoleInvite = {
  key: string
  label: string
}

export interface HomeData {
  account?: string
  date: string
  txID: string
  type: TransactionType
  amount: string
  balance: string
  fromTo: string
  status: TransactionStatus
}

export enum TransactionStatus {
  Received = 'received',
  Signed = 'signed',
  Rejected = 'rejected',
  Released = 'released',
  Pending = 'pending',
  Held = 'held',
  Sent = 'sent',
}

export enum TransactionType {
  Credit = 'Credit',
  Debit = 'Debit',
  Transfer = 'Transfer',
}

export enum TransactionFullNameType {
  credit = 'CreditTransaction',
  debit = 'DebitTransaction',
}

export interface TransactionTypeNames {
  Credit: string
  Debit: string
  Transfer: string
}

export interface TransactionStatusNames {
  received: string
  signed: string
  rejected: string
  released: string
  pending: string
  held: string
}

export type Response2FA_type = {
  challengeId: string
  requires2FA: boolean
  resendTimeout: number
  phoneLastFourDigits: string
  attemptsLeft: number
  twoFaType: TwoFaMethod
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FormInputFieldEntity extends Partial<any> {
  type?: string
  name: string
  label?: ReactNode
  id?: string
  placeholder?: string
  validations?: RegisterOptions
  helpText?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateValue?: (name: string, value: any) => void
  cssProp?: string
  disabled?: boolean
  shouldValidateParam?: boolean
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  externalError?: Error
}

export type DetailsContractsByIndividualType = {
  individual: Individual
  isAuthorizedPersons: boolean
  contractsAp: BankableEntityContractType[]
  contractsApplicant: BankableEntityContractType[]
  individualContract: BankableEntityContractType | null
}

export enum SingleOrPlural {
  Single = 'single',
  Plural = 'plural',
}

export type ShowAlertType = {
  key: string
  value: string
  messageParam1?: string
  messageParam2?: string
  messageParam3?: string
}

export enum ActionButtonType {
  onAdd = 'onAdd',
  onProceed = 'onProceed',
}

export type LegalList = {
  key: string
  label: Maybe<string> | undefined
  legalEntityType?: Maybe<UboLegalEntityType>
  ownersTotalShareSize?: Maybe<number> | undefined
}

export type PaymentFormTypes = {
  form: {
    account: string
    name: string
    amount: string
    payoutAmount?: string
    currency: string
    recepient: string
    reference: string
    chargeBearer: Maybe<SwiftTransferChargeBearer> | undefined
    recipientType: SwiftTransferRecipientType
    country: Maybe<Country> | undefined
    city: string
    postalCode: string
    iban?: string
    swift?: string
    accountNumber?: string
    streetAddress?: string
    additionalDetailsOptional?: string
    execution: TransactionExecutionType
    executionDate?: string
    isSweeping?: boolean
    lowerThreshold?: string
    minRemainingBalance?: string
    periodicity?: StandingOrderPeriodicity
    dateExecution?: string
    quotedFXRate?: ReservedFxRateDetails
    clientReference?: string
    useLocalPay?: boolean
    purposeCode: Maybe<string> | undefined
    purposeCode2: Maybe<string> | undefined
    creditorContractPhone: Maybe<string> | undefined
    creditorTaxId: Maybe<string> | undefined
    memberId: Maybe<string> | undefined
    agentBranchId: Maybe<string> | undefined
    agentBranchName: Maybe<string> | undefined
    priority: TransactionPriorityType
    useSupportedMethods?: boolean
    accountNoType?: string
    walletId?: string
    pan?: string
    cardLastFour?: string
    phoneNumber?: string
    registrationNumber?: string
    registrationDate?: string
    birthday?: string
    idExpiryDate?: string
    idIssueCountry?: string
    idIssueDate?: string
    idNumber?: string
    idType?: string
    nationality?: string
    cardNo?: string
  }
  accData?: GetAccountQuery | null
  recipient?: ContractPaymentRecipient | null
}

export type TransferFormTypes = {
  accData?: Account | null
  accToData?: Account | null
  transData?: DebitTransaction | null
}

export enum PaymentResult {
  submitted = 'submitted',
  pending = 'pending',
  failed = 'failed',
}

export enum TransferResult {
  submitted = 'submitted',
  failed = 'failed',
}

export enum AccountRequisites {
  iban = 'iban',
  swift = 'swift',
  address = 'address',
}

export interface AlertDataType {
  title?: string | undefined
  text: string
  type: 'warning' | 'primary' | 'success' | 'danger' | undefined
  icon: React.ReactElement<HTMLElement, string>
  textBold?: string
}

export interface BatchPaymentInfo {
  title?: string
  text?: string
  calculatedTotal?: Maybe<number> | undefined
  count?: number | undefined
  currency?: Maybe<string> | undefined
}

export type CardholderType = {
  key: Maybe<number>
  label: string
  isPhoneVerified: boolean
  videoVerified: boolean
  videoVerificationStatus: string
  limitedAccessRight: string
  status: string
}

export type LimitType = {
  id: number
  type: string
  Daily: number
  // Weekly: number
  Monthly: number
}

export type ChangesType = {
  fieldName: string
  oldValue: unknown
  newValue: unknown
}

export enum LimPeriod {
  Transaction = 'transaction',
  Daily = 'daily',
  // Weekly = 'weekly',
  Monthly = 'monthly',
}

export type LimNameType = 'Internet' | 'Contactless' | 'Withdrawals'
export type LimPeriodType = 'daily' | 'monthly'
//export type LimPeriodType = 'transaction' | 'daily' | 'weekly' | 'monthly'

export type TimeLimits = {
  [key in LimPeriodType]: number
}

export type LimitsType = {
  [key in LimNameType]: TimeLimits
}

export type TransactionsMethodsList = {
  key: string | number
  label: Maybe<string> | undefined
  accountId?: Maybe<string | number> | undefined
}

export type ListOption = {
  key: string
  label: string
  value: string
}

export type BeneficiaryOption = Omit<ListOption, 'value'>

export enum AccountFilterOptions {
  allMethods = 'allMethods',
  bankTransfer = 'bankTransfer',
  cryptoTransfer = 'cryptoTransfer',
  noActiveCards = 'noCards',
  card = 'card',
}

export type LimitsChanged = {
  cardSpendingLimitTypes: CardSpendingLimitTypes | undefined
  limitUsageAmount: number
  limitAmount: number
}

export type SpendingLimitProps = ActionSignatureProps & {
  spendingLimitData?: SpendingLimitRequestAction
}

export type ShortDateFormat = {
  weekday?: 'long' | 'short' | 'narrow' | undefined
  year: 'numeric' | '2-digit' | undefined
  month: 'long' | 'short' | 'narrow' | 'numeric' | '2-digit' | undefined
  day: 'numeric' | '2-digit' | undefined
}

export type OwnerFormPropsType = {
  isDomiciliary: boolean
  isNewRecord: boolean
  companyName?: Maybe<string>
  applicationId: string
  uboId?: string
  ownedUboId?: string
  type: UboStakeType
}

export type OwnerTypeFormPropsType = {
  ownersList: AuthPersonsSelectType[]
  isDomiciliary: boolean
  isNewRecord: boolean
  applicationId: string
  uboId?: string
  ownedUboId?: string
  type: UboStakeType
  formMode: string
  contractData: GetContractDetailsQuery | undefined
}

export type DefaultFormValues = {
  birthday?: string
  city: string
  country?: string
  firstName: string
  lastName: string
  nationality?: string
  postalCode: string
  streetAddress: string
}

export type ControllingPersonDefaultValues = DefaultFormValues & {
  additionalDetailsOptional: string
  authorizedPerson?: number
  controllingPerson?: string
  documentsAlreadyUploaded?: boolean
  documentType?: string
  filesForUpload: FilesForSavingAndShowType[]
  ownershipPercent: string
  politicallyExposedPerson?: string
  taxId: string
  usReportablePerson?: string
}

export enum CardOperationTypes {
  terminate = 'terminate',
  activate = 'activate',
  passwordChange = 'passwordChange',
}

export enum ApiOperationTypes {
  createApiUser = 'createApi',
  deactivateApiUser = 'deactivateApi',
  resetSecretApiUser = 'resetApiSecret',
  updateApiUserIp = 'updateApiIp',
}

export type ContractChangeProps = ActionSignatureProps & {
  contractChangeData?: ContractChangeAction
}

export enum AddressTypeOptions {
  address = 'address',
  correspondenceAddress = 'correspondenceAddress',
}

export type ContractChanges = {
  addressType: AddressTypeOptions | undefined
  changedProperty: string
  oldValue: string | number | boolean | unknown[] | Record<string, unknown>
  newValue: string | number | boolean | unknown[] | Record<string, unknown>
}

export interface KeyAndLabelSelectType {
  key?: string | number
  label: string
}

export enum CPAlerts {
  NoOneCP = 'noOneControllingPerson',
  NoOneCPOfEntity = 'noOneControllingPersonOfEntity',
  OperatingCompanyPercents = 'operatingCompanyPercentsAdded',
  DomiciliaryNo100 = 'domiciliaryCompanyNo100',
  Already100 = 'alreadyAdded100',
  Already75 = 'alreadyMoreThan75',
}

export enum OptionsList {
  sendPayment = 'sendPayment',
  internalTransfer = 'internalTransfer',
  transactions = 'transactions',
  allTransactions = 'allTransactions',
  viewVBANs = 'viewVBANs',
  receivePayment = 'receivePayment',
  directDebit = 'directDebit',
  standingOrders = 'standingOrders',
  changeAltAccount = 'changeAltAccount',
  pendingTransactions = 'pendingTransactions',
  depositGuaranteedInfo = 'depositGuaranteedInfo',
  rename = 'rename',
  topupWithCard = 'topupWithCard',
  ibanCertification = 'ibanCertification',
  goToAccountPage = 'goToAccountPage',
  buySellCrypto = 'buySellCrypto',
  viewWallet = 'viewWallet',
  disconnect = 'disconnect',
}

export enum BeneficiaryOptionList {
  editDetails = 'editDetails',
  deleteBeneficiary = 'deleteBeneficiary',
}

export interface OptionsType {
  key: OptionsList
  isDisabled?: boolean
  disabledTooltipText?: string | null
}

export type DocUploadingType = {
  key: string
  label: string
  frontSideLabel: string
  frontSideType: string
  backSideLabel: string
  backSideType: string
}

export const enum BatchStatus {
  Unrecognized = 'unrecognized',
  Valid = 'valid',
  Invalid = 'invalid',
}

export interface Beneficiaries {
  id: number
  name: string
  amount: string
  address: string
  iban: string
  swift: number
}

export enum HandlerType {
  chooseDocTypeHandler = 'chooseDocTypeHandler',
  chooseRelatesToHandler = 'chooseRelatesToHandler',
  chooseFileDescriptionToHandler = 'chooseFileDescriptionToHandler',
}

export interface IMetaWithFileUrl extends IMeta {
  fileUrl: string
}

export interface IFileWithWidenedMeta extends IFileWithMeta {
  file: File
  meta: IMetaWithFileUrl
  cancel: () => void
  restart: () => void
  remove: () => void
  xhr?: XMLHttpRequest
}

export type VbanType = {
  vban: string
  balance: number
  name: string
  address: string
  dateOfBirth: string
  nationality?: string
}

export type VbanTransactionType = {
  vban: string
  trxId: string
  direction: string
  amount: number
  balance: number
  fromTo: string
  added: string
  statusActions: string
}

export enum BeneficialCellType {
  amount = 'amount',
  name = 'name',
  ibanAccountNumber = 'ibanAccountNumber',
  swiftBic = 'swiftBic',
  processing = 'processing',
  reference = 'reference',
  payoutAmount = 'payoutAmount',
  usingLocalAch = 'usingLocalAch',
  fixedPayoutAmount = 'fixedPayoutAmount',
  bankClearingMemberId = 'bankClearingMemberId',
  bankBranchId = 'bankBranchId',
  bankBranchName = 'bankBranchName',
  purposeCode = 'purposeCode',
  beneficiaryPhone = 'beneficiaryPhone',
  beneficiaryEmail = 'beneficiaryEmail',
  chargeBearer = 'chargeBearer',
}

export enum ContractProfileChangesType {
  LegalName = 'LegalName',
  GeneralInfoNoConfirmation = 'GeneralInfoNoConfirmation',
  GeneralQuestions = 'GeneralQuestions',
  TaxId = 'TaxId',
  Address = 'Address',
  CorrespondenceAddress = 'CorrespondenceAddress',
  ContactInformation = 'ContactInformation',
  EconomicProfile = 'EconomicProfile',
  CountriesOfOperatingAndConductingBusiness = 'CountriesOfOperatingAndConductingBusiness',
}

export enum ContractManagementTablesEnum {
  Team = 'team',
  ReceivedDocs = 'receivedDocs',
  UploadedDocs = 'uploadedDocs',
  BusinessDetails = 'businessDetails',
  Directors = 'directors',
  TerminateContract = 'terminateContract',
  PersonalProfile = 'personalProfile',
}

export enum FacilitationTabsEnum {
  overview,
  transactions,
}

export enum ActionsFilterTypesEnum {
  Document = 'document',
  ContractChange = 'contractChange',
  Transfer = 'transfer',
  BatchPayment = 'batchPayment',
  NewAuthorizedPerson = 'newAuthorizedPerson',
  DeactivatePerson = 'deactivatePerson',
  AccountChange = 'accountChange',
  DirectDebit = 'directDebit',
  PaymentFacilitation = 'paymentRefundRequest',
}

export interface TrxPageStateType {
  searchValue: string | null
  direction: string
  cardsFilter: string
  status: string
  notStatus: string
  period: [Date | null, Date | null]
  cardId: string | number | undefined
  sortBy: TransactionOrderBy
  sortDirection: OrderDirection
  page: number
}

export interface StandingOrdersPageStateType {
  sortBy: StandingOrdersOrderBy
  direction: OrderDirection
  beneficiary: string | StatusSelectorType.AllBeneficiary
  page: number
}

export interface DocumentPageStateType {
  searchValue: string | null
  documentType: string
  period: [Date | null, Date | null]
  direction: OrderDirection
  sortBy: DocumentOrderBy
  page: number
}

export interface TeamPageStateType {
  searchValue: string
  role: AuthorizedPersonRights | StatusSelectorType.AllRoles
  status: string
  direction: OrderDirection
  sortBy: ContractAuthPersonOrderBy
  page: number
}

export interface ActionsPageStateType {
  searchValue: string
  type: ActionsFilterTypesEnum | StatusSelectorType.AllTypes
  status: ActionStatus | StatusSelectorType.AllStatuses
  sortBy: ActionsOrderBy
  direction: OrderDirection
  page: number
}

export interface AccountsPageStateType {
  searchValue: string
  currency: string
  status: AccountStatus | StatusSelectorType.AllStatuses
  page: number
}

export interface BeneficiaryPageStateType {
  searchValue: string
  sortBy: ContractPaymentRecipientOrderByType
  direction: OrderDirection
  page: number
}

export interface BeneficiaryOverviewPageStateType {
  sortBy: TransactionOrderBy
  direction: OrderDirection
  page: number
}

export type CardsPageStateType = {
  searchValue: string
  sortBy: CardsOrderBy
  direction: OrderDirection
  page: number
}

export enum VbansTabsEnum {
  individual,
  company,
}

export interface VbansPageStateType {
  searchValue: string
  page: number
  currentTab: VbansTabsEnum
}

export interface DirectDebitPageStateType {
  sortBy: OrderBy
  direction: OrderDirection
  status: DirectDebitMandateStatus | StatusSelectorType.AllStatuses
  page: number
}

export interface DebitExecutionsStateType {
  sortBy: OrderBy
  direction: OrderDirection
  status: DirectDebitExecutionStatus | StatusSelectorType.AllStatuses
  period: [Date | null, Date | null]
  page: number
}

export enum FilterActions {
  Search = 'setSearch',
  Direction = 'setDirection',
  Period = 'setPeriod',
  Status = 'setStatus',
  CardFilter = 'setCardFilter',
  CardId = 'setCardId',
  Type = 'setType',
  Role = 'setRole',
  SortBy = 'setSortBy',
  SortDirection = 'setSortDirection',
}

export enum DocumentFilterActions {
  Search = 'setSearch',
  DocumentType = 'setDocumentType',
  Period = 'setPeriod',
}

export enum TeamFilterActions {
  Search = 'setSearch',
  Role = 'setRole',
  Status = 'setStatus',
  Direction = 'setDirection',
}

export enum BatchTrxSignActions {
  CheckAll = 'setCheckAll',
  SetBatchSign = 'setBatchSign',
  SetAllBatchSign = 'setAllBatchSign',
  RemoveBatchSign = 'removeBatchSign',
}

export interface ActionReducerType {
  type: FilterActions | DocumentFilterActions | TeamFilterActions | 'reset'
  payload: string | [Date | null, Date | null] | Date | null
}

export type ExpectedVolumes = {
  currency: NewAccountCurrency | ''
  expectedVolume: string
}

export interface BatchSignTypes {
  checkedAll: boolean
  batchTrxSign: (string | number | undefined)[]
}

export interface BatchTrxReducerType {
  type: BatchTrxSignActions
  payload: string | number | boolean | undefined | (string | number | undefined)[]
}

export enum StepsAuth {
  SMSOrCallToPhone = 'SMSOrCallToPhone',
  StartAuthenticatorApp = 'StartAuthenticatorApp',
  TypeVerificationCode = 'TypeVerificationCode',
  AuthenticatorApp = 'AuthenticatorApp',
  LoadingState = 'LoadingState',
}

export enum StatusSelectorType {
  AllStatuses = 'allStatuses',
  AllBeneficiary = 'allBeneficiary',
  Received = 'received',
  Sent = 'sent',
  Processing = 'processing',
  Pending = 'pending',
  Reverted = 'reverted',
  Failed = 'failed',
  Declined = 'declined',
  Canceled = 'canceled',
  AllRoles = 'allRoles',
  AllTypes = 'allTypes',
  AllMethods = 'allMethods',
  Completed = 'completed',
  Scheduled = 'scheduled',
}

export type locationStateType = {
  cardId?: string
  from?: string
  missingInfoId?: string
  guaranteedActiveTabInd?: TransactionsTabs
  selectedAccount?: string
  accIdFromAccountPage?: string
  isFromHomePage?: boolean
}

export type KeyLabelType = {
  key: string | number
  label: Maybe<string> | undefined
}

export enum DocumentUploadRelated {
  companyRelated = 'companyRelated',
  personRelated = 'personRelated',
}

export enum ApplicationTypeTab {
  business = 'business',
  personal = 'personal',
}

export enum VerificationSteps {
  other = 'other',
  email = 'email',
  phone = 'phone',
}

export type EditableIp = {
  id: string
  ip: string
  checked: boolean
}

export type TableSortType = {
  isActionsSorted: boolean
  isTransactionsSorted: boolean
  isClientsSorted: boolean
}

export enum TableSortPagesEnum {
  actions = 'actions',
  transactions = 'transactions',
  clients = 'clients',
  contract = 'contract-management',
  cards = 'cards',
  payments = 'payment-facilitator',
  standingOrders = 'standing-orders',
}

export type EditIpContextType = {
  isIpEditing: boolean
  setIsIpEditing: React.Dispatch<React.SetStateAction<boolean>>
  editModalOpenName: string | null
  setEditModalOpenName: React.Dispatch<React.SetStateAction<string | null>>
  editedIps: EditableIp[]
  setEditedIps: React.Dispatch<React.SetStateAction<EditableIp[]>>
  unsavedIp: string
  setUnsavedIp: React.Dispatch<React.SetStateAction<string>>
}

export enum documentsAccordionsType {
  pinnedDoc = 'pinnedDoc',
  everythingElse = 'everythingElse',
}

export type Partner = {
  name: string
  country: string
  bankCountry: string
  businessSector: string
  paymentPurpose: string[]
  website: string
}

export type historyPaymentType = {
  account?: string | null | number
  amount?: number | string
  payoutAmount?: number | string
  clientReference?: string | null
  reference?: string | null
  recipient: ContractPaymentRecipient
  commentTemplate?: string
  chargeBearer?: string | null
}

export type CurrenciesData = {
  key: string | undefined
  label: string
  rails?: Array<string | null>
  iconImage: string | undefined
}

export enum CardPaymentsSelectOptions {
  notRequired = 'notRequired',
  required = 'required',
}

export type dateRangeType = Date | [Date | null, Date | null] | null

export enum PersonStatus {
  new = 'new',
  existing = 'existing',
  myself = 'myself',
}

export enum CardsTypesVolumes {
  Domestic = 'domestic',
  Eea = 'eea',
  NonEea = 'nonEea',
}

export interface SuccessFeesPageStateType {
  sortBy: SuccessFeeOrderBy
  sortDirection: OrderDirection
  page: number
}

export enum OperationNames {
  sign = 'sign',
  reject = 'reject',
}

export enum PersonAccessRightsEnum {
  full = 'full',
  limited = 'limited',
}

export type TooltipPartnersDataType = {
  name: ReactNode
  country: ReactNode
  bankCountry: ReactNode
  businessSector: ReactNode
}

export enum PartnersInformationEnum {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export type TooltipTitleTextClassesType = {
  divider?: string
  listItem?: string
  tooltipKey?: string
}

export interface GuaranteeEventPageStateType {
  sortBy: OrderBy
  direction: OrderDirection
  page: number
}

export enum ChangeValuesType {
  old = 'oldValue',
  new = 'newValue',
}

export enum TransactionsTabs {
  all,
  pending,
  guaranteeReCalc,
}

export enum SettingsTabsEnum {
  changePassword,
  emailPreferences,
  twoFactor,
}

export interface PaymentsPageStateType {
  searchValue: string
  statuses: PaymentStatus[]
  methods: PaymentMethod[]
  period: [Date | null, Date | null]
  sortBy: PaymentsOrderBy
  direction: OrderDirection
  page: number
}

export enum BusinessRegionsEnumErrorTypes {
  businessRegions0 = 'businessRegions0',
  businessRegions100 = 'businessRegions100',
  valueIsNotEmptyRegion = 'valueIsNotEmptyRegion',
  valueIsNotEmptyPercentage = 'valueIsNotEmptyPercentage',
  onlyPositiveIsAllowed = 'onlyPositiveIsAllowed',
}

export enum ExpectedVolumesErrorTypes {
  expectedVolumes100 = 'expectedVolumes100',
  expectedVolumes0 = 'expectedVolumes0',
  volumeIsNotEmptyCurrency = 'volumeIsNotEmptyCurrency',
  volumeIsNotEmptyExpectedVolume = 'volumeIsNotEmptyExpectedVolume',
  onlyPositiveIsAllowed = 'onlyPositiveIsAllowed',
}

export enum ControllingPersonsTypeEnum {
  personOwner = 'personOwner',
  legalOwner = 'legalOwner',
  attorneyPower = 'attorneyPower',
  managingDirector = 'managingDirector',
}

export enum contactsPrimaryStatus {
  primaryOnly = 'primaryOnly',
  notPrimary = 'notPrimary',
}

export interface ContactsFilterTypes {
  searchValue: string
  isPrimaryStatus: contactsPrimaryStatus | StatusSelectorType.AllStatuses
  page: number
}

export enum NoYes {
  no = 'No',
  yes = 'Yes',
}

export type TransactionsActionsAccountPageProps = {
  selectedTrxCount: number
  handleSignChecked: (action: OperationNames) => void
  disabled: boolean
  transactionsData?: GetTransactionsQuery
}

export type ContactTableListRowProps = {
  contact: ContractContact
  showDeleteContactModal: React.Dispatch<React.SetStateAction<boolean>>
  setContactIdToDelete: React.Dispatch<React.SetStateAction<string | number | null>>
}

export type ContactMenuOptions = {
  key: string
  label: string
}

export enum CreateOptionEnum {
  payment = 'payment',
  internal = 'internal',
  batch = 'batch',
  topUp = 'topUp',
  standingOrder = 'standingOrder',
  account = 'account',
  card = 'card',
  beneficiary = 'beneficiary',
  cryptoTransfer = 'cryptoTransfer',
}

export type CreateOption = {
  key: CreateOptionEnum
  translation: string
  disabled: boolean
  icon: React.ReactElement<HTMLElement, string>
}

export enum SearchName {
  SearchTrx = 'searchTrx',
  SearchAccounts = 'searchAccounts',
  SearchAccountDetails = 'searchAccountDetails',
  SearchCards = 'searchCards',
  SearchActions = 'searchActions',
  SearchClients = 'searchClients',
  SearchPayments = 'searchPayments',
  SearchReceivedDocuments = 'SearchReceivedDocuments',
  SearchUploadDocuments = 'SearchUploadDocuments',
  SearchContacts = 'searchContacts',
  SearchTeam = 'searchTeam',
  SearchVbans = 'searchVbans',
  SearchMismatches = 'searchMismatches',
}

export type AddNewPersonDefaultValues = DefaultFormValues & {
  additionalDetailsOptional: string
  email: string
  groupSignature: string
  phoneNumber: string
  signatoryRight: string
  ownerPerson?: number
}

export type WebsiteField = { value: string }

export type TeamMenuOption = {
  label: string
  handler: (teamId?: number) => void
}

export type TeamMenuOptions = Partial<
  {
    [key in AuthorizedPersonStatuses]: TeamMenuOption
  }
>

export type TeamTableRowProps = {
  isViewOnly: boolean
  isShouldDisabled: boolean
  teammate: ContractAuthorizedPersonType
  isMyself: boolean
  teamMenuOptions: TeamMenuOptions
}

export interface StepType {
  label: string
  pendingLabel: string
  description?: string
  isCurrentStep: (status: TransactionRequestStatus) => boolean
}

export enum DirectDebLimitsEnum {
  single = 'single',
  monthly = 'monthly',
}

export interface WalletTrxPageStateType {
  status: string
  sortBy: OrderBy
  sortDirection: OrderDirection
  page: number
}

export interface WalletInboundTrxPageStateType {
  sortBy: TransactionOrderBy
  sortDirection: OrderDirection
  page: number
}
