import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'

import BusinessIcon from '../../assets/images/icons/business.svg?react'
import { AppActions, statusActions } from '../../constants'
import {
  ActionSignatureStatus,
  BankableEntityContractType,
  ContractStatusType,
  ContractType,
  Individual,
  LegalEntity,
  UboLegalEntityType,
  UboType,
  useActionSignatureRequestsCountLazyQuery,
  useGetUserContractsQuery,
} from '../../graphql'
import { goBackPathVar, isUnsaveFormData } from '../../graphql/local'
import {
  CurrentUserContext,
  useCustomHistory,
  useDetermineUserRights,
  useLimitedPerson,
  usePageFiltersSorting,
  useTotalUnreadDocCount,
} from '../../hooks'
import { CmTabName } from '../../pages/ContractManagementPage'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import {
  getDetailsContractsByIndividual,
  isActiveContract,
  isLimitedActiveContract,
} from '../../utils'

import Loader from '../Common/Loader'
import { ContractAlertDot } from '../Common/Selectors/ContractAlertDot'
import { ContractsListItem } from './ContractsListItem'
import { useMenuStyles } from './menuStyles'
import { SubAccordionMenu } from './SubAccordionMenu'

export const useCompanySelectUnionStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1, 1, 6.625),
    '& .flag-icon-lg': {
      fontSize: '1.5em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1, 1, 6.75),
    },
  },
  companyName: {
    position: 'relative',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    lineHeight: '2rem',
    paddingRight: theme.spacing(1.65),
  },
  contractsSelectWrap: {
    width: '100%',
    boxShadow: 'none',
    paddingTop: theme.spacing(1),
    '&.Mui-expanded': {
      backgroundColor: '#F9F9F9',
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
      '&.Mui-expanded': {
        transform: 'rotate(-180deg)',
      },
    },
    '& .MuiIconButton-label .MuiSvgIcon-root': {
      color: '#ccc',
    },
  },
  accordionHeader: {
    '&.Mui-expanded': {
      minHeight: theme.spacing(6),
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
      '& svg': {
        paddingLeft: theme.spacing(0.25),
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  accordionBody: {
    flexDirection: 'column',
    padding: theme.spacing(0, 0, 1),
    '& .MuiList-root': {
      padding: 0,
      width: '100%',
    },
  },
  activeContractItem: {
    position: 'relative',
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: theme.spacing(0.5),
      backgroundColor: '#EF2828',
    },
  },
  noPadding: {
    padding: 0,
  },
}))

const useStyles = makeStyles((theme) => ({
  nameOwner: {
    position: 'relative',
    textAlign: 'left',
    paddingLeft: theme.spacing(1.5),
    fontSize: 16,
    lineHeight: 1.5,
    width: '90%',
    '& .MuiFormHelperText-root': {
      bottom: 0,
      margin: 0,
      position: 'relative',
      paddingLeft: 0,
      color: '#999999',
    },
    '& > span': {
      right: theme.spacing(-1.5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1.875),
    },
  },
  itemNewApp: {
    textDecoration: 'underline',
  },
  iconActivateAccount: {
    opacity: '.2',
    minWidth: theme.spacing(1),
  },
  wordBrakeText: {
    margin: 0,
    wordBreak: 'break-word',
  },
  divider: {
    height: 1,
    backgroundColor: '#E5E7EB',
    margin: theme.spacing(1.25, 0),
  },
}))

export const CompanySelect: FC<{
  disabled?: boolean
  contractId?: number | string
}> = ({ contractId, disabled }) => {
  const classes = { ...useMenuStyles(), ...useCompanySelectUnionStyles(), ...useStyles() }
  const { t } = useTranslation()
  const history = useHistory()

  const { data: contractsData, loading } = useGetUserContractsQuery()
  const { resetHistory } = useCustomHistory()

  const [getActionSignatureRequestsCount] = useActionSignatureRequestsCountLazyQuery()
  const [owner, setOwner] = useState<string>('')
  const [expanded, setExpanded] = useState<string | false>(false)

  const { resetAll } = usePageFiltersSorting()
  const { docsCount, refetchDocsCount } = useTotalUnreadDocCount()

  const user = useContext(CurrentUserContext)
  const { userRights } = useDetermineUserRights(contractId as string | number)
  const { contractsAp, contractsApplicant, individualContract } = useMemo(
    () =>
      getDetailsContractsByIndividual(
        contractsData?.contracts as BankableEntityContractType[],
        user as Individual,
      ),
    [contractsData, user],
  )
  const contractsList = [
    ...(individualContract ? [individualContract] : []),
    ...contractsAp,
    ...contractsApplicant,
  ]

  const currentContract = useMemo(
    () => contractsList.find((item) => Number(item?.id) === Number(contractId)),
    [contractsList, contractId],
  )
  const { isLimitedPerson, isStrictLimitedPerson } = useLimitedPerson(currentContract)

  const allowContractDataView =
    (user?.isPhoneVerified &&
      ([
        ContractStatusType.Suspended,
        ContractStatusType.Terminated,
        ContractStatusType.Monitoring,
        ContractStatusType.TerminationPending,
      ].includes(currentContract?.status as ContractStatusType) ||
        (currentContract?.status === ContractStatusType.Active &&
          [individualContract, ...contractsAp].some(
            (contract) => Number(contract?.id) === Number(contractId),
          ))) &&
      !isStrictLimitedPerson) ||
    userRights?.isIntroducerContractOwner

  const { resetSearchAndPageValues } = usePageFiltersSorting()

  const isPersonalContract = userRights?.isIndividualContractOwner

  const isUserContractAP = useMemo(
    () => !!currentContract?.authorizedPersons?.find((ap) => ap?.person?.id === user?.id),
    [user, currentContract],
  )

  const isHiddenDots = useMemo(() => {
    if (!isUserContractAP) return true
    return !user?.isVerified || isLimitedPerson || !docsCount
  }, [isUserContractAP, isLimitedPerson, docsCount, user])

  const onView = useCallback(
    (contract: BankableEntityContractType) => {
      history.push(
        generatePath(APP_PATHS.application.status, {
          [PATH_PARAMS.applicationId]: contract.id,
        }),
      )
    },
    [history],
  )

  const handleExpand = useCallback(
    (panel: string) => (event: React.ChangeEvent<unknown>) => {
      event.stopPropagation()

      if (!!expanded) {
        setExpanded(false)
        return
      }
      setExpanded(panel)
    },
    [setExpanded, expanded],
  )

  const onEdit = useCallback(
    (contract: BankableEntityContractType) => {
      let redirectPath = APP_PATHS.application.review
      if (contract.status === ContractStatusType.Draft) {
        if (
          (contract.owner as LegalEntity).legalEntityType === UboLegalEntityType.Unknown &&
          contract.type !== ContractType.IntroducerReference
        ) {
          redirectPath = APP_PATHS.application.edit
        } else if (
          isEmpty(contract.authorizedPersons) &&
          contract.type !== ContractType.IntroducerReference
        ) {
          redirectPath = APP_PATHS.application.authorizedPersons.add
        } else if (isEmpty((contract.owner as LegalEntity).legalEntityDirectors)) {
          redirectPath = APP_PATHS.application.directors.list
        } else if (isEmpty(contract.ubos)) {
          redirectPath = APP_PATHS.application.beneficialOwners.list
        } else if (isEmpty(contract.contacts)) {
          redirectPath = APP_PATHS.application.contacts.list
        }
      } else if (contract.status === ContractStatusType.PreAssessmentDraft) {
        redirectPath = APP_PATHS.application.edit
      }
      history.push(generatePath(redirectPath, { [PATH_PARAMS.applicationId]: contract.id }))
    },
    [history],
  )

  const actions: { [key in AppActions]: (contract: BankableEntityContractType) => void } = {
    [AppActions.View]: onView,
    [AppActions.Edit]: onEdit,
    [AppActions.Delete]: () => true,
  }

  const handleChange = useCallback(
    async (contract: BankableEntityContractType) => {
      setExpanded(false)
      goBackPathVar('')
      resetHistory()
      isUnsaveFormData(false)
      const specialContractStatuses = [
        ContractStatusType.FirstLinePending,
        ContractStatusType.AmloPending,
        ContractStatusType.SignaturesPending,
      ]

      if (
        (contract.owner as LegalEntity).legalEntityType === UboLegalEntityType.Unknown &&
        contract.type !== ContractType.IntroducerReference
      ) {
        history.push(
          generatePath(APP_PATHS.application.edit, { [PATH_PARAMS.applicationId]: contract?.id }),
        )
      } else {
        const { data } = await getActionSignatureRequestsCount({
          variables: { contractId: contract.id, statuses: [ActionSignatureStatus.Pending] },
          fetchPolicy: 'network-only',
        })

        const pathToRedirect = !!data?.actionSignatureRequestsCount
          ? APP_PATHS.dashboard.actions
          : contract.type === ContractType.IntroducerReference
          ? APP_PATHS.dashboard.partnerManagementOverview
          : APP_PATHS.dashboard.home

        if (
          (isActiveContract(contract, user as Individual) ||
            isLimitedActiveContract(contract, user as Individual)) &&
          contract.status &&
          !specialContractStatuses.includes(contract.status)
        ) {
          resetSearchAndPageValues()
          history.push(generatePath(pathToRedirect, { [PATH_PARAMS.applicationId]: contract?.id }))
        } else {
          actions[statusActions[contract.status as ContractStatusType][0]](
            contract as BankableEntityContractType,
          )
        }
      }
      localStorage.clear()
      resetAll()
    },
    [history, resetSearchAndPageValues, getActionSignatureRequestsCount],
  )

  const getContractTabUrl = (tab: CmTabName) => {
    return generatePath(APP_PATHS.dashboard.contractManagement.root, {
      [PATH_PARAMS.applicationId]: contractId,
      [PATH_PARAMS.contractManagementTab]: tab,
    })
  }

  const navigateContractManagementTab = (tab: CmTabName) => {
    history.push(getContractTabUrl(tab))
  }

  const addNewApp = useCallback(() => {
    history.push(APP_PATHS.root, { from: 'dashboard' })
  }, [history])

  useEffect(() => {
    if (!isEmpty(contractsData?.contracts)) {
      const nameOwner = (contractsData?.contracts as BankableEntityContractType[]).find(
        (contract) => {
          // eslint-disable-next-line eqeqeq
          return contractId == contract.id
        },
      )
      setOwner(
        nameOwner?.ownerType === UboType.NaturalPerson
          ? t('personal')
          : (nameOwner?.owner as LegalEntity)?.companyName ?? '',
      )
    }
    return () => {
      setOwner('')
    }
  }, [contractId, contractsData])

  useEffect(() => {
    refetchDocsCount().then()
  }, [contractId])

  const handleClose = () => {
    if (!history.location.pathname.includes('contract-management')) {
      setExpanded(false)
    }
    return
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Accordion
        square
        className={classes.contractsSelectWrap}
        expanded={!!expanded}
        onChange={handleExpand('contract-select')}
        data-test="autotest-contractSelect"
      >
        <AccordionSummary
          expandIcon={!loading ? <ExpandMoreIcon /> : null}
          aria-controls="contract-select"
          id="contract-select"
          IconButtonProps={{ disableRipple: true }}
          className={classes.accordionHeader}
          disabled={disabled}
          data-test="contractSelector"
        >
          {loading ? (
            <Loader size={15} />
          ) : (
            <>
              <BusinessIcon />
              <Typography component="div" className={classes.nameOwner} data-test="owner">
                <ContractAlertDot size={12} hidden={isHiddenDots} />
                <Box component={'p'} className={classes.wordBrakeText}>
                  {owner}
                </Box>
              </Typography>
            </>
          )}
        </AccordionSummary>

        <AccordionDetails className={classes.accordionBody}>
          <List id="menu-list-grow">
            {!isEmpty(contractsList) &&
              contractsList.map((contract) => (
                <ContractsListItem
                  key={contract.id}
                  contract={contract}
                  isSelected={Number(contractId) === contract.id}
                  handleChangeContract={handleChange}
                />
              ))}

            {!(user?.isIntroducer && (contractsData?.contracts?.length ?? 0) > 0) && (
              <ListItem
                button
                onClick={addNewApp}
                className={clsx(classes.item, classes.itemNewApp)}
                data-test="autotest-addNewApplication"
              >
                <ListItemText data-test="addNewApp">
                  {t('addNewApplication', 'Add new application')}
                </ListItemText>
              </ListItem>
            )}
          </List>

          <Box>
            <Divider className={classes.divider} orientation="horizontal" flexItem />

            {!userRights?.isIntroducerContractOwner && allowContractDataView && (
              <List>
                <ListItem
                  button
                  className={clsx(
                    classes.item,
                    history.location.pathname ===
                      getContractTabUrl(
                        isPersonalContract ? CmTabName.PersonalProfile : CmTabName.BusinesDetails,
                      ) && classes.activeContractItem,
                  )}
                  onClick={() =>
                    navigateContractManagementTab(
                      isPersonalContract ? CmTabName.PersonalProfile : CmTabName.BusinesDetails,
                    )
                  }
                >
                  <Typography className={classes.companyName}>
                    {isPersonalContract
                      ? t('personalProfile', 'Personal profile')
                      : t('businessDetails', 'Business details')}
                  </Typography>
                </ListItem>
              </List>
            )}

            <SubAccordionMenu
              contractsApplicant={contractsApplicant}
              currentContract={currentContract}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  )
}
