import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  Theme,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp, Check } from '@material-ui/icons'
import { toLower } from 'lodash'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { NewAccountCurrency, useSaveBalanceCurrencyMutation } from '../../graphql'
import { PATH_PARAMS } from '../../routes/paths'
import { currenciesLabelsArray, currencyLabels } from '../../utils'
import { FlagIcon } from './FlagIcon'

export const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    position: 'absolute',
    minWidth: theme.spacing(37.5),
    left: '-25px !important',
    top: theme.spacing(20),
    zIndex: 9999,
  },
  paper: {
    margin: theme.spacing(1.5, 1.5, 0, 0),
    maxHeight: 400,
    maxWidth: '100%',
    background: '#FFFFFF',
    borderRadius: 0,
    boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
    overflow: 'auto',
  },
  currencySelector: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    position: 'static',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: '#FFFFFF',
    marginLeft: theme.spacing(1.25),
    width: 100,
  },
  currencyButton: {
    flex: '0 0',
    cursor: 'pointer',
    minWidth: 81,
    minHeight: 32,
    background: 'none',
    border: '1px solid #F0F0F0',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(0, 0.5, 0, 1.5),
    position: 'relative',
    fontSize: '16px',
    '&.open': {
      border: '1px solid #EF2828',
      fontSize: '20px',
      minWidth: 94,
      minHeight: 36,
      '&:hover': {
        border: '1px solid #EF2828',
        backgroundColor: 'transparent',
        color: '#000000',
        fontSize: '20px',
        opacity: 1,
        minWidth: 94,
        minHeight: 36,
        '& .MuiSvgIcon-root': {
          color: '#262626',
        },
      },
    },
    '&:hover': {
      backgroundColor: '#F5F5F5',
      color: '#434343',
      border: '1px solid #D9D9D9',
      fontSize: '20px',
      minWidth: 94,
      minHeight: 36,
      '& .MuiSvgIcon-root': {
        color: '#CCCCCC',
      },
    },
    '& .flag-icon-lg': {
      fontSize: '1em',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  upperItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '& .MuiTypography-displayBlock': {
      marginBottom: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: '0.5em',
      marginRight: theme.spacing(2),
    },
    '& .flag-icon-lg': {
      fontSize: '0.8em',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    position: 'static',
    width: '100%',
    height: theme.spacing(6),
    left: 0,
    top: theme.spacing(6),
    background: '#FFFFFF',
  },
  item: {
    '& .MuiTypography-displayBlock': {
      marginBottom: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: '0.5em',
      marginRight: theme.spacing(2),
    },
    '& .flag-icon-lg': {
      fontSize: '1.5rem',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'start',
    padding: theme.spacing(1.5),
    position: 'static',
    width: '100%',
    height: theme.spacing(6),
    left: 0,
    top: theme.spacing(6),
    background: '#FFFFFF',
    marginTop: theme.spacing(0.625),
    justifyContent: 'space-between',
  },
  listItemIcon: {
    flexGrow: 0,
  },
  listItemTextKey: {
    flexGrow: 0,
    flex: '1 0 auto',
    color: '#000000',
    '& .MuiTypography-displayBlock': {
      marginBottom: 0,
    },
  },
  listItemTextLabel: {
    flexGrow: 17,
    flex: '0 0 auto',
    '& .MuiTypography-displayBlock': {
      marginBottom: 0,
      color: '#595959',
    },
  },
  listItemCheck: {
    flexGrow: 0,
    color: '#999999',
  },
  list: {
    boxShadow: '5px 5px 4px 0px rgba(34, 60, 80, 0.2)',
    backgroundColor: 'white',
    padding: 0,
  },
  arrowDropDown: {
    color: '#999999',
  },
}))

export const CurrencySelector: FC<{
  currencyKey: string | undefined
  setCurrencyKey: React.Dispatch<React.SetStateAction<string>>
  componentDisabled?: boolean
}> = ({ currencyKey, setCurrencyKey, componentDisabled = false }) => {
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [saveBalanceCurrencyMutation] = useSaveBalanceCurrencyMutation()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const changeCurrency = useCallback(
    async (key: string) => {
      await saveBalanceCurrencyMutation({
        variables: {
          contractId: applicationId,
          currency: key as NewAccountCurrency,
        },
      })
      setCurrencyKey(key)
      setOpen(false)
    },
    [setCurrencyKey, applicationId],
  )

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <Box className={classes.currencySelector}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`${classes.currencyButton} ${open ? 'open' : ''}`}
        disabled={componentDisabled}
      >
        {currencyKey}
        {open ? <ArrowDropUp /> : <ArrowDropDown className={classes.arrowDropDown} />}
      </Button>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        // role={undefined}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top' }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <List id="menu-list-grow" className={classes.list}>
                  <ListItem className={classes.upperItem}>
                    <ListItemText>{t('selectCurrency', 'Select Currency')}:</ListItemText>
                  </ListItem>
                  {currenciesLabelsArray?.map((currency) => (
                    <ListItem
                      key={currency.iconImage}
                      onClick={() => changeCurrency(currency.key)}
                      button
                      className={classes.item}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <FlagIcon code={toLower(currency.iconImage)} size={'1.2em'} />
                      </ListItemIcon>
                      <ListItemText className={classes.listItemTextKey}>
                        <Trans i18nKey={currency.key} defaults={currency.key} />
                      </ListItemText>
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      <ListItemText className={classes.listItemTextLabel}>
                        &nbsp;&bull;&nbsp;
                        {currencyLabels[currency.key]}
                      </ListItemText>
                      {currencyKey === currency.key && (
                        <Check className={classes.listItemCheck} fontSize={'small'} />
                      )}
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
