import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { AccountStatus, Currency, FundsType, useGetContractAccountsQuery } from '../../../graphql'
import { PATH_PARAMS } from '../../../routes/paths'

export const useAllowCryptoOperations = () => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data, loading } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
      currencies: [Currency.Eur],
    },
  })

  const filteredAccounts = useMemo(
    () =>
      data?.accounts?.filter((account) =>
        [FundsType.Operational, FundsType.ClientFunds].includes(account?.fundsType as FundsType),
      ),
    [data],
  )

  return loading || isEmpty(filteredAccounts)
}
