import { toNumber } from 'lodash'
import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Chip, makeStyles } from '@material-ui/core'
import { Box, Grid, Hidden, Icon, MenuItem, SvgIcon, Typography } from '@material-ui/core'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'

import { useIsLimitedContract, useWebApi } from '../../hooks'
import { APP_PATHS, PATH_PARAMS, ROOT_PATHS } from '../../routes/paths'
import { Change2FAMenuItem } from '../Common'
import { ContractStatusType, useGetViewerQuery } from '../../graphql'
import { useReactiveVar } from '@apollo/client'
import { isDirtyForm, isModalDialogOpen } from '../../graphql/local'
import { useTableSort } from '../../hooks/useCurrentTableSort'
import { getTranslateFooterName } from '../../utils'
import { useWeb3 } from '../../hooks/useWeb3'

const useStyles = makeStyles((theme) => ({
  menuFooter: {
    color: theme.palette.common.black,
    paddingLeft: '0',
    margin: theme.spacing(0, 0, 4, 0),
    '& > .MuiGrid-container': {
      '& > .MuiGrid-item': {
        padding: theme.spacing(1, 0),
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  menuFooterMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.5,
    paddingLeft: theme.spacing(2.25),
    '&:hover': {
      color: '#434343',
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        opacity: '.4',
        transition: '0.3s',
      },
    },
    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '& a': {
      width: '100%',
    },
  },
  menuItemContact: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    lineHeight: 1.5,
    transition: '0.3s',
    color: '#000000',
    '& > a:hover': {
      color: '#434343',
    },
    '&:hover': {
      color: '#434343',
      backgroundColor: 'transparent',
      transition: '0.3s',
      '& .MuiSvgIcon-root': {
        opacity: '.4',
        transition: '0.3s',
      },
    },
    '& .MuiSvgIcon-root': {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '& a': {
      width: '100%',
      height: '48px',
      paddingLeft: theme.spacing(4),
    },
  },
  copyright: {
    padding: theme.spacing(1),
  },
  inMenu: {
    padding: theme.spacing(2),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  dotContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  changeMargin: {
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      fontSize: '11px',
    },
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      '& span': {
        marginRight: '5px',
      },
    },
  },
  contactWrapper: {
    marginBottom: 15,
    '& a': {
      paddingLeft: 0,
    },
  },
  extendedPaddingLeft: {
    paddingLeft: theme.spacing(4),
  },
  fullWidth: {
    width: '100%',
  },
  footerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& img': {
      marginRight: theme.spacing(0.5),
    },
  },
  appVersion: {
    height: theme.spacing(2.25),
    marginLeft: theme.spacing(0.5),
    backgroundColor: '#F4F3F2',
    borderColor: '#0000001A',
    '& .MuiChip-label': {
      fontSize: 9,
      padding: theme.spacing(0.625, 0.5),
      color: '#474747',
      fontWeight: 700,
    },
  },
  link: {
    color: '#000',
    fontSize: 11,
    '&:hover': {
      color: '#474747',
    },
  },
}))

export const Footer: FC<{
  isMenu: boolean
  isMenuOpen?: boolean | undefined
}> = ({ isMenu = false, isMenuOpen }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { webApi } = useWebApi()
  const { disconnect } = useWeb3()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data } = useGetViewerQuery()
  const isDirtyField = useReactiveVar(isDirtyForm)

  const history = useHistory()
  const { clearTablesSort } = useTableSort()
  const { contractStatus } = useIsLimitedContract()

  const checkableContractStatuses = [
    ContractStatusType.Active,
    ContractStatusType.Suspended,
    ContractStatusType.TerminationPending,
    ContractStatusType.Monitoring,
  ]

  const contractStatusCheck = contractStatus && !checkableContractStatuses.includes(contractStatus)
  const isActiveContract = useMemo(
    () =>
      [
        ContractStatusType.Active,
        ContractStatusType.TerminationPending,
        ContractStatusType.SignaturesPending,
        ContractStatusType.Suspended,
        ContractStatusType.PreAssessment,
        ContractStatusType.FirstLinePending,
      ].includes(contractStatus as ContractStatusType),
    [contractStatus],
  )

  const showLessPadding = ['onboarding', 'status'].some((pathSegment) => {
    if (history.location.pathname.includes('onboarding/start')) return false
    return history.location.pathname.includes(pathSegment)
  })

  const showLogoutBtn = [
    ROOT_PATHS.appRoot,
    APP_PATHS.application.new,
    APP_PATHS.application.individual.new,
    generatePath(APP_PATHS.application.onboarding.personalDetails, {
      [PATH_PARAMS.applicationId]: toNumber(applicationId),
    }),
    generatePath(APP_PATHS.application.onboarding.phoneVerification, {
      [PATH_PARAMS.applicationId]: toNumber(applicationId),
    }),
    generatePath(APP_PATHS.application.onboarding.videoVerification, {
      [PATH_PARAMS.applicationId]: toNumber(applicationId),
    }),
    generatePath(APP_PATHS.application.status, {
      [PATH_PARAMS.applicationId]: toNumber(applicationId),
    }),
  ].includes(history.location.pathname)

  const onLogout = useCallback(() => {
    clearTablesSort()

    if (isDirtyField) {
      isModalDialogOpen(true)
      return
    } else {
      disconnect()
      webApi?.logout().then(() => history.push(APP_PATHS.auth.login))
    }
  }, [isDirtyField, webApi])

  const renderMenu = useMemo(() => {
    return (
      <ul className={clsx(classes.menuFooter, isMenuOpen && classes.menuFooterMargin)}>
        <Change2FAMenuItem lessPadding={showLessPadding} />
        {(contractStatusCheck || showLogoutBtn) && (
          <MenuItem
            onClick={onLogout}
            className={clsx(
              classes.menuItem,
              (contractStatus && !isActiveContract) ||
                (!applicationId && classes.extendedPaddingLeft),
            )}
            data-test="autotest-sidebarLogOutBtn"
          >
            <SvgIcon>
              <path
                fill="#000"
                d="M14 5l-1.41 1.41L14.17 8H6v2h8.17l-1.58 1.58L14 13l4-4-4-4zM2 2h7V0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h7v-2H2V2z"
              />
            </SvgIcon>
            {t('logOut', 'Log Out')}
          </MenuItem>
        )}
      </ul>
    )
  }, [isDirtyField, contractStatus])
  const footerName = getTranslateFooterName(t)

  return (
    <Box>
      {!isMenu && <Hidden smDown>{renderMenu}</Hidden>}
      {data?.viewer && isMenu && renderMenu}

      {/*for desktop left menu application*/}
      {(isMenuOpen || isMenu) && (
        <Grid
          item
          xs={12}
          container
          direction="column"
          alignContent="flex-start"
          alignItems="flex-start"
          className={clsx(
            classes.copyright,
            isMenu && classes.inMenu,
            ((contractStatus && !isActiveContract) || !showLessPadding) &&
              classes.extendedPaddingLeft,
          )}
        >
          <Grid item container className={classes.mb1}>
            <Grid item>
              <Typography variant="body2">
                <b>{footerName}</b>
              </Typography>
            </Grid>

            <Grid item className={classes.dotContainer}>
              <Box component="span" display="flex">
                <Icon style={{ fontSize: '5px' }}>fiber_manual_record</Icon>
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="body2">
                <b>{t('uidNumber', 'CHE-402.707.584')}</b>
              </Typography>
            </Grid>
          </Grid>

          <Box className={classes.fullWidth}>
            {/*<Link className={classes.link} to="/">
              {t('terms', 'Terms')}
            </Link>
            {' - '}
            <Link className={classes.link} to="/">
              {t('privacy', 'Privacy')}
            </Link>
            {' - '}*/}
            <Link
              className={classes.link}
              to={generatePath(APP_PATHS.contactSupport)}
              target="_blank"
              data-test="autotest-contactSupport"
            >
              {t('contactSupport', 'Contact support')}
            </Link>
          </Box>

          <Box className={clsx(classes.footerText, classes.fullWidth, classes.mt1)}>
            <Box className={classes.footerText}>
              <img src={'/img/Flag_of_Switzerland 1.png'} alt={'Flag_of_Switzerland'} />
              <Typography variant="body2">
                {t('madeInSwitzerland', 'Made in Switzerland')}
              </Typography>
            </Box>

            {import.meta.env.REACT_APP_VERSION && (
              <Chip
                size="small"
                variant="outlined"
                className={classes.appVersion}
                label={`V. ${import.meta.env.REACT_APP_VERSION}`}
              />
            )}
          </Box>
        </Grid>
      )}
    </Box>
  )
}
