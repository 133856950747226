import React, { FC, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'
import { BankableEntityContractType, ContractStatusType, LimitedAccessRight } from '../../graphql'
import { useCurrentUser, useDetermineUserRights } from '../../hooks'
import { DocumentsTabName } from '../../pages/ContractManagementPage/Documents/CmDocumentsTabs'
import { PeopleTabName } from '../../pages/ContractManagementPage/People/CmPeopleTabs'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { CompanySelectAccordionSubgroup } from './CompanySelectAccordionSubgroup'

export const SubAccordionMenu: FC<{
  currentContract: BankableEntityContractType | undefined
  contractsApplicant: BankableEntityContractType[]
}> = ({ currentContract, contractsApplicant }) => {
  const { t } = useTranslation()

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const user = useCurrentUser()

  const isPersonalContract = userRights?.isIndividualContractOwner
  const isLimitedAccess =
    userRights?.limitedAccessRight === LimitedAccessRight.PaymentFacilitationView ||
    userRights?.limitedAccessRight === LimitedAccessRight.CardHolder

  const isDocAccessPermitted =
    (userRights?.limitedAccessRight &&
      ![LimitedAccessRight.CardHolder, LimitedAccessRight.PaymentFacilitationView].includes(
        userRights?.limitedAccessRight,
      )) ||
    !!userRights?.signatoryRight

  const isShowSection = useMemo(() => {
    const isLimitedActiveContract =
      currentContract?.status === ContractStatusType.Active &&
      contractsApplicant.some(({ id }) => id === currentContract.id)
    const isUnverifiedSignatory = Boolean(userRights?.signatoryRight) && !user?.isVerified

    return (
      !isLimitedAccess &&
      Boolean(currentContract?.activatedOn) &&
      !isLimitedActiveContract &&
      user?.isPhoneVerified &&
      !isUnverifiedSignatory
    )
  }, [userRights, isLimitedAccess, currentContract, contractsApplicant, user])

  return (
    <Box hidden={!isShowSection}>
      {isDocAccessPermitted && (
        <CompanySelectAccordionSubgroup
          title={t('documents', 'Documents')}
          details={[
            {
              name: t('received', 'Received'),
              href: generatePath(APP_PATHS.dashboard.contractManagement.documents, {
                [PATH_PARAMS.applicationId]: applicationId,
                [PATH_PARAMS.docsTab]: DocumentsTabName.Received,
              }),
              haveAlertPoint: true,
            },
            {
              name: t('uploaded', 'Uploaded'),
              href: generatePath(APP_PATHS.dashboard.contractManagement.documents, {
                [PATH_PARAMS.applicationId]: applicationId,
                [PATH_PARAMS.docsTab]: DocumentsTabName.Uploaded,
              }),
            },
          ]}
        />
      )}

      {!isPersonalContract && !userRights?.isIntroducerContractOwner && (
        <CompanySelectAccordionSubgroup
          title={t('people', 'People')}
          details={[
            {
              name: t('team', 'Team'),
              href: generatePath(APP_PATHS.dashboard.contractManagement.people, {
                [PATH_PARAMS.applicationId]: applicationId,
                [PATH_PARAMS.peopleTab]: PeopleTabName.Team,
              }),
            },
            {
              name: t('directors', 'Directors'),
              href: generatePath(APP_PATHS.dashboard.contractManagement.people, {
                [PATH_PARAMS.applicationId]: applicationId,
                [PATH_PARAMS.peopleTab]: PeopleTabName.Directors,
              }),
            },
            {
              name: t('contacts', 'Contacts'),
              href: generatePath(APP_PATHS.dashboard.contractManagement.people, {
                [PATH_PARAMS.applicationId]: applicationId,
                [PATH_PARAMS.peopleTab]: PeopleTabName.Contacts,
              }),
            },
          ]}
        />
      )}
    </Box>
  )
}
