export const VBAN_ALERT_TIMEOUT = 10000

export const CONTRACT_MANAGEMENT_ALERT_TIMEOUT = 10000

export const CARD_ALERT_TIMEOUT = 10000

export const CARD_ALERT_TIMEOUT_SHORT = 2000

export const ACCOUNT_ALERT_TIMEOUT = 10000

export const CONTACT_ALERT_TIMEOUT = 5000
