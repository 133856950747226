import {
  Box,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { isEmpty, isEqual } from 'lodash'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Checked from '../../../../assets/images/icons/cheked_icon.svg?react'
import IconReceivedDocuments from '../../../../assets/images/icons/receivedDocuments.svg?react'
import { ColumnNameWithSort, receivedPageStateVar } from '../../../../components'
import EmptyDataPage from '../../../../components/Common/EmptyDataPage'
import { Unchecked } from '../../../../components/Common/Unchecked'
import { Document, DocumentOrderBy, OrderDirection } from '../../../../graphql'
import { usePageFiltersSorting } from '../../../../hooks'
import { documentsAccordionsType } from '../../../../types'
import { CustomAccordion } from './CustomAccordion'
import ManagementListRow, { DocumentsStyledColumns } from './ManagementListRow'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    '& th': {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  checkBoxHeader: {
    marginRight: theme.spacing(3),
  },
  date: {
    position: 'relative',
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
  tableHead: {
    '& .MuiTableRow-root': {
      width: '100%',
      display: 'inline-table',
      ...DocumentsStyledColumns,
    },
  },
}))

const ReceivedDocumentsTableComponent: FC<{
  receivedDocuments: Document[] | undefined
  pinnedDocuments: Document[] | undefined
  checkedAll: boolean
  setCheckedAll: React.Dispatch<React.SetStateAction<boolean>>
  onMark: (isLoadDocumentId?: number) => void
  checkedIdArray: Document[]
  setCheckedIdArray: React.Dispatch<React.SetStateAction<Document[]>>
  currentAccordion: documentsAccordionsType
  setCurrentAccordion: React.Dispatch<React.SetStateAction<documentsAccordionsType>>
  canBeCheckedCount?: number
  disabled?: boolean
  isFiltered: boolean
}> = ({
  receivedDocuments,
  pinnedDocuments,
  checkedAll,
  setCheckedAll,
  onMark,
  checkedIdArray,
  setCheckedIdArray,
  currentAccordion,
  setCurrentAccordion,
  canBeCheckedCount = 0,
  disabled,
  isFiltered,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { pageFilters, setPageFilters } = usePageFiltersSorting(receivedPageStateVar)

  const onChecked = useCallback(() => {
    setCheckedAll((prevState) => !prevState)
  }, [])

  const onCheckedAny = useCallback(
    (value, element) => {
      if (value) {
        setCheckedIdArray((prevState) => [...prevState, element])
      } else {
        setCheckedIdArray((prevState) => prevState.filter((el) => el.id !== element.id))
      }
    },
    [setCheckedIdArray],
  )

  const resetAccordionTab = useCallback(() => {
    setPageFilters({ page: 1 })
    if (currentAccordion === documentsAccordionsType.pinnedDoc) {
      setCheckedAll(false)
    }
  }, [setCheckedAll, setPageFilters, currentAccordion])

  const handleRefetch = useCallback(
    (sortedBy: string) => {
      setCheckedAll(false)
      setCheckedIdArray([])
      if (pageFilters.sortBy !== sortedBy) {
        setPageFilters({
          sortBy: sortedBy as DocumentOrderBy,
          direction: OrderDirection.Ascending,
          page: 1,
        })
      } else {
        const sortDirection =
          pageFilters.direction === OrderDirection.Descending
            ? OrderDirection.Ascending
            : OrderDirection.Descending
        setPageFilters({ direction: sortDirection, page: 1 })
      }
    },
    [setPageFilters, pageFilters.direction, pageFilters.sortBy],
  )

  return !(isEmpty(receivedDocuments) && isEmpty(pinnedDocuments)) ? (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>
                <Box>
                  <Checkbox
                    checked={!!checkedIdArray.length}
                    indeterminate={
                      !!checkedIdArray.length && canBeCheckedCount !== checkedIdArray.length
                    }
                    value={checkedAll}
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                    onChange={onChecked}
                    indeterminateIcon={
                      <IndeterminateCheckBoxIcon color="primary" fontSize="small" />
                    }
                    className={`${classes.checkBoxHeader} ${
                      disabled ? classes.disabledCheckbox : ''
                    }`}
                    data-test="name"
                    disabled={disabled}
                  />
                  {t('name', 'Name')}
                </Box>
              </TableCell>
              <TableCell data-test="relatedAccountCell">
                {t('relatedAccount', 'Related account')}
              </TableCell>

              <ColumnNameWithSort
                data-test="type"
                name={DocumentOrderBy.Type}
                handleRefetch={handleRefetch}
              >
                {t('type', 'Type')}
              </ColumnNameWithSort>

              <ColumnNameWithSort
                data-test="added"
                name={DocumentOrderBy.CreatedAt}
                handleRefetch={handleRefetch}
              >
                {t('added', 'Added')}
              </ColumnNameWithSort>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(pinnedDocuments) &&
              isEmpty(receivedDocuments) &&
              pinnedDocuments?.map((value) => (
                <ManagementListRow
                  data={value as Document}
                  key={value.id}
                  checkedAll={checkedAll}
                  onCheckedAny={onCheckedAny}
                  checkedIdArray={checkedIdArray}
                  onMark={onMark}
                  disabled={disabled}
                />
              ))}
            {!isEmpty(receivedDocuments) &&
              isEmpty(pinnedDocuments) &&
              receivedDocuments?.map((value) => (
                <ManagementListRow
                  data={value as Document}
                  key={value.id}
                  checkedAll={checkedAll}
                  onCheckedAny={onCheckedAny}
                  checkedIdArray={checkedIdArray}
                  onMark={onMark}
                  disabled={disabled}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isEmpty(pinnedDocuments) && !isEmpty(receivedDocuments) && (
        <>
          <CustomAccordion
            title={t('PinnedDocuments', 'Pinned documents')}
            expanded={currentAccordion === documentsAccordionsType.pinnedDoc}
            setExpanded={setCurrentAccordion}
            resetAccordionTab={resetAccordionTab}
          >
            {pinnedDocuments?.map((value) => (
              <ManagementListRow
                data={value as Document}
                key={value.id}
                checkedAll={checkedAll}
                onCheckedAny={onCheckedAny}
                checkedIdArray={checkedIdArray}
                onMark={onMark}
                disabled={disabled}
              />
            ))}
          </CustomAccordion>
          <CustomAccordion
            title={t('everythingElse', 'Everything else')}
            expanded={currentAccordion === documentsAccordionsType.everythingElse}
            setExpanded={setCurrentAccordion}
            resetAccordionTab={resetAccordionTab}
          >
            {receivedDocuments?.map((value) => (
              <ManagementListRow
                data={value as Document}
                key={value.id}
                checkedAll={checkedAll}
                onCheckedAny={onCheckedAny}
                checkedIdArray={checkedIdArray}
                onMark={onMark}
                disabled={disabled}
              />
            ))}
          </CustomAccordion>
        </>
      )}
    </>
  ) : (
    <EmptyDataPage
      messageKey={
        isFiltered
          ? t('noReceivedDocumentsFound', 'No Received documents found')
          : t('noReceivedDocuments', 'No Received documents to display')
      }
      icon={IconReceivedDocuments}
    />
  )
}

export const ReceivedDocumentsTable = React.memo(ReceivedDocumentsTableComponent, isEqual)
