import { ReactiveVar } from '@apollo/client'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { usePageFiltersSorting, useRowPerPage } from '../../hooks'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'fixed',
    top: '30vh',
    left: '50%',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    transform: 'translateX(-50%)',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    opacity: 2,
    transition: 'opacity 0.3s ease-in-out',
    zIndex: theme.zIndex.snackbar,
  },
}))

export const SwipeNavigation: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageStateVar: ReactiveVar<any>
  totalCount: number | undefined | null
}> = ({ pageStateVar, totalCount, children }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { pageFilters, setPageFilters } = usePageFiltersSorting(pageStateVar)
  const { page } = pageFilters

  const { itemsPerPage } = useRowPerPage()

  const [swipePage, setSwipePage] = useState<number | null>(null)

  const maxPage = useMemo(() => Math.ceil((totalCount || 1) / itemsPerPage), [
    totalCount,
    itemsPerPage,
  ])

  const handlers = useSwipeable({
    onSwiping: ({ deltaX }) => {
      if (deltaX > 0 && page > 1) {
        setSwipePage(page - 1)
      } else if (deltaX < 0 && page < maxPage) {
        setSwipePage(page + 1)
      }
    },
    onSwipedLeft: () => {
      if (page < maxPage) {
        setPageFilters({ page: page + 1 })
      }
      setSwipePage(null)
    },
    onSwipedRight: () => {
      if (page > 1) {
        setPageFilters({ page: page - 1 })
      }
      setSwipePage(null)
    },
    onSwiped: () => setSwipePage(null),
    trackTouch: true,
    preventScrollOnSwipe: true,
  })

  return (
    <Box {...handlers} style={{ touchAction: 'pan-y' }}>
      {children}

      {swipePage !== null && (
        <Box className={classes.container}>
          <Box display="flex" gridGap={4} alignItems="center" justifyContent="center">
            {page > swipePage && <KeyboardArrowLeft />}
            <Typography variant="h5">{`${t('toPage', 'To page')} ${swipePage}`}</Typography>
            {page < swipePage && <KeyboardArrowRight />}
          </Box>
        </Box>
      )}
    </Box>
  )
}
