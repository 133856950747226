import { useHistory } from 'react-router'
import { isDirtyForm, isNavMenuRoute, isUnsaveFormData } from '../graphql/local'
import { APP_PATHS } from '../routes/paths'
import { useTableSort } from './useCurrentTableSort'
import { usePageFiltersSorting } from './usePageFiltersSorting'
import { useWebApi } from './useWebApi'
import { useWeb3 } from './useWeb3'

export const useLogOut = () => {
  const { webApi } = useWebApi()
  const history = useHistory()
  const { disconnect } = useWeb3()

  const { clearTablesSort } = useTableSort()
  const { resetAll } = usePageFiltersSorting()

  const isOnLoginPage = history.location.pathname === APP_PATHS.auth.login //

  return () => {
    clearTablesSort()
    resetAll()
    isUnsaveFormData(false)
    isDirtyForm(false)
    isNavMenuRoute(false)
    // disconnecting crypto wallet
    disconnect()
    //check not already logged out
    if (!isOnLoginPage) {
      webApi?.logout().then(() => {
        history.push(APP_PATHS.auth.login)
      })
    }
  }
}
