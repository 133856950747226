import { useDetermineUserRights } from './useDetermineUserRights'
import { CardStatuses, CardType, LimitedAccessRight, useCardsListQuery } from '../graphql'
import { useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { selectOptions, selectOptionsForCardHolder } from '../utils/Data'
import { AccountFilterOptions, TransactionsMethodsList } from '../types'
import { getMaskedCardNumber } from '../utils'
import { PATH_PARAMS } from '../routes/paths'
import { useParams } from 'react-router-dom'

export const useCardFiltersDataManager = (accountId: string | number | null | undefined) => {
  const [searchMethodValue, setSearchMethodValue] = useState<string | null>(null)
  const [filterOptions, setFilterOptions] = useState<TransactionsMethodsList[]>([])

  const {
    [PATH_PARAMS.applicationId]: applicationId,
    [PATH_PARAMS.vbanId]: vbanId,
  } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId)
  const isCardholder = userRights?.limitedAccessRight === LimitedAccessRight.CardHolder
  const serverRequestRecordLimit = 20

  const defaultSelectOptions = useMemo(
    () =>
      !!vbanId
        ? selectOptions.filter(
            ({ key }) =>
              key !== AccountFilterOptions.cryptoTransfer && key !== AccountFilterOptions.card,
          )
        : selectOptions,
    [vbanId],
  )

  const { data: cardsData, error: cardsListError, loading: cardsLoading } = useCardsListQuery({
    variables: {
      contractId: applicationId,
      limit: serverRequestRecordLimit,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (cardsLoading) return
    if (!isEmpty(cardsData?.contractCards)) {
      const baseOptions = searchMethodValue
        ? defaultSelectOptions.filter(({ label }) =>
            label?.toLowerCase().includes(searchMethodValue),
          )
        : [...defaultSelectOptions]
      const options = (cardsData?.contractCards as CardType[])?.reduce(
        (acc: TransactionsMethodsList[], itemCard: CardType) => {
          const checkAccount = isCardholder || accountId === itemCard.account?.id

          const isValidStatus = itemCard.isCard3dSecureActivated
            ? itemCard.status === CardStatuses.Active || itemCard.status === CardStatuses.Blocked
            : itemCard.status === CardStatuses.Closed && !!itemCard.apiMaskedCardNumber

          if (checkAccount && isValidStatus) {
            acc.push({
              key: itemCard.id,
              label: `${getMaskedCardNumber(itemCard.apiMaskedCardNumber)} ${
                itemCard.alias ? itemCard.alias : ''
              }`,
              accountId: itemCard.account?.id,
            })
          }
          return acc
        },
        isCardholder ? [] : baseOptions,
      )
      setFilterOptions(
        searchMethodValue
          ? options.filter(({ label }) => label?.toLowerCase().includes(searchMethodValue))
          : options,
      )
    } else {
      const baseOptions = isCardholder ? selectOptionsForCardHolder : defaultSelectOptions
      setFilterOptions(
        searchMethodValue
          ? baseOptions.filter(({ label }) => label?.toLowerCase().includes(searchMethodValue))
          : baseOptions,
      )
    }
  }, [cardsData, accountId, searchMethodValue])

  return {
    cardsData,
    cardsListError,
    cardsLoading,
    filterOptions,
    searchMethodValue,
    setSearchMethodValue,
    isCardholder,
  }
}
