import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PATH_PARAMS } from '../routes/paths'
import { useDetermineUserRights } from './useDetermineUserRights'
import { BankableEntityContractType, LimitedAccessRight } from '../graphql'

export const useLimitedPerson = (contract?: BankableEntityContractType) => {
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(contract?.id || (applicationId as string))

  const onlyLookingUsersList = [
    LimitedAccessRight.CardHolder,
    LimitedAccessRight.ViewOnly,
    LimitedAccessRight.PaymentFacilitationView,
  ]

  const isLimitedPerson = useMemo(
    () =>
      [...onlyLookingUsersList, LimitedAccessRight.ViewDataEntry].includes(
        userRights?.limitedAccessRight as LimitedAccessRight,
      ),
    [userRights?.limitedAccessRight],
  )

  const isStrictLimitedPerson = useMemo(
    () => onlyLookingUsersList.includes(userRights?.limitedAccessRight as LimitedAccessRight),
    [userRights?.limitedAccessRight],
  )

  return { isLimitedPerson, isStrictLimitedPerson }
}
