/* eslint-disable i18next/no-literal-string */
import clsx from 'clsx'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, makeStyles, Checkbox } from '@material-ui/core'

import Checked from '../../../../assets/images/icons/cheked_icon.svg?react'
import { Unchecked } from '../../../../components/Common/Unchecked'
import { Document } from '../../../../graphql'
import { dateFormat, documentTypesArray } from '../../../../utils'
import { isEmpty } from 'lodash'
import { PATH_PARAMS } from '../../../../routes/paths'
import { useParams } from 'react-router-dom'
import { useDetermineUserRights } from '../../../../hooks'

const useStyles = makeStyles(() => ({
  tableRow: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    margin: '0 0 8px 0',
    '&.checked': {
      backgroundColor: '#EFF3FE!important',
    },
    '&.isNotRead': {
      backgroundColor: 'rgb(245, 245, 245)',
    },
  },
  name: {
    textDecoration: 'underline',
    fontSize: '14px',
    color: '#000000',
  },
  tableDoubleItem: {
    padding: '12px 0 12px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  mobileTableLabel: {
    fontSize: '12px',
    color: '#595959',
  },
  receivedDocuments: {
    fontSize: '14px',
    color: '#000000',
  },
  type: {
    fontSize: '14px',
    color: '#000000',
  },
  added: {
    fontSize: '14px',
    color: '#000000',
  },
  linkStyle: {
    color: 'black',
    wordWrap: 'break-word',
    overflowWrap: 'anywhere',
  },
  tableFlexItem: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    '& svg rect': {
      fill: 'rgba(0, 0, 0, 0.05)',
    },
  },
}))

export const ManagementListTablet: FC<{
  data: Document
  checkedAll: boolean
  onCheckedAny: (
    b: boolean,
    element: {
      id: number
      isRead: boolean
    },
  ) => void
  checkedIdArray: Document[]
  onMark: (isLoadDocumentId: number) => void
  disabled?: boolean
}> = ({ data, checkedAll, onCheckedAny, checkedIdArray, onMark, disabled }) => {
  const { id, fileDescription, createdAt, type, firstSide, isRead, account } = data
  const [checked, setChecked] = useState(false)

  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isDisabled = disabled || ((isRead as boolean) && !userRights?.isApplicant)

  const classes = useStyles()
  const { t } = useTranslation()

  const handleCheck = useCallback(() => {
    setChecked((prevState) => !prevState)
    onCheckedAny(!checked, { id: id, isRead: !!isRead })
  }, [checked, id, onCheckedAny])

  const handleDetails = useCallback(
    (event, url) => {
      if (event.target.tagName === 'INPUT') {
        return
      }
      onMark(id)
      const win = window.open(url, '_blank')
      if (win != null) {
        win.focus()
      }
    },
    [id],
  )

  useEffect(() => {
    setChecked(checkedAll)
  }, [checkedAll])

  useEffect(() => {
    if (isEmpty(checkedIdArray)) {
      setChecked(false)
    }
  }, [checkedIdArray])

  return (
    <Box
      className={clsx(
        classes.tableRow,
        `${checked && !isDisabled && 'checked'}`,
        `${!isRead && 'isNotRead'}`,
      )}
    >
      <Box>
        <Grid container className={classes.tableDoubleItem}>
          <Grid
            item
            xs={6}
            className={classes.tableFlexItem}
            onClick={(event) => handleDetails(event, data?.firstSide?.url)}
          >
            <Box>
              <Checkbox
                checked={isDisabled ? false : checked}
                icon={<Unchecked />}
                checkedIcon={<Checked />}
                onChange={handleCheck}
                disabled={isDisabled}
                classes={{ disabled: classes.disabledCheckbox }}
              />
            </Box>
            <Grid>
              <Box className={classes.mobileTableLabel}>{t('name', 'Name')}</Box>
              <Box className={classes.name}>
                <a
                  href={firstSide?.url || '/'}
                  target="_blank"
                  rel={'noopener'}
                  className={classes.linkStyle}
                >
                  {fileDescription}
                </a>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={6} onClick={(event) => handleDetails(event, data?.firstSide?.url)}>
            <Box className={classes.mobileTableLabel}>{t('relatedAccount', 'Related account')}</Box>
            <Box className={classes.receivedDocuments}>{account?.alias ?? 'None'}</Box>
          </Grid>
        </Grid>
        <Grid container className={classes.tableDoubleItem}>
          <Grid item xs={6} onClick={(event) => handleDetails(event, data?.firstSide?.url)}>
            <Box className={classes.mobileTableLabel}>{t('type', 'Type')}</Box>
            <Box className={classes.type}>{`${
              documentTypesArray.find((item) => item.key === type)?.label
            }`}</Box>
          </Grid>
          <Grid item xs={6} onClick={(event) => handleDetails(event, data?.firstSide?.url)}>
            <Box className={classes.mobileTableLabel}>{t('added', 'Added')}</Box>
            <Box className={classes.added}>{dateFormat(createdAt)}</Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ManagementListTablet
