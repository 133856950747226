import React, { FC, useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router'
import CryptoIcon from '../../assets/images/icons/create-menu/crypto.svg?react'
import { Wallet, WalletStatus } from '../../graphql'
import { currentStepVarPay } from '../../graphql/local/dashboard'
import { useWeb3 } from '../../hooks/useWeb3'
import { useAllowCryptoOperations } from '../../pages/WalletPage/hooks'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const WalletButtons: FC<{ wallet: Wallet | undefined }> = ({ wallet }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>
  const { disconnect, isDisconnected } = useWeb3()
  const history = useHistory()

  const isForbiddenCryptoOperations = useAllowCryptoOperations()

  useEffect(() => {
    ;(async () => {
      if (wallet && wallet.status !== WalletStatus.Disconnected && isDisconnected) {
        history.push(generatePath(APP_PATHS.dashboard.home, { applicationId }))
      }
    })()
  }, [wallet, applicationId, isDisconnected])

  return (
    <Box className={clsx(classes.container)}>
      {wallet?.status === WalletStatus.Active && (
        <Button
          className={classes.button}
          variant="contained"
          disableElevation
          disableRipple
          startIcon={<CryptoIcon />}
          disabled={isForbiddenCryptoOperations}
          onClick={() => {
            currentStepVarPay(3)
            return history.push(generatePath(APP_PATHS.dashboard.newPayment, { applicationId }))
          }}
        >
          {t('buySellCryptoCapital', 'Buy / Sell Crypto')}
        </Button>
      )}

      {wallet && wallet?.status !== WalletStatus.Disconnected && (
        <Button
          className={classes.button}
          variant="contained"
          disableElevation
          disableRipple
          startIcon={<LinkOffOutlinedIcon />}
          onClick={() => disconnect()}
        >
          {t('disconnectWallet', 'Disconnect Wallet')}
        </Button>
      )}
    </Box>
  )
}
