import React, { FC } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useMatchCurrentLocation } from '../../../hooks'
import { APP_PATHS } from '../../../routes/paths'
import { GridRow } from '../../Common'
import { useCommonStyles } from './helpers/functions'

export const ParagraphStyling: FC<{
  title: string
  description?: string
  mt?: number
  mb?: number
  id: string
}> = ({ title, description, mt = 0, mb = 0, id, children }) => {
  const classes = useCommonStyles()
  const theme = useTheme()

  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isOnboarding = useMatchCurrentLocation(APP_PATHS.application.new)

  const topSpacing = isOnboarding && isBigScreen ? theme.spacing(-1) : theme.spacing(-9)

  return (
    <Box position={'relative'}>
      <Box id={id} position={'absolute'} top={topSpacing} />
      <Box mb={mb} mt={mt}>
        <Typography variant="h3">{title}</Typography>
        {description && (
          <GridRow fullWidth>
            <Typography variant="h6" className={classes.titleSubBox}>
              {description}.
            </Typography>
          </GridRow>
        )}
        <GridRow fullWidth>
          <Box bgcolor="#FBFBFB" p={2} my={3}>
            {children}
          </Box>
        </GridRow>
      </Box>
    </Box>
  )
}
