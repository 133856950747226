import { isNull } from 'lodash'
import { GetUploadedDocsQueryVariables } from '../../graphql'
import { DocumentPageStateType } from '../../types'
import { formatDate } from '../../utils/dateUtils'

export const updateDocQueryParams = (
  state: DocumentPageStateType,
  queryParams: GetUploadedDocsQueryVariables,
) => {
  const updatedParams = { ...queryParams }

  const { searchValue, documentType, period } = state

  if (searchValue) {
    updatedParams.search = searchValue
  } else {
    delete updatedParams.search
  }

  if (documentType && documentType !== 'allTypes') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updatedParams.types = documentType
  } else {
    delete updatedParams.types
  }

  if (!period.some(isNull)) {
    const [from, to] = period

    const day = 24 * 60 * 60 * 1000
    const updatedFromDate = new Date(formatDate(from, true)).setHours(24) - day
    const fromDate = new Date(updatedFromDate).toISOString()
    const updatedToDate = new Date(formatDate(to, true)).setHours(24)
    const toDate = new Date(updatedToDate).toISOString()

    updatedParams.creationTimeFrom = fromDate
    updatedParams.creationTimeTo = toDate
  } else {
    delete updatedParams.creationTimeFrom
    delete updatedParams.creationTimeTo
  }

  return updatedParams
}
