import React from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

type Props = WithStyles & {
  height?: string | number
  size?: number | undefined
  withBackdrop?: boolean
}

export const Loader = withStyles((theme) => ({
  centeredLoader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))(({ classes, height, size = 40, withBackdrop = false }: Props) => {
  return withBackdrop ? (
    <Backdrop className={classes.backdrop} open={true}>
      <div className={classes.centeredLoader} style={{ height }}>
        <CircularProgress size={size} />
      </div>
    </Backdrop>
  ) : (
    <div className={classes.centeredLoader} style={{ height }}>
      <CircularProgress size={size} />
    </div>
  )
})

export default Loader
