import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { head, isEmpty, isEqual } from 'lodash'
import { Box, MenuItem, Popover, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonWithTooltip } from '../Common'
import { CreateOption, CreateOptionEnum } from '../../types'
import { CLIENT_MODAL, ContractTypeLabels, createOptions } from '../../constants'
import { APP_PATHS, PATH_PARAMS } from '../../routes/paths'
import { currentStepVarPay } from '../../graphql/local/dashboard'
import {
  AccountStatus,
  CardStatuses,
  ContractType,
  FundsType,
  LimitedAccessRight,
  TransactionExecutionType,
  useCardsListQuery,
  useGetAccountsCountQuery,
  useGetContractAccountsQuery,
  WalletStatus,
} from '../../graphql'
import { useDetermineUserRights, useIsLimitedContract } from '../../hooks'
import { hasLimitations } from '../../utils'

import AddIcon from '../../assets/images/icons/add-circle.svg?react'
import PaymentIcon from '../../assets/images/icons/create-menu/payment.svg?react'
import InternalIcon from '../../assets/images/icons/create-menu/internal.svg?react'
import BatchIcon from '../../assets/images/icons/create-menu/batch.svg?react'
import TopUpIcon from '../../assets/images/icons/create-menu/top-up.svg?react'
import StandingOrderIcon from '../../assets/images/icons/create-menu/standing-order.svg?react'
import AccountIcon from '../../assets/images/icons/create-menu/account.svg?react'
import CardIcon from '../../assets/images/icons/create-menu/card.svg?react'
import BeneficiaryIcon from '../../assets/images/icons/create-menu/beneficiary.svg?react'
import CryptoIcon from '../../assets/images/icons/create-menu/crypto.svg?react'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    minWidth: 'auto',
    margin: theme.spacing(1),
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiTypography-root': {
        textDecoration: 'underline',
      },
    },
    '&[disabled] svg path': {
      fill: '#ccc',
    },
  },
  btnText: {
    marginLeft: theme.spacing(0.5),
  },
  menu: {
    '& .MuiPaper-rounded': {
      borderRadius: 0,
      border: '1px solid #F0F0F0',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(1.25, 0),
    },
  },
  menuItem: {
    minHeight: theme.spacing(4.25),
  },
  menuItemText: {
    marginLeft: theme.spacing(1.25),
  },
  menuItemWithBorders: {
    padding: theme.spacing(0, 0),
    margin: theme.spacing(0, 2),
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    '& .MuiListItem-root': {
      margin: theme.spacing(1, -2),
    },
  },
}))

type CreateButtonProps = {
  resetPages: () => void
}

const CreateButtonComponent: FC<CreateButtonProps> = ({ resetPages }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { [PATH_PARAMS.applicationId]: applicationId } = useParams() as Record<string, string>

  const { data: accountsData, loading } = useGetContractAccountsQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
    },
  })

  const { data, loading: countLoading } = useGetAccountsCountQuery({
    variables: {
      contractId: applicationId,
      statuses: AccountStatus.Active,
    },
  })

  const { userRights } = useDetermineUserRights(applicationId as string | number)
  const isCardholder = userRights?.limitedAccessRight === LimitedAccessRight?.CardHolder
  const isViewOnly = userRights?.limitedAccessRight === LimitedAccessRight.ViewOnly
  const isPaymentFacilitator =
    userRights?.limitedAccessRight === LimitedAccessRight.PaymentFacilitationView

  const isOnlyOneDepositGuaranteed =
    accountsData?.accounts?.length === 1 &&
    head(accountsData?.accounts)?.fundsType === FundsType.DepositGuaranteed
  const isActiveAccounts = useMemo(() => !isEmpty(accountsData?.accounts), [accountsData])
  const { isLimitedContract, contractStatus, type, contractCountry } = useIsLimitedContract()
  const isDisabled = isLimitedContract || isOnlyOneDepositGuaranteed

  const hasActiveWallet =
    (accountsData?.wallets?.filter((w) => w?.status === WalletStatus.Active)?.length || 0) > 0

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { data: cardsData } = useCardsListQuery({
    variables: {
      contractId: applicationId,
      statuses: CardStatuses.Active,
    },
    fetchPolicy: 'network-only',
  })

  const isNoCreateCardLimitations = useMemo(() => {
    return hasLimitations(accountsData, cardsData, type as ContractType, contractCountry)
  }, [type, contractCountry, accountsData?.accounts, cardsData?.contractCards])

  const isTopUpSupported = useMemo(
    () =>
      accountsData?.accounts?.some(
        (account) => account?.cardTopupSupported && account?.status === AccountStatus.Active,
      ),
    [accountsData?.accounts],
  )

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const redirectPaths = useMemo(
    () => ({
      [CreateOptionEnum.payment]:
        generatePath(APP_PATHS.dashboard.newPayment, { applicationId }) +
        `?initExecution=${TransactionExecutionType.Instant}`,
      [CreateOptionEnum.internal]: generatePath(APP_PATHS.dashboard.newPayment, {
        applicationId,
      }),
      [CreateOptionEnum.batch]: generatePath(APP_PATHS.dashboard.batchPayment, {
        applicationId,
      }),
      [CreateOptionEnum.topUp]: generatePath(APP_PATHS.dashboard.newPayment, { applicationId }),
      [CreateOptionEnum.cryptoTransfer]: generatePath(APP_PATHS.dashboard.newPayment, {
        applicationId,
      }),
      [CreateOptionEnum.standingOrder]:
        generatePath(APP_PATHS.dashboard.newPayment, { applicationId }) +
        `?initExecution=${TransactionExecutionType.Recurring}`,
      [CreateOptionEnum.account]: generatePath(APP_PATHS.dashboard.createAccount, {
        [PATH_PARAMS.applicationId]: applicationId,
      }),
      [CreateOptionEnum.card]: generatePath(APP_PATHS.dashboard.createCard, {
        [PATH_PARAMS.applicationId]: applicationId,
        [PATH_PARAMS.cardsPageNum]: 1,
      }),
      [CreateOptionEnum.beneficiary]:
        generatePath(APP_PATHS.dashboard.clients, { applicationId }) + `?${CLIENT_MODAL}=true`,
    }),
    [applicationId],
  )

  const handleSelect = useCallback(
    (option: CreateOptionEnum) => {
      handleClose()
      const location = history.location.pathname
      const isNewPaymentPath = location.includes('new-payment')

      switch (option) {
        case CreateOptionEnum.payment:
          currentStepVarPay(0)
          resetPages()
          break

        case CreateOptionEnum.internal:
          currentStepVarPay(1)
          break

        case CreateOptionEnum.batch:
          resetPages()
          break

        case CreateOptionEnum.topUp:
          currentStepVarPay(2)
          break

        case CreateOptionEnum.cryptoTransfer:
          currentStepVarPay(3)
          break

        case CreateOptionEnum.standingOrder:
          currentStepVarPay(0)
          break
      }

      if (isNewPaymentPath || location === redirectPaths[option]) {
        history.replace(redirectPaths[option])
      } else {
        history.push(redirectPaths[option])
      }
    },
    [history, handleClose, resetPages, redirectPaths],
  )

  const options: CreateOption[] = useMemo(() => {
    const disabledPayments = isViewOnly || isCardholder || !data?.accountsCount

    return [
      {
        key: CreateOptionEnum.payment,
        translation: createOptions[CreateOptionEnum.payment],
        disabled: disabledPayments,
        icon: <PaymentIcon />,
      },
      {
        key: CreateOptionEnum.internal,
        translation: createOptions[CreateOptionEnum.internal],
        disabled: disabledPayments,
        icon: <InternalIcon />,
      },
      {
        key: CreateOptionEnum.batch,
        translation: createOptions[CreateOptionEnum.batch],
        disabled: disabledPayments,
        icon: <BatchIcon />,
      },
      {
        key: CreateOptionEnum.topUp,
        translation: createOptions[CreateOptionEnum.topUp],
        disabled: disabledPayments || !isTopUpSupported,
        icon: <TopUpIcon />,
      },
      ...(hasActiveWallet
        ? [
            {
              key: CreateOptionEnum.cryptoTransfer,
              translation: createOptions[CreateOptionEnum.cryptoTransfer],
              disabled: disabledPayments,
              icon: <CryptoIcon />,
            },
          ]
        : []),
      {
        key: CreateOptionEnum.standingOrder,
        translation: createOptions[CreateOptionEnum.standingOrder],
        disabled: isCardholder || !isActiveAccounts,
        icon: <StandingOrderIcon />,
      },
      {
        key: CreateOptionEnum.account,
        translation: createOptions[CreateOptionEnum.account],
        disabled: isCardholder,
        icon: <AccountIcon />,
      },
      {
        key: CreateOptionEnum.card,
        translation: createOptions[CreateOptionEnum.card],
        disabled: !isActiveAccounts || isLimitedContract || !isNoCreateCardLimitations,
        icon: <CardIcon />,
      },
      {
        key: CreateOptionEnum.beneficiary,
        translation: createOptions[CreateOptionEnum.beneficiary],
        disabled: false,
        icon: <BeneficiaryIcon />,
      },
    ]
  }, [
    isViewOnly,
    isCardholder,
    data,
    isActiveAccounts,
    isLimitedContract,
    isNoCreateCardLimitations,
    hasActiveWallet,
    isTopUpSupported,
  ])

  const disabledTooltipValue = useMemo(() => {
    if (isOnlyOneDepositGuaranteed || !isActiveAccounts) {
      return t(
        'creationPaymentsAccountsCardsIsUnavailable',
        'Creation payments, accounts and cards are not available, because contract doesn’t have active accounts',
      )
    }

    if (isLimitedContract && contractStatus) {
      return t(
        'creationPaymentsAccountsCardsIsUnavailableByContractStatus',
        `Creation payments, accounts and cards are not available, because contract is {{contractStatus}}`,
        { contractStatus: ContractTypeLabels[contractStatus] },
      )
    }

    return ''
  }, [isOnlyOneDepositGuaranteed, isLimitedContract, contractStatus, isActiveAccounts])

  if (isViewOnly || isCardholder || isPaymentFacilitator) return null

  return (
    <>
      <ButtonWithTooltip
        className={classes.iconButton}
        onClick={handleClick}
        disableRipple
        aria-controls="create-menu"
        aria-haspopup="true"
        disabled={isDisabled}
        title={disabledTooltipValue}
        hiddeTooltip={loading || countLoading || !isDisabled}
      >
        <AddIcon />
        {!isMobile && <Typography className={classes.btnText}>{t('create', 'Create')}</Typography>}
      </ButtonWithTooltip>

      <Popover
        id="create-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={55}
        className={classes.menu}
      >
        {options.map(({ key, icon, disabled, translation }) => {
          // if (disabled) return null

          return key === CreateOptionEnum.account ? (
            <Box className={classes.menuItemWithBorders} key={key}>
              <MenuItem className={classes.menuItem} onClick={() => handleSelect(key)}>
                {icon}
                <Typography className={classes.menuItemText}>{translation}</Typography>
              </MenuItem>
            </Box>
          ) : (
            <MenuItem
              key={key}
              disabled={disabled}
              className={classes.menuItem}
              onClick={() => handleSelect(key)}
            >
              {icon}
              <Typography className={classes.menuItemText}>{translation}</Typography>
            </MenuItem>
          )
        })}
      </Popover>
    </>
  )
}

export const CreateButton = React.memo(CreateButtonComponent, isEqual)
