import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { find, forEach } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, FormControl } from '@material-ui/core'

import { FormAutocompleteSelect, FormAutocompleteSelectCustomValue, GridRow } from '../../Common'
import { DocumentUploadControllingPerson } from './DocumentUploadControllingPerson'
import { ProofOfIdentityListControlPerson } from '../../../utils/Data'
import Attention from '../../../assets/images/icons/attention.svg?react'
import { poiNeeded } from '../../../graphql/local'
import { DocUploadingType, FilesForSavingAndShowType } from '../../../types'
import { DocAlreadyUploadedControllingPerson } from './DocAlreadyUploadedControllingPerson'
import { DocumentType } from '../../../graphql'

const useStyles = (infoLabel: string) =>
  makeStyles((theme) => ({
    title: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
    },
    text: {
      margin: infoLabel === ' ' ? theme.spacing(0, 0, 0, 0) : theme.spacing(1.5, 0, 2, 0),
    },
    modalErrorMessage: {
      display: 'flex',
      padding: 8,
      alignItems: 'center',
      '& > svg': {
        marginRight: 15,
      },
      border: '1px solid #fed7d2',
      marginTop: 30,
    },
    iconErrorMessage: {
      padding: theme.spacing(0, 1.5, 0, 0),
    },
    uploadingDoc: {
      '&.fullWidth': {
        '& > div > div': {
          maxWidth: '100%',
          flexBasis: '100%',
        },
      },
      marginBottom: 24,
    },
    boxSelectTop: {
      margin: '16px 0 10px 0',
      display: 'flex',
    },
    mb: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
    },
  }))()

export const ProofOfIdentityControllingPerson: FC<{
  managing?: boolean
  nameLabel?: string
  infoLabel?: string
  isModal?: boolean
  filesForUploadRegName?: string
  documentTypeRegName?: string
  optionalIdentityList?: DocUploadingType[]
  expiryDateRegName?: string
  isOtherInDocType?: boolean
}> = ({
  nameLabel,
  infoLabel,
  managing,
  isModal = false,
  filesForUploadRegName,
  documentTypeRegName,
  optionalIdentityList,
  expiryDateRegName,
  isOtherInDocType = false,
}) => {
  const { t } = useTranslation()
  const { watch, formState, setValue, errors } = useFormContext()

  const poiNeededVar = useReactiveVar(poiNeeded)
  const classes = useStyles(infoLabel ? infoLabel : '')
  const documentType = !!documentTypeRegName ? watch(documentTypeRegName) : watch(`documentType`)
  const documentTypeVar = !!documentTypeRegName ? documentTypeRegName : `documentType`
  const documentAlreadyUploaded = watch(`documentAlreadyUploaded`)
  const filesForUpload = !!filesForUploadRegName
    ? watch(filesForUploadRegName)
    : watch(`filesForUpload`)
  const filesForUploadVar = !!filesForUploadRegName ? filesForUploadRegName : `filesForUpload`
  const expiryDateVar = !!expiryDateRegName ? expiryDateRegName : `expiryDate`
  const identityList = !!optionalIdentityList
    ? optionalIdentityList
    : ProofOfIdentityListControlPerson

  const typeDocFront = useMemo(
    () =>
      find(identityList, (o) => o.key === documentType)?.frontSideType ||
      (!!documentType ? DocumentType.Other : ''),
    [identityList, documentType],
  )

  const typeDocBack = useMemo(
    () => find(identityList, (o) => o.key === documentType)?.backSideType,
    [identityList, documentType],
  )

  useEffect(() => {
    if (documentType !== undefined && documentType !== documentAlreadyUploaded?.type) {
      setValue(`documentAlreadyUploaded`, null)
      const tempFilesForUpload: FilesForSavingAndShowType[] = []
      forEach(filesForUpload, (itemFile: FilesForSavingAndShowType) => {
        const tempDocument: FilesForSavingAndShowType = itemFile
        tempDocument.isRemoved = true
        tempFilesForUpload.push(tempDocument)
      })
      setValue(filesForUploadVar, tempFilesForUpload)
      setValue(expiryDateVar, undefined)
    } else if (documentAlreadyUploaded) {
      const tempArray: FilesForSavingAndShowType[] = []
      tempArray.push({
        id: documentAlreadyUploaded.id,
        fileName: documentAlreadyUploaded.firstSide?.fileName as string,
        size: documentAlreadyUploaded.firstSide?.size as number,
        docType: ['id', 'drivingLicense'].includes(documentAlreadyUploaded.type)
          ? documentAlreadyUploaded.type + 'Front'
          : documentAlreadyUploaded.type,
        plainUrl: documentAlreadyUploaded.firstSide?.url,
        metaType: documentAlreadyUploaded.firstSide?.mimeType as string,
        isAlreadyUploaded: true,
        isRemoved: false,
        isFirstSide: true,
        expiration: documentAlreadyUploaded.firstSide?.expiration,
      })
      if (!!documentAlreadyUploaded.secondSide?.fileName) {
        tempArray.push({
          id: documentAlreadyUploaded.id,
          fileName: documentAlreadyUploaded.secondSide?.fileName as string,
          size: documentAlreadyUploaded.secondSide?.size as number,
          docType: ['id', 'drivingLicense'].includes(documentAlreadyUploaded.type)
            ? documentAlreadyUploaded.type + 'Back'
            : documentAlreadyUploaded.type,
          plainUrl: documentAlreadyUploaded.secondSide?.url,
          metaType: documentAlreadyUploaded.secondSide?.mimeType as string,
          isAlreadyUploaded: true,
          isRemoved: false,
          isFirstSide: false,
          expiration: documentAlreadyUploaded.secondSide?.expiration,
        })
      }
      setValue(filesForUploadVar, tempArray)
    } else {
      const tempArray: FilesForSavingAndShowType[] = []
      setValue(filesForUploadVar, tempArray)
    }
  }, [documentType, documentAlreadyUploaded, setValue])

  useEffect(() => {
    if (errors['expiryDate'] || errors['undefinedExpiryDate']) {
      poiNeeded(true)
    }
  }, [errors])

  return (
    <Grid>
      <Box>
        <Box className={clsx(classes.uploadingDoc, isModal && 'fullWidth')}>
          {!!infoLabel && !!nameLabel && (
            <Box mt={1}>
              <Typography variant={'h3'} className={classes.title}>
                {nameLabel ? nameLabel : t('proofOfIdentity', 'Proof of identity')}
              </Typography>
              <Typography className={classes.text}>
                {managing
                  ? t(
                      'ownerIdentityLabelManaging',
                      'Please upload a copy of a document to prove the Managing director’s identity.',
                    )
                  : infoLabel
                  ? infoLabel
                  : t(
                      'ownerIdentityLabelControlling',
                      'Please upload a copy of a document to prove the Controlling owner’s identity.',
                    )}
              </Typography>
            </Box>
          )}
          {isOtherInDocType ? (
            <GridRow>
              <FormControl className={clsx(classes.boxSelectTop)}>
                <FormAutocompleteSelectCustomValue
                  name={documentTypeVar}
                  label={t('documentTypeSmall', 'Document type')}
                  data={identityList}
                />
              </FormControl>
            </GridRow>
          ) : (
            <GridRow fullWidth>
              <Box data-test="documentType">
                <FormAutocompleteSelect
                  name={documentTypeVar}
                  label={t('documentTypeSmall', 'Document type')}
                  data={identityList}
                  shouldValidateParam={false}
                />
              </Box>
            </GridRow>
          )}
          <Box className={errors?.documentType ? classes.mb : ''}>
            <GridRow fullWidth>
              {(filesForUpload as FilesForSavingAndShowType[])?.find(
                (docFile) => docFile.isAlreadyUploaded && docFile.isFirstSide && !docFile.isRemoved,
              ) ? (
                <DocAlreadyUploadedControllingPerson
                  typeDoc={find(identityList, (o) => o.key === documentType)?.frontSideType}
                />
              ) : (
                <DocumentUploadControllingPerson
                  typeDoc={typeDocFront}
                  filesForUploadRegName={filesForUploadRegName}
                  documentTypeRegName={documentTypeRegName}
                  expiryDateRegName={expiryDateRegName}
                />
              )}
            </GridRow>
          </Box>
          {find(identityList, (o) => o.key === documentType)?.backSideLabel && (
            <GridRow fullWidth>
              {(filesForUpload as FilesForSavingAndShowType[])?.find(
                (docFile) =>
                  docFile.isAlreadyUploaded && !docFile.isFirstSide && !docFile.isRemoved,
              ) ? (
                <DocAlreadyUploadedControllingPerson
                  typeDoc={find(identityList, (o) => o.key === documentType)?.backSideType}
                  isFirstSide={false}
                />
              ) : (
                <DocumentUploadControllingPerson
                  typeDoc={typeDocBack}
                  isFirstSide={false}
                  filesForUploadRegName={filesForUploadRegName}
                  documentTypeRegName={documentTypeRegName}
                  expiryDateRegName={expiryDateRegName}
                />
              )}
            </GridRow>
          )}
          {poiNeededVar && formState.isDirty && (
            <GridRow fullWidth>
              <Box className={classes.modalErrorMessage}>
                <Box component="span" className={classes.iconErrorMessage}>
                  <Attention />
                </Box>
                <Typography>{t('poiRequired', 'Proof of identity is required')}</Typography>
              </Box>
            </GridRow>
          )}
        </Box>
      </Box>
    </Grid>
  )
}
