import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import {
  ITEM_PER_PAGE,
  ITEM_PER_PAGE_TABLET,
  MAX_ITEM_PER_PAGE,
  MAX_ITEM_PER_PAGE_TABLET,
} from '../../constants'
import { useRowPerPage } from '../../hooks'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    rowGap: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 21,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
      alignContent: 'center',
      '& .MuiPagination-ul li:first-child .MuiPaginationItem-root': {
        marginLeft: 0,
      },
      '& .MuiPagination-ul li:last-child .MuiPaginationItem-root': {
        marginRight: 0,
      },
      '& .MuiPaginationItem-root': {
        margin: '0 2px',
      },
    },
  },
  paginationText: {
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  rowsPerPageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: theme.spacing(24),
  },
  autocompleteInput: {
    transform: 'translateY(8px)',
    width: theme.spacing(3),
    '& .MuiAutocomplete-input': {
      padding: 0,
    },
    '& .MuiInputBase-input': {
      maxHeight: theme.spacing(2.8),
    },
    '& .MuiInputBase-root': {
      maxHeight: theme.spacing(4),
    },
  },
  pagination: {
    [theme.breakpoints.down('md')]: {
      marginInline: 'auto',
    },
  },
}))

const PagePaginationComponent: FC<{
  page: number
  totalItems?: number | null
  handleChange: (event: ChangeEvent<unknown>, page: number) => void
  itemsPerPageCustom?: number
  shape?: 'rounded' | 'round'
  variant?: 'text' | 'outlined'
  color?: 'secondary' | 'primary' | 'standard'
}> = ({
  page,
  totalItems,
  handleChange,
  shape = 'rounded',
  variant = 'outlined',
  color = 'secondary',
  itemsPerPageCustom,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const { itemsPerPage: selectedItemPerPage, setItemPerPage } = useRowPerPage()

  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const itemsPerPage = itemsPerPageCustom || selectedItemPerPage

  const [itemsCount, setItemsCount] = useState<number>(0)
  const [pagesCount, setPagesCount] = useState<number>(1)
  const fromEntry = page === 1 ? 1 : itemsPerPage * page - (itemsPerPage - 1)
  const toEntry = page !== pagesCount ? itemsPerPage * page : itemsCount

  const options = useMemo(() => {
    const minPerPage = isTablet ? ITEM_PER_PAGE_TABLET : ITEM_PER_PAGE
    const maxPerPage = isTablet ? MAX_ITEM_PER_PAGE_TABLET : MAX_ITEM_PER_PAGE
    const initOptions = []
    for (let i = minPerPage; i <= maxPerPage; i++) {
      initOptions.push(i)
    }
    return initOptions
  }, [isTablet, ITEM_PER_PAGE, MAX_ITEM_PER_PAGE, MAX_ITEM_PER_PAGE_TABLET, ITEM_PER_PAGE_TABLET])

  const handleRowsPerPageChange = useCallback((_, option) => {
    setItemPerPage(option)
    handleChange(_, 1)
  }, [])

  useEffect(() => {
    if (totalItems) {
      const pagesCount = Math.ceil(totalItems / itemsPerPage)
      setItemsCount(totalItems)
      setPagesCount(pagesCount)
    } else {
      setItemsCount(0)
      setPagesCount(0)
    }
  }, [totalItems, itemsPerPage])

  return (
    <Grid item className={classes.container}>
      {pagesCount > 1 && (
        <span
          className={classes.paginationText}
          data-test="showingEntries"
          data-from-entry={fromEntry}
          data-to-entry={toEntry}
          data-all-entries={itemsCount}
        >
          {t('showingEntries', {
            fromEntry,
            toEntry,
            itemsCount,
          })}
        </span>
      )}
      {!itemsPerPageCustom && (
        <Box className={classes.rowsPerPageContainer}>
          <Typography className={classes.paginationText}>
            {t('rowsPerPage ', 'Rows per page')}
          </Typography>
          <Autocomplete
            defaultValue={itemsPerPage}
            closeIcon={null}
            options={options}
            onChange={handleRowsPerPageChange}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                className={classes.autocompleteInput}
              />
            )}
            popupIcon={<ExpandMoreIcon />}
          />
        </Box>
      )}
      {pagesCount > 1 && (
        <Box className={classes.pagination}>
          <Pagination
            count={pagesCount}
            color={color}
            variant={variant}
            shape={shape}
            page={page}
            onChange={handleChange}
            data-test="pagination"
          />
        </Box>
      )}
    </Grid>
  )
}

const PagePagination = React.memo(PagePaginationComponent, isEqual)
export default PagePagination
