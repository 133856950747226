import React, { FC } from 'react'
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { ContractChanges } from '../../types'
import { getLabelByKey } from '../../utils'
import { BusinessCategoriesList, CountriesUnknownList } from '../../utils/Data'
import ControlledTooltipWrapped from './Tooltips/ControlledTooltipWrapped'
import { omit, isArray, isNil } from 'lodash'
import i18next from 'i18next'
import {
  anticipatedAccountActivities,
  economicProfileSourceFundsTypes,
  economicProfileProducts,
  economicProfileTransactionSourceDestinationTypes,
} from '@klarpay/enums'
import { cardPaymentsRegions } from '../../constants'

export const ChangesValue: FC<{ diffData: ContractChanges; type: string }> = ({
  diffData,
  type,
}) => {
  const value = diffData?.[type as keyof ContractChanges]

  let markUp = <></>

  if (typeof value === 'number') {
    markUp = <>{value}</>
  }

  if (typeof value === 'string') {
    markUp = <>{value ? value : '-'}</>
  }

  if (typeof value === 'boolean') {
    markUp = <>{value ? i18next.t('enabled', 'Enabled') : i18next.t('disabled', 'Disabled')}</>
  }

  if (Array.isArray(value) && value.length) {
    if (typeof value[0] !== 'object') {
      markUp =
        (value as string[]).length !== 1 ? (
          <ControlledTooltipWrapped title={parseStringsArr(value)} arrowHorizontalPosition={'left'}>
            <Box>{`${parseStringsArr(value).split(',')[0]}...`}</Box>
          </ControlledTooltipWrapped>
        ) : (
          <>{`${parseStringsArr(value)}`}</>
        )
    } else {
      const omitedValue = omit(value[0], '__typename')
      const translatedText = Object.keys(omitedValue)
        .map((item) => parser(value[0] as Record<string, unknown>, item))
        .join(' ')
      const logoText = !!(value[0] as Record<string, never>)?.name
        ? `${(value[0] as Record<string, never>)?.name}`
        : translatedText || `${Object.values(omitedValue).join(' ')}`
      markUp = (
        <ControlledTooltipWrapped
          title={parseObjArr(value as Record<string, unknown>[])}
          arrowHorizontalPosition={'left'}
        >
          <Box>{logoText + '...'}</Box>
        </ControlledTooltipWrapped>
      )
    }
  }

  if (
    typeof value === 'object' &&
    (value as Record<string, unknown>)?.__typename === 'TotalPredictedMonthlyVolumes'
  ) {
    const logoText = !!(value as Record<string, never>)?.name
      ? `${(value as Record<string, never>)?.name}`
      : `${Object.values(omit(value, '__typename')).join(' ')}`
    markUp = (
      <ControlledTooltipWrapped
        title={parseObj(value as Record<string, unknown>)}
        arrowHorizontalPosition={'left'}
      >
        <Box>{logoText + '...'}</Box>
      </ControlledTooltipWrapped>
    )
  }

  return markUp
}

function parser(objItem: Record<string, unknown>, key: string) {
  switch (key) {
    case 'country':
    case 'bankCountry':
      return getLabelByKey(objItem[key] as string, CountriesUnknownList)
    case 'businessSector':
      return getLabelByKey(objItem[key] as string, BusinessCategoriesList)
    case 'businessCategoryRegion':
      return getLabelByKey(objItem[key] as string, cardPaymentsRegions)
    default:
      return objItem[key]
  }
}

function parseStringsArr(arr: unknown[]): string {
  const enums = {
    ...anticipatedAccountActivities,
    ...economicProfileSourceFundsTypes,
    ...economicProfileProducts,
    ...economicProfileTransactionSourceDestinationTypes,
  }
  return arr
    .map((value) => enums[value as string]?.label || i18next.t(`${value}`, value as string))
    .join(', ')
}
function parseObj(object: Record<string, unknown>) {
  return (
    <List disablePadding>
      {Object.keys(object).map((key, index) => {
        if (key === '__typename') return null

        return (
          <React.Fragment key={index}>
            <ListItem key={`${key + index}`}>
              <ListItemText
                primary={
                  <>
                    <Typography
                      component={'span'}
                      style={{
                        display: 'inline-block',
                        fontWeight: 'bold',
                        marginRight: '4px',
                      }}
                    >
                      {i18next.t(key)}:
                    </Typography>
                    {object[key]}
                  </>
                }
              />
            </ListItem>
            <Divider
              style={{
                margin: '4px',
                backgroundColor: 'rgba(255, 255, 255,.3)',
                height: 3,
              }}
            />
          </React.Fragment>
        )
      })}
    </List>
  )
}

export function parseObjArr(arr: Record<string, unknown>[]): NonNullable<React.ReactNode> {
  return (
    <List disablePadding>
      {arr.map((objItem, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && (
              <Divider
                style={{
                  margin: '4px',
                  backgroundColor: 'rgba(255, 255, 255,.3)',
                  height: 3,
                }}
              />
            )}
            {Object.keys(objItem).map((key, index) => {
              if (key === '__typename' || isNil(objItem[key])) return null

              const translatedValue = isArray(objItem[key])
                ? parseStringsArr(objItem[key] as (string | unknown)[])
                : (parser(objItem, key) as string)

              return (
                <ListItem key={`${key + index}`}>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          component={'span'}
                          style={{
                            display: 'inline-block',
                            fontWeight: 'bold',
                            marginRight: '4px',
                          }}
                        >
                          {i18next.t(key)}:
                        </Typography>
                        {translatedValue}
                      </>
                    }
                  />
                </ListItem>
              )
            })}
          </React.Fragment>
        )
      })}
    </List>
  )
}
