import React, { FC, useEffect, useRef } from 'react'
import { Box, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIntersection } from 'react-use'
import { BUSINESS_DETAILS_SUBMIT_ID } from '../../../../constants'
import { scrollToParagraph } from './helpers'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

export const ScrollDownButton: FC = () => {
  const { t } = useTranslation()

  const observeEl = useRef<HTMLElement | null>(null)

  const businessDetailsNode = document.getElementById(BUSINESS_DETAILS_SUBMIT_ID)

  const intersection = useIntersection(observeEl, { threshold: 0.1 })

  useEffect(() => {
    if (businessDetailsNode) {
      observeEl.current = businessDetailsNode
    }
  }, [businessDetailsNode])

  return (
    <Box position="fixed" bottom={56} width={208} hidden={intersection?.isIntersecting}>
      <Button
        endIcon={<ArrowDownwardIcon />}
        onClick={scrollToParagraph(BUSINESS_DETAILS_SUBMIT_ID)}
        color="primary"
        variant="outlined"
        fullWidth
        disableElevation
      >
        {t('scrollDown', 'Scroll Down')}
      </Button>
    </Box>
  )
}
