import CloseIcon from '@material-ui/icons/Close'
import React, {
  CSSProperties,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { concat, debounce, dropRight, isEmpty, isEqual, without } from 'lodash'
import { useTranslation } from 'react-i18next'
import { SEARCH_ITEM_PER_LIST } from '../../../constants'
import { ALL_EXCEPT_CYRILLIC_REGEXP } from '../../../constants/validations'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { SearchName } from '../../../types'

const useStyles = makeStyles((theme) => ({
  popper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    width: '100%',
    left: 0,
    zIndex: 300,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  paper: {
    position: 'absolute',
    width: '100%',
    maxHeight: '65vh',
    marginRight: 0,
    maxWidth: '100%',
    background: '#FFFFFF',
    overflowX: 'hidden',
    boxShadow: '0 3.5px 14px rgba(0, 0, 0, 0.2)',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      outline: '1px solid #efefef',
      borderRadius: '0.05em',
    },
  },
  item: {
    '& .MuiTypography-displayBlock': {
      marginBottom: '0px',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '0.5em',
      marginRight: theme.spacing(2),
    },
    '& .flag-icon-lg': {
      fontSize: '0.8em',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    alignContent: 'start',
    paddingRight: theme.spacing(3),
    position: 'static',
    width: '100%',
    left: '0px',
    top: '48px',
    background: '#FFFFFF',
    marginTop: '5px',
    justifyContent: 'space-between',
  },
  listItemTextLabel: {
    flexGrow: 17,
    flex: '0 0 auto',
    width: '92%',
  },
  listItemCheck: {
    flexGrow: 0,
    color: '#000000',
  },
  list: {
    boxShadow: '5px 5px 4px 0px rgba(34, 60, 80, 0.2)',
    backgroundColor: 'white',
  },
  arrowDropDown: {
    color: '#999999',
  },
  cardSelector: {
    width: '100%',
    display: 'flex',
    marginLeft: 'auto',
    height: 48,
    position: 'relative',
    background: '#FFFFFF',
    border: '1px solid #c4c4c4',
    borderRadius: '0.05em',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
    '& .MuiInputBase-input': {
      height: 'inherit',
      textOverflow: 'ellipsis',
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiIconButton-root': {
      padding: 8,
    },
  },
  cardButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    height: 48,
    padding: 0,
    minHeight: 32,
    background: 'none',
    boxSizing: 'border-box',
    position: 'relative',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: 8,
    },
    '&.open': {
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        color: '#000000',
        transform: 'rotate(180deg)',
      },
    },
    '& .MuiTypography-root:first-letter': {
      textTransform: 'capitalize',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  btnClose: {
    minWidth: 'auto',
    minHeight: 'auto',
    color: 'grey',
  },
}))

const SearchFieldComponent: FC<{
  setValue: (value: string) => void
  currentValue: string
  name: SearchName
  setPage?: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void)
  placeHolder?: string
  accountId?: string | number | null | undefined
  disabled?: boolean
  style?: CSSProperties
  onBlur?: (currentValue?: string) => void
}> = ({
  setValue,
  setPage,
  name,
  placeHolder,
  currentValue,
  accountId,
  disabled,
  style,
  onBlur,
}) => {
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'))
  const localStoredHistory = localStorage.getItem(name)

  const [searchValue, setSearchValue] = useState<string>('')
  const [searchHistory, setSearchHistory] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)

  const anchorRef = useRef<HTMLButtonElement>(null)

  const classes = useStyles()
  const { t } = useTranslation()

  const handleSetSearchData = useCallback(
    (value: string, storedHistory: string[]) => {
      if (!isEmpty(storedHistory)) {
        const newSearchList =
          storedHistory.length < SEARCH_ITEM_PER_LIST
            ? [value, ...storedHistory]
            : concat([value], dropRight(storedHistory))

        localStorage.setItem(name, JSON.stringify(newSearchList))
        setSearchHistory(newSearchList)
      } else {
        localStorage.setItem(name, JSON.stringify([value]))
        setSearchHistory([value])
      }
    },
    [setSearchHistory, name, SEARCH_ITEM_PER_LIST],
  )

  const onSearch = useCallback(
    (value: string) => {
      debouncedRef.current.cancel()

      if (value !== currentValue) {
        const localHistory = localStorage.getItem(name)
        const storedHistory = localHistory && JSON.parse(localHistory)

        if (!isEmpty(value) && (isEmpty(storedHistory) || !storedHistory.includes(value))) {
          handleSetSearchData(value, storedHistory)
        }
      }
      setValue(value)
      setPage && setPage(1)
    },
    [searchValue, currentValue, searchHistory, name, handleSetSearchData],
  )

  const debouncedRef = useRef(debounce(onSearch, 3000))
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedRef.current.cancel()
    const currentValue = event.target.value
    setSearchValue((prev) => {
      // it's need to handle the onSearch with a delay after the input is cleared
      if (!!prev && !currentValue) {
        debouncedRef.current(currentValue)
      }
      return currentValue
    })
  }

  const handleClickAway = useCallback(() => {
    currentValue !== searchValue && onSearch(searchValue)
    setOpen(false)
    onBlur && onBlur(searchValue)
  }, [searchValue, currentValue, onSearch, onBlur])

  const handleSelectHistory = useCallback(
    (selectedArg: string) => () => {
      setSearchValue(selectedArg)
      setOpen(false)
    },
    [],
  )

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const deleteItem = useCallback(
    (method: string) => (e: React.MouseEvent) => {
      e.stopPropagation()

      const newList = without(searchHistory, method)
      setSearchHistory(newList)
      localStorage.setItem(name, JSON.stringify(newList))
    },
    [searchHistory, setSearchHistory, name],
  )

  useEffect(() => {
    const storedHistory = localStoredHistory && JSON.parse(localStoredHistory)
    !isEmpty(storedHistory) && setSearchHistory(storedHistory)
  }, [localStoredHistory, setSearchHistory])

  useEffect(() => {
    if (isBigScreen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isBigScreen])

  useEffect(() => {
    if (!!searchValue) {
      debouncedRef.current(searchValue)
    } else {
      debouncedRef.current.cancel()
    }
  }, [searchValue])

  useEffect(() => debouncedRef.current.cancel(), [])

  useEffect(() => {
    if (accountId && searchValue) {
      setSearchValue('')
      onSearch('')
    }
  }, [accountId])

  useEffect(() => {
    setSearchValue(currentValue)
  }, [currentValue, setSearchValue])

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!ALL_EXCEPT_CYRILLIC_REGEXP.test(event.key)) {
      event.preventDefault()
      return
    }
    if (event.key === 'Enter' && currentValue !== searchValue) {
      onSearch(searchValue)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.cardSelector} style={style} data-test="autotest-searchField">
        <IconButton type="button" onClick={() => onSearch(searchValue)}>
          <Search style={{ fill: '#c4c4c4' }} />
        </IconButton>
        <InputBase
          placeholder={
            placeHolder || `${t('searchTrxLabel', 'Search transaction by ID or From/To')}`
          }
          inputProps={{ 'aria-label': 'search google maps', 'aria-invalid': 'false' }}
          value={searchValue}
          onChange={onChange}
          onKeyDown={handleKeyPress}
          disabled={disabled}
          inputRef={inputRef}
        />

        {!isEmpty(searchHistory) && (
          <IconButton
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={`${classes.cardButton} ${open ? 'open' : ''}`}
            data-test="autotest-filterBtn"
            disableRipple
          >
            {open ? <ExpandMoreIcon /> : <ExpandMoreIcon className={classes.arrowDropDown} />}
          </IconButton>
        )}

        {!isEmpty(searchHistory) && anchorRef.current && (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            className={classes.popper}
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement !== 'bottom-start' ? 'left bottom' : 'left top',
                }}
              >
                <Paper className={classes.paper}>
                  <List id="menu-list-grow" className={classes.list}>
                    {!isEmpty(searchHistory) &&
                      searchHistory.map((method, index) => (
                        <ListItem
                          key={`${method + index}`}
                          button
                          className={classes.item}
                          onClick={handleSelectHistory(method)}
                        >
                          <ListItemText className={classes.listItemTextLabel}>
                            {method}
                          </ListItemText>
                          <Button onClick={deleteItem(method)} className={classes.btnClose}>
                            <CloseIcon fontSize={'small'} />
                          </Button>
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export const SearchField = React.memo(SearchFieldComponent, isEqual)
