import { useCallback, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { initial, isEmpty, isEqual, last } from 'lodash'
import { useHistory } from 'react-router-dom'
import { customHistoryListVar } from '../graphql/local'
import { locationStateType } from '../types'

export const useCustomHistory = () => {
  const history = useHistory()
  const currentPath = history.location.pathname
  const goBackList = useReactiveVar(customHistoryListVar)
  const currentLocationState = history.location.state as locationStateType

  const handleGoBack = useCallback(
    (historyState?: Record<string, unknown>) => {
      if (isEmpty(goBackList)) {
        history.goBack()
      } else {
        history.push(last(goBackList) as string, historyState || currentLocationState)
        customHistoryListVar(initial(goBackList))
      }
    },
    [goBackList, history],
  )

  const resetHistory = useCallback(() => {
    !isEmpty(goBackList) && customHistoryListVar([])
  }, [goBackList])

  const addUrl = useCallback(
    (url: string = currentPath) => {
      if (last(goBackList) === url) return

      customHistoryListVar([...goBackList, url])
    },
    [goBackList, currentPath],
  )

  const initHistory = useCallback(() => {
    if (!isEqual(goBackList, [currentPath])) {
      customHistoryListVar([currentPath])
    }
  }, [goBackList, currentPath])

  const lastUrl = useMemo(() => last(goBackList), [goBackList])

  return {
    handleGoBack,
    resetHistory,
    addUrl,
    initHistory,
    lastUrl,
  }
}
