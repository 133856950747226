import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { find, get, intersection, isEmpty, isEqual } from 'lodash'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ClearIcon from '@material-ui/icons/Clear'

import { ControlledTextFieldProps, KeyAndLabelSelectType } from '../../../types'
import { FormNewValueField } from '../Fields'
import Close from '../../../assets/images/icons/closeModal.svg?react'
import Unchecked from '../../../assets/images/icons/unchecked_icon.svg?react'
import Checked from '../../../assets/images/icons/cheked_icon.svg?react'
import { handleLatinKeysDown } from '../../../utils'

const useStyles = makeStyles<Theme, { disabled: boolean | undefined }>((theme: Theme) => ({
  rootPaper: {
    zIndex: 10,
    backgroundColor: 'white',
    width: '100%',
    marginTop: 15,
  },
  formControl: {
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'auto'),
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:hover': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:focus': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-webkit-autofill:active': {
      boxShadow: '0 0 0 42px white inset !important',
      webkitBoxShadow: '0 0 0 42px white inset !important',
    },
    '& .MuiInputBase-input:-internal-autofill-selected': {
      webkitBoxShadow: '0 0 0 42px white inset !important',
      boxShadow: '0 0 0 42px white inset !important',
    },
  },
  fieldControl: {
    width: '100%',
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.spacing(1.5, 0, 1.5, 1.5),
      lineHeight: '1.1876em',
      display: 'block',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -3px) scale(0.857)',
    },
  },
  input: {
    display: 'inline-flex',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 2,
    '& .MuiInputBase-input': {
      paddingRight: theme.spacing(4),
    },
    '& .MuiInputBase-root': {
      paddingTop: 0,
      minHeight: 52,
    },
    '&.heightLevelOne': {
      '& > div': {
        height: '95px',
      },
    },
    '&.heightLevelTwo': {
      '& > div': {
        height: '140px',
      },
    },
    '&.heightLevelThree': {
      '& > div': {
        height: '174px',
      },
    },
    '&.heightLevelFour': {
      '& > div': {
        height: '210px',
      },
    },
  },
  iconInput: {
    width: 48,
    height: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    top: 'calc(50% - 16px)',
    bottom: 12,
    right: 10,
    margin: 'auto',
    zIndex: 9,
    '& .MuiSvgIcon-root': {
      color: '#999',
    },
    '& svg': {
      width: 28,
      height: 28,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius: '50%',
        cursor: 'pointer',
      },
    },
  },
  item: {
    padding: theme.spacing(1, 1.5),
    '&:hover': {
      '& svg > rect': {
        stroke: '#000000',
      },
    },
  },
  lastItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    backgroundColor: '#000000',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    width: '100%',
    '& svg': {
      marginRight: theme.spacing(1.5),
    },
    '&.disabled': {
      backgroundColor: '#F5F5F5',
      '& .MuiButton-contained.Mui-disabled': {
        backgroundColor: '#F5F5F5',
      },
    },
  },
  btn: {
    width: '100%',
    margin: '15px 0 0 0',
  },
  inputField: {
    border: '1px solid #D9D9D9',
    marginTop: 0,
    marginBottom: 10,
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.spacing(1.5, 0, 1.5, 1.5),
      lineHeight: '1.1876em',
      display: 'block',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 10px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -3px) scale(0.857)',
    },
    '& .MuiInputBase-input': {
      height: 48,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 10,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: 'black',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: '1px solid #000000',
      height: '100%',
    },
    '& .MuiInput-underline.Mui-focused:after': {
      border: '1px solid #000000',
      transform: 'none',
      borderBottom: '1px solid #000000',
    },
  },
  boxChips: {
    paddingRight: 60,
    paddingLeft: theme.spacing(1.5),
  },
  multiSelect: {
    paddingTop: '10px',
    '& .MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable': {
      borderRadius: '4px',
      backgroundColor: '#F0F0F0',
      '& svg > path': {
        d:
          'path("M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z")',
        fill: 'black',
        fillOpacity: '0.4',
      },
      '& svg': {
        width: 24,
        height: 24,
        margin: '12px 0 0 0',
      },
    },
    '& .MuiInputLabel-formControl': {
      position: 'relative',
    },
  },
  chipItem: {
    '& .MuiChip-label': {
      width: '100%',
    },
    '& .MuiChip-deleteIcon': {
      width: '12px',
      zIndex: 6,
    },
    '& svg > path': {
      d:
        'path("M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z")',
      fill: 'black',
      fillOpacity: 0.4,
    },
    position: 'relative',
    opacity: ({ disabled }) => (disabled ? '0.5' : 'inherit'),
    borderRadius: '4px',
    backgroundColor: '#F0F0F0',
    margin: '3px',
    maxWidth: 'calc(100% - 40px)',
    zIndex: 3,
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing(21.5),
    },
  },
  label: {
    zIndex: 5,
    ...theme.typography.body1,
    lineHeight: '14px',
    color: '#999',
    display: 'block',
    paddingLeft: 14,
    paddingRight: 14,
    transformOrigin: 'top left',
    transform: 'translate(0, 24px) scale(1)',
    transition:
      'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  shrink: {
    '&.setShrink': {
      transform: 'translate(0, 0) scale(0.857)',
    },
    transformOrigin: 'top left',
  },
  labelError: {
    color: theme.palette.error.main,
  },
  checkBox: {
    '& .PrivateSwitchBase-root-152': {
      padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
      margin: theme.spacing(0.7, 2, 0.7, 0),
    },
    '& .MuiIconButton-colorSecondary': {
      backgroundColor: 'transparent',
    },
  },
}))

const defaultRules = {} //{ required: true }

interface FormAutocompleteSelectMultiCustomValueProps {
  name: string
  data: KeyAndLabelSelectType[]
  label: string
  onlyLatinLetters?: boolean
}

export const FormAutocompleteSelectMultiCustomValue: React.FC<
  FormAutocompleteSelectMultiCustomValueProps & Partial<ControlledTextFieldProps>
> = ({ name = '', data, label, rules, onlyLatinLetters = true, ...rest }) => {
  const { t } = useTranslation()
  const classes = useStyles({ disabled: rest.disabled })
  const anchorRef = useRef<HTMLButtonElement>(null)
  const anchorRefDiv = useRef<HTMLDivElement>(null)
  const anchorRefParentDiv = useRef<HTMLDivElement>(null)
  const {
    errors,
    register,
    setValue,
    getValues,
    watch,
    formState: { dirtyFields },
    trigger,
  } = useFormContext()
  const error = errors ? get(errors, name) : null
  const [open, setOpen] = useState(false)
  const customValVar = name + 'CustomVal'
  const customVal = watch(customValVar)
  const [defaultData, setDefaultData] = useState<KeyAndLabelSelectType[]>([])
  const currentValue = useMemo(() => watch(name), [watch, name])
  const [currentValueLength, setCurrentValueLength] = useState(0)
  const [focused, setFocused] = useState(false)
  const [showClearButton, setShowClearButton] = useState(false)

  const isOtherSelected = watch(`isOtherSelected`)

  const setFieldData = () => {
    setValue(`isOtherSelected`, false)
    setValue(customValVar, '', {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleToggle = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()

      if (
        open &&
        !isEqual(
          defaultData.map((item) => item.key),
          currentValue,
        )
      ) {
        setFieldData()
      } else {
        if (currentValue?.forEach) {
          currentValue?.forEach((itemValue: string) => {
            if (!find(data, (itemData: KeyAndLabelSelectType) => itemData.key === itemValue)) {
              setValue(`isOtherSelected`, true)
              setValue(customValVar, itemValue, {
                shouldValidate: false,
                shouldDirty: false,
              })
            }
          })
        }
      }
      setOpen((prevOpen) => !prevOpen)
    },
    [setOpen, currentValue, setValue, open, isOtherSelected, name, data, customValVar],
  )

  const handleLabelClick = (e: MouseEvent<HTMLElement>) => {
    anchorRef.current?.focus()
    handleToggle(e)
  }

  const handleClose = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      if (!open || (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement))) {
        return
      }

      if (
        !isEqual(
          defaultData.map((item) => item.key),
          currentValue,
        )
      ) {
        setFieldData()
      }

      if (!customVal) {
        setValue('isOtherSelected', false)
      }
      setOpen(false)
    },
    [anchorRef, isOtherSelected, currentValue, customValVar, defaultData, open],
  )

  const clearList = useCallback(() => {
    setValue(name, [], {
      shouldDirty: true,
    })
    trigger(name).then()
  }, [setValue, name])

  const submitCustomValue = useCallback(() => {
    trigger(customValVar).then((isValid) => {
      if (isValid) {
        const tempArray: string[] = []
        if (currentValue?.forEach) {
          currentValue?.forEach((itemValue: string) => {
            find(data, (itemData: KeyAndLabelSelectType) => itemData.key === itemValue) &&
              tempArray.push(itemValue)
          })
        }
        tempArray.push(customVal)
        setValue(name, tempArray, {
          shouldValidate: true,
          shouldDirty: true,
        })
        setOpen(false)
        setValue(`isOtherSelected`, false)
        trigger(name).then()
      }
    })
  }, [customVal, setValue, setOpen, data, name, currentValue])

  const changeSelectedValue = useCallback(
    (label, chosenValue) => {
      const tempArray: string[] = []
      const selectedAlready =
        find(currentValue, (itemCurrentValue) => itemCurrentValue === chosenValue?.key) ||
        find(currentValue, (itemCurrentValue) => itemCurrentValue === label)
      if (currentValue?.forEach) {
        currentValue?.forEach((itemValue: string) => {
          !(selectedAlready === itemValue) && tempArray.push(itemValue)
        })
      }
      !selectedAlready && tempArray.push(chosenValue?.key)
      setValue(name, tempArray, {
        shouldValidate: true,
        shouldDirty: true,
      })
    },
    [setValue, currentValue],
  )

  const removeSelectedValue = useCallback(
    (label, chosenValue) => {
      const tempArray: string[] = []
      const selectedAlready =
        find(currentValue, (itemCurrentValue) => itemCurrentValue === chosenValue?.key) ||
        find(currentValue, (itemCurrentValue) => itemCurrentValue === label)
      if (currentValue?.forEach) {
        currentValue?.forEach((itemValue: string) => {
          !(selectedAlready === itemValue) && tempArray.push(itemValue)
        })
      }
      setValue(name, tempArray, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setValue(customValVar, '', {
        shouldValidate: true,
        shouldDirty: true,
      })
      setValue(`isOtherSelected`, false)
    },
    [currentValue, setValue, isOtherSelected, customValVar],
  )

  const toggleOther = useCallback(() => {
    if (isOtherSelected) {
      setValue(customValVar, '', {
        shouldValidate: true,
        shouldDirty: true,
      })
      const withoutCustomVal = intersection(
        currentValue,
        data.map(({ key }) => key),
      )
      setValue(name, withoutCustomVal)
    }
    setValue(`isOtherSelected`, !isOtherSelected)
  }, [isOtherSelected, setValue, customValVar, currentValue, data])

  const calculateHeightProperty = () => {
    if (currentValueLength > 50 && currentValueLength <= 100) return 'heightLevelOne'
    if (currentValueLength > 100 && currentValueLength <= 140) return 'heightLevelTwo'
    if (currentValueLength > 140 && currentValueLength <= 174) return 'heightLevelThree'
    if (currentValueLength > 174) return 'heightLevelFour'
  }

  const handleOtherFieldKeydown = (e: React.KeyboardEvent<HTMLDivElement>) =>
    handleLatinKeysDown(e, onlyLatinLetters)

  useEffect(() => {
    customVal && trigger(customValVar).then()
  }, [customVal])

  useEffect(() => {
    setCurrentValueLength(anchorRefDiv?.current?.clientHeight ?? 0)
  })

  useLayoutEffect(() => {
    if (anchorRefDiv?.current?.clientHeight) {
      setCurrentValueLength(anchorRefDiv?.current?.clientHeight)
    }
  }, [setCurrentValueLength, anchorRefDiv?.current?.clientHeight, currentValueLength])

  useEffect(() => {
    const tempData = data?.filter((itemData) =>
      find(currentValue, (itemCurrentValue) => itemCurrentValue === itemData.key),
    )
    if (currentValue?.forEach) {
      currentValue?.forEach((itemValue: string) => {
        if (!find(data, (itemData: KeyAndLabelSelectType) => itemData.key === itemValue)) {
          tempData.push({
            key: 'other',
            label: itemValue,
          })
        }
      })
    }
    setDefaultData(tempData)
  }, [currentValue, setDefaultData, data])

  useEffect(() => {
    if (!getValues(name)) {
      register(name, { ...defaultRules, ...(rules ?? {}) })
    }
    register(customValVar, { ...defaultRules, ...(rules ?? {}) })
    register(`isOtherSelected`)
    setValue(`isOtherSelected`, false)
  }, [register, name, rules, customValVar, setValue])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        ref={anchorRefParentDiv}
        className={classes.multiSelect}
        style={{ backgroundColor: rest.disabled ? '#F5F5F5' : '' }}
        onMouseEnter={() => setShowClearButton(true)}
        onMouseLeave={() => setShowClearButton(false)}
      >
        <FormControl
          className={classes.formControl}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          <label
            className={clsx(
              classes.label,
              classes.shrink,
              (!isEmpty(currentValue) || focused) && 'setShrink',
              `${!!error && classes.labelError}`,
            )}
            onClick={handleLabelClick}
          >
            {label}
          </label>
          <div className={classes.boxChips} ref={anchorRefDiv}>
            <TextField
              value={''}
              onClick={handleToggle}
              inputRef={anchorRef}
              className={clsx(classes.input, calculateHeightProperty())}
              name={name}
              error={!!error}
              helperText={error ? error.message : null}
              {...rest}
              multiline={true}
            />
            {defaultData &&
              defaultData.map((mapItem: KeyAndLabelSelectType, index) => {
                return (
                  <Chip
                    className={classes.chipItem}
                    label={mapItem.label}
                    key={mapItem.key}
                    onDelete={() => removeSelectedValue(mapItem.label, mapItem)}
                    deleteIcon={<Close />}
                    data-test={`companyDetails-chip-${name}-${index}`}
                  />
                )
              })}
          </div>
          <Box component="span" className={classes.iconInput}>
            {!isEmpty(currentValue) && (showClearButton || open) && (
              <Box onClick={clearList}>
                <ClearIcon viewBox="-4 -2 32 28" />
              </Box>
            )}
            <Box onClick={handleToggle}>
              {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon viewBox="-2 0 28 24" />}
            </Box>
          </Box>
        </FormControl>
        <Popper
          open={open}
          anchorEl={anchorRefParentDiv.current}
          role={undefined}
          transition
          disablePortal
          className={classes.rootPaper}
        >
          <Paper>
            <List id={`menu-list-grow-${name}`}>
              {!isEmpty(data) &&
                data?.map((mapItem) => (
                  <ListItem
                    key={mapItem.key}
                    onClick={() => {
                      const label = `${mapItem.label ? mapItem.label : ''}`
                      changeSelectedValue(label, mapItem)
                      trigger(name).then()
                    }}
                    button
                    data-test={`autotest-${name}`}
                    className={classes.item}
                  >
                    <Box className={classes.checkBox}>
                      <Checkbox
                        checked={
                          !!find(
                            currentValue,
                            (itemCurrentValue) => itemCurrentValue === mapItem.key,
                          )
                        }
                        icon={<Unchecked />}
                        checkedIcon={<Checked />}
                      />
                    </Box>
                    <ListItemText>{`${mapItem.label ? mapItem.label : ''}`}</ListItemText>
                  </ListItem>
                ))}
              <ListItem onClick={toggleOther} button className={classes.item}>
                <Box className={classes.checkBox}>
                  <Checkbox
                    checked={isOtherSelected}
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                  />
                </Box>
                <ListItemText>{t('other', 'Other')}</ListItemText>
              </ListItem>

              {isOtherSelected && (
                <ListItem className={`${classes.item} ${classes.lastItem}`}>
                  <Grid item className={classes.btn}>
                    <FormControl className={classes.fieldControl}>
                      <FormNewValueField
                        className={classes.inputField}
                        name={customValVar}
                        placeholder={t('yourAnswer', 'Your answer')}
                        fullWidth
                        shouldValidateParam={false}
                        onKeyDown={handleOtherFieldKeydown}
                        data-test="sourceFromYourAnswer"
                      />
                    </FormControl>
                  </Grid>
                  {!!customVal && (
                    <Grid item className={classes.btn}>
                      <Button
                        className={clsx(classes.submitButton, isEmpty(customVal) && 'disabled')}
                        variant="contained"
                        disableElevation
                        color="default"
                        onClick={submitCustomValue}
                        data-test="sourceFromYourAnswerSubmit"
                        disabled={
                          (errors ? get(errors, customValVar) : null) ||
                          !get(dirtyFields, customValVar)
                        }
                      >
                        {t('submit', 'Submit')}
                      </Button>
                    </Grid>
                  )}
                </ListItem>
              )}
            </List>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}
